import { useQuery } from '@apollo/client';
import { Select, SelectProps, Skeleton } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import GET_CURRENCY from './GetCurrency.graphql';
import { GetCurrency } from './__types/GetCurrency';

interface ChakraSelectCurrencyProps extends SelectProps {
  onSingle?: (payload: unknown) => void;
  isLoading?: boolean;
}

const ChakraSelectCurrency = forwardRef<HTMLSelectElement, ChakraSelectCurrencyProps>(({ onSingle, isLoading = false, ...props }, ref) => {
  const { data, loading: isQueryLoading } = useQuery<GetCurrency>(GET_CURRENCY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const currencies = response?.getOrganization?.sales?.payment?.currencies;
      const isOne = currencies?.length === 1;
      isOne && onSingle?.(currencies[0]);
    },
  });

  return (
    <Skeleton isLoaded={!isLoading || !isQueryLoading} hidden={data?.getOrganization?.sales?.payment?.currencies.length === 1}>
      <Select {...props} ref={ref}>
        {data?.getOrganization?.sales?.payment?.currencies?.map((currency: string) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </Select>
    </Skeleton>
  );
});

export default ChakraSelectCurrency;
