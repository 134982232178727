export function calcFullHeight(input: string | string[]): string | string[] {
  let result: string | Array<string | string[]>;
  if (Array.isArray(input)) {
    result = input.map((value) => {
      const height = calcFullHeight(value);
      if (Array.isArray(height)) {
        return height[0];
      } else {
        return height;
      }
    });
  } else {
    const simple = input.match(/^(\d+)vh$/);
    const calc = input.match(/^calc\((\d+)vh(.*)\)(.*)$/);
    if (simple) {
      result = [[simple[0], `calc(var(--vh, 1vh) * ${simple[1]})`]];
    } else if (calc) {
      result = [[calc[0], `calc(var(--vh, 1vh) * ${calc[1]}${calc[2]})${calc[3]}`]];
    } else {
      result = input;
    }
  }

  return result as string | string[];
}
