import { DocumentNode, useQuery } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { EntityWithId } from 'shared/types/EntityWithId';

import { ExtractData, QueryTypeLike } from '../structures';

interface Props<QueryType> {
  selected?: EntityWithId<Partial<ExtractData<QueryType>>>;
  triggerLabel: keyof ExtractData<QueryType>;
  query: DocumentNode;
  placeholder: string;
}

const SingleSelectLabel = <QueryType extends QueryTypeLike>({ selected, triggerLabel, query, placeholder }: Props<QueryType>) => {
  const { data } = useQuery<QueryType>(query, {
    variables: { limit: 1, variables: { _id: [selected?._id] } },
    skip: !selected || !!selected[triggerLabel],
  });

  const [item, setItem] = useState<EntityWithId<Partial<ExtractData<QueryType>>> | undefined>(selected?.[triggerLabel] ? selected : undefined);

  useEffect(() => {
    selected?.[triggerLabel] ? setItem(selected) : setItem(undefined);
  }, [selected]);

  useEffect(() => {
    if (data?.data) {
      setItem(data.data[0] as EntityWithId<Partial<ExtractData<QueryType>>>);
    }
  }, [data?.data]);

  return (
    <Box w="calc(100% - 22px)" textAlign="left">
      {item ? (
        <Text fontSize="sm" isTruncated>
          {item[triggerLabel]}
        </Text>
      ) : (
        placeholder
      )}
    </Box>
  );
};

export default SingleSelectLabel;
