import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FallbackFront = () => {
  const { t } = useTranslation();
  return (
    <Flex flex={1} alignItems="center" justifyContent="center">
      {t('FallbackFront.title')}
    </Flex>
  );
};

export default FallbackFront;
