import React from 'react';
import { useNavigate } from 'react-router-dom';

import RouterSwitcher from './RouterSwitcher';
import { TenantStore } from './RouterSwitcher/store';
import VersionNotification from './VersionNotification';

const Tenant = () => {
  const navigate = useNavigate();

  return (
    <TenantStore.Provider runtimeModel={{ navigate }}>
      <RouterSwitcher />
      <VersionNotification />
    </TenantStore.Provider>
  );
};

export default Tenant;
