import { Select, SelectProps } from '@chakra-ui/react';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { LANGUAGES } from 'shared/constants';

const ChakraSelectLanguageComponent: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = ({ placeholder, ...props }, ref) => (
  <Select {...props} ref={ref} placeholder={placeholder}>
    {LANGUAGES.map((lang) => (
      <option key={lang.value} value={lang.value}>
        {lang.label}
      </option>
    ))}
  </Select>
);

const ChakraSelectLanguage = forwardRef(ChakraSelectLanguageComponent);

export default ChakraSelectLanguage;
