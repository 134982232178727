import {
  NotificationFragment,
  NotificationFragment_BackChannelAuthRequestNotification_,
  NotificationFragment_EventNotification_,
  NotificationFragment_ExpiringContractNotification_,
  NotificationFragment_MentionNotification_,
  NotificationFragment_SaleNotification_,
  NotificationFragment_SharingNotification_,
  NotificationFragment_TaskNotification_,
} from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { NOTIFICATION_TYPE } from '__types/graphql';
import useDesktopNotification from 'hooks/useDesktopNotification';
import useLocale from 'hooks/useLocale';
import { APP_ICON } from 'shared/constants';

import { getTranslationEntityKey, getValueFromEntity } from '../Item/MentionedInNote/utils';
import { getEntityName, getValue } from '../Item/ResourceShared/utils';

interface Options {
  t: TFunction<'translation', undefined>;
  priceFormatter: (price: unknown) => unknown;
}

const mapToNotificationOptions = (notification: NotificationFragment, { t, priceFormatter }: Options): NotificationOptions => {
  const key = `NotificationsInbox.${notification.type}.body`;
  switch (notification.type) {
    case NOTIFICATION_TYPE.MENTIONED_IN_NOTE: {
      const not = notification as NotificationFragment_MentionNotification_;
      return {
        body: t(key, {
          creator: not.createdBy?.name,
          entityName: getTranslationEntityKey(not),
          value: getValueFromEntity(not),
        }),
      };
    }
    case NOTIFICATION_TYPE.LOGIN_REQUESTED: {
      const not = notification as NotificationFragment_BackChannelAuthRequestNotification_;
      return {
        body: t(key, {
          userName: not.requester.name,
        }),
      };
    }
    case NOTIFICATION_TYPE.EVENT_REMINDER: {
      const { event, sentAt } = notification as NotificationFragment_EventNotification_;
      return {
        body: t(key, {
          context: event.customer?.name ? 'with_customer' : '',
          customer: event.customer?.name,
          time: moment(event.start).to(sentAt, true),
        }),
      };
    }
    case NOTIFICATION_TYPE.EXPIRING_CONTRACT: {
      const { sale, sentAt } = notification as NotificationFragment_ExpiringContractNotification_;
      return {
        body: t(key, {
          context: moment(sale.expiresAt).diff(sentAt) < 0 ? 'past' : '',
          customer: sale.customer.name,
          time: moment(sentAt).to(sale.expiresAt),
        }),
      };
    }
    case (NOTIFICATION_TYPE.TASK_ASSIGNED, NOTIFICATION_TYPE.TASK_ASSIGNED): {
      const {
        task: { title, dueAt },
        sentAt,
      } = notification as NotificationFragment_TaskNotification_;
      return {
        body: t(key, {
          title,
          time: dueAt && moment(dueAt).to(sentAt, true),
        }),
      };
    }
    case NOTIFICATION_TYPE.WON_SALE: {
      const not = notification as NotificationFragment_SaleNotification_;
      return {
        body: t(key, {
          creator: not.sale.creator.name,
          saleValue: priceFormatter(not.sale.currencySubtotal),
        }),
      };
    }
    case NOTIFICATION_TYPE.RESOURCE_SHARED: {
      const not = notification as NotificationFragment_SharingNotification_;
      return {
        body: t(key, {
          creator: not.createdBy.name,
          entityName: getEntityName(not),
          value: getValue(not),
        }),
      };
    }
    default:
      return {
        body: t(key),
      };
  }
};

const useNotificationToast = () => {
  const { state, Modal } = useDesktopNotification();
  const { t } = useTranslation();
  const { formatCurrency } = useLocale();

  const notify = useCallback(
    (notification: NotificationFragment) => {
      if (state === 'granted') {
        new Notification(t(`NotificationsInbox.${notification.type}.title`), {
          silent: false,
          requireInteraction: true,
          icon: APP_ICON,
          badge: APP_ICON,
          ...mapToNotificationOptions(notification, { t, priceFormatter: formatCurrency }),
        });
      }
    },
    [state],
  );

  return {
    notify,
    state,
    Modal,
  };
};

export default useNotificationToast;
