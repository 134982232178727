import { ButtonProps, PlacementWithLogical } from '@chakra-ui/react';
import { SearchPersonFragment } from 'fragments/__types/SEARCH_PERSON';
import { isEmpty } from 'lodash';
import React, { memo, RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import client from 'client';
import SelectPerson from 'components/SearchDropdown/Instances/SelectPerson';
import { DROPDOWN_VARIANT } from 'components/SearchDropdown/constants';
import usePersonHandler from 'hooks/usePersonHandler';

import { RenderCreatePerson } from './RenderCreatePerson';
import { RenderDeletePerson } from './RenderDeletePerson';

export interface Props extends Omit<ButtonProps, 'value' | 'onChange' | 'placeholder' | 'onSelect'> {
  customer?: GraphQL_ObjectId;
  value?: SearchPersonFragment;
  onChange?: (person?: SearchPersonFragment) => void;
  isDeletable?: boolean;
  placeholder?: string;
  isEmailRequired?: boolean;
  tabIndex?: number;
  portalContainerRef?: RefObject<HTMLElement | null>;
  placement?: PlacementWithLogical;
  shouldResetOnChange?: boolean;
}

const ChakraSelectPerson = ({ customer, value, onChange, isDeletable = false, placeholder, isEmailRequired, shouldResetOnChange, ...props }: Props) => {
  const { PersonHandler, onOpen: onOpenPersonHandler } = usePersonHandler({
    customer,
    onComplete: (person?: SearchPersonFragment) => {
      onChange?.(person);
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getManyPeople' });
    },
  });

  const { t } = useTranslation();

  const select = (person: SearchPersonFragment) => {
    onChange?.(person);
  };

  useEffect(() => {
    if (!isEmpty(customer) && !isEmpty(value) && shouldResetOnChange) {
      onChange?.();
    }
  }, [customer, shouldResetOnChange]);

  const checkPersonEmail = (person: SearchPersonFragment) => !isEmailRequired || Boolean(person.email);

  return (
    <>
      <SelectPerson
        dataFilter={checkPersonEmail}
        onSelect={select}
        selected={value ? [value] : []}
        variables={{ variables: { customerId: customer } }}
        title={value?.name || placeholder || t('Person.placeholder')}
        triggerLabel="name"
        extraOptions={[
          ({ onClose }) => (
            <RenderCreatePerson
              key="create-order-person"
              onClick={() => {
                onOpenPersonHandler();
                onClose?.();
              }}
            />
          ),
          ({ onToggle }) => (
            <RenderDeletePerson
              key="delete-order-person"
              hidden={!isDeletable}
              onClick={() => {
                onChange?.();
                onToggle?.();
              }}
            />
          ),
        ]}
        dropdownVariant={DROPDOWN_VARIANT.SINGLE}
        {...props}
      />
      {PersonHandler}
    </>
  );
};

export default memo(ChakraSelectPerson);
