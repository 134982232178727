import { Box, Button, Popover, PopoverContent, PopoverTrigger, Portal, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import ChakraChevron from '../ChakraChevron';

export default ({ children, initialFocusRef, tabIndex = -1, isReadOnly = false, disablePortal = false, ...props }) => {
  const disclosureProps = useDisclosure();
  const { isOpen, onClose, onOpen } = disclosureProps;
  if (children.length !== 2) throw new Error('Expected ChakraCustomMenu children to be two React elements.');

  const popoverInput = children[0];
  const popoverBody = children[1];

  const open = () => {
    if (!isReadOnly) {
      onOpen();
    }
  };

  return (
    <Popover placement="bottom-start" initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose} onOpen={open}>
      <PopoverTrigger>
        <Button tabIndex={tabIndex} cursor={isReadOnly ? 'auto' : 'pointer'} {...props}>
          <Box flex={1} overflow="hidden" mr="5px" lineHeight="20px">
            {popoverInput}
          </Box>
          <ChakraChevron isOpen={isOpen} hidden={isReadOnly} />
        </Button>
      </PopoverTrigger>
      {disablePortal ? (
        <PopoverContent width="240px" maxHeight="300px" overflow="auto" p="0px" {...props.popoverContentStyles}>
          {typeof popoverBody === 'function' ? popoverBody(disclosureProps) : React.cloneElement(popoverBody, { ...disclosureProps })}
        </PopoverContent>
      ) : (
        <Portal>
          <PopoverContent width="240px" maxHeight="300px" overflow="auto" p="0px" {...props.popoverContentStyles}>
            {typeof popoverBody === 'function' ? popoverBody(disclosureProps) : React.cloneElement(popoverBody, { ...disclosureProps })}
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};
