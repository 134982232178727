import { Icon } from '@chakra-ui/react';
import React from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

interface Props {
  index: number;
  value?: number;
  onClick: () => void;
}

const Star = ({ index, value, onClick }: Props) => {
  const selected = value ? index < value : false;

  return <Icon as={selected ? AiFillStar : AiOutlineStar} onClick={onClick} boxSize="26px" cursor="pointer" color="#FFCC4D" />;
};

export default Star;
