import { useState, useEffect, RefObject } from 'react';

export default function useOnScreen<T extends Element>(ref: RefObject<T>, options?: IntersectionObserverInit) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    setIntersecting(entry.isIntersecting);
  }, options);

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
