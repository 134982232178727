import { TabPanels, TabPanel } from '@chakra-ui/react';
import React from 'react';

import History from './History';
import Unread from './Unread';

const Content = () => (
  <TabPanels flex="1" height="calc(100% - 43px)">
    <TabPanel h="100%" overflowY="auto" p="0">
      <Unread />
    </TabPanel>
    <TabPanel h="100%" overflowY="auto" p="0">
      <History />
    </TabPanel>
  </TabPanels>
);

export default Content;
