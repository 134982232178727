import { Box, Button, Flex } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SidebarMenu from 'components/ChakraLayout/SidebarMenu';
import FloatingUserActions from 'components/FloatingUserActions';
import TenantLink from 'components/TenantRouter/RouterSwitcher/TenantLink';
import { calcFullHeight } from 'utils/calcFullHeight';

const NotFoundTenant = () => {
  const { t } = useTranslation();
  const { selectedTheme } = useTheme();

  return (
    <Flex h={calcFullHeight('100vh')} w="100%" flexDir="row">
      <SidebarMenu />
      <Box w="100%" h={calcFullHeight('100vh')} overflowY="auto">
        <Flex h={calcFullHeight('100vh')} flexDir="column" flex={1} justifyContent="center" alignItems="center">
          <Box fontSize="lg" mb="2.5">
            {t('NotFound.title')}
          </Box>
          <Box>
            <TenantLink to="/">
              <Button colorScheme={selectedTheme.id}>{t('NotFound.goBack')}</Button>
            </TenantLink>
          </Box>
        </Flex>
      </Box>
      <FloatingUserActions />
    </Flex>
  );
};

export default NotFoundTenant;
