import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';

import { CustomFieldComponentProps } from 'components/CustomField/structures';
import RatePicker from 'components/RatePicker';

const Rating = ({ name, label, required, control, error, ...formProps }: CustomFieldComponentProps) => {
  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => <RatePicker onChange={(rating) => onChange(rating)} value={value} />}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Rating;
