import { DocumentNode, useQuery } from '@apollo/client';
import { Box, Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';

import If from 'components/If';
import { EntityWithId } from 'shared/types/EntityWithId';
import { isolateEvent } from 'shared/utils/isolateEvent';

import { ExtractData, QueryTypeLike } from '../structures';
import { isExtractData } from '../utils';
import RemainingTags from './RemainingTags';
import { ENTITIES_LABEL_QUERY_LIMIT } from './constants';

interface Props<QueryType> {
  selected?: EntityWithId<Partial<ExtractData<QueryType>>>[];
  deselect?: (value: EntityWithId<Partial<ExtractData<QueryType>>>) => void;
  triggerLabel: keyof ExtractData<QueryType>;
  query: DocumentNode;
  placeholder: string;
  maxElementsToShow?: number;
}

const MultiSelectLabel = <QueryType extends QueryTypeLike>({
  selected,
  deselect,
  triggerLabel,
  query,
  placeholder,
  maxElementsToShow,
}: Props<QueryType>) => {
  const hasNecessaryData = selected?.every((element) => element[triggerLabel]);
  const { data } = useQuery<QueryType>(query, {
    variables: { limit: ENTITIES_LABEL_QUERY_LIMIT, variables: { _id: selected?.map((element) => element._id) } },
    skip: !selected || hasNecessaryData,
  });
  const theme = useTheme();

  const handleRemove = (value: EntityWithId<Partial<ExtractData<QueryType>>>) => {
    deselect?.(value);
  };
  const [items, setItems] = useState<EntityWithId<Partial<ExtractData<QueryType>>>[] | undefined>(hasNecessaryData ? selected : undefined);

  useEffect(() => {
    hasNecessaryData && setItems(selected);
  }, [selected]);

  useEffect(() => {
    if (data?.data) {
      setItems(data.data as EntityWithId<Partial<ExtractData<QueryType>>>[]);
    }
  }, [data?.data]);

  const shouldDisplayCountTag = !isNil(maxElementsToShow) && (selected?.length || 0) > (maxElementsToShow || 0);
  const filterPredicate = (_: EntityWithId<Partial<ExtractData<QueryType>>>, idx: number) => (shouldDisplayCountTag ? idx < maxElementsToShow : true);

  return (
    <Box>
      {items?.filter(Boolean).length ? (
        <Flex pt="5px" w="100%" alignItems="center" justify="flex-start" flexWrap="wrap">
          {items?.filter(filterPredicate)?.map(
            (element) =>
              isExtractData<QueryType>(element) &&
              element[triggerLabel] && (
                <Tag key={element?._id} colorScheme={theme.selectedTheme.id} rounded="full" size="sm" mr="5px" mb="5px">
                  <TagLabel isTruncated maxWidth="150px">
                    {element[triggerLabel]}
                  </TagLabel>
                  <TagCloseButton
                    hidden={!deselect}
                    onClick={(event) => {
                      isolateEvent(event);
                      handleRemove(element);
                    }}
                  />
                </Tag>
              ),
          )}
          <If hidden={!shouldDisplayCountTag}>
            <Tag colorScheme={theme.selectedTheme.id} rounded="full" size="sm" mr="5px" mb="5px">
              <TagLabel isTruncated maxWidth="150px">
                +{(selected?.length || 1) - (maxElementsToShow ?? 1)}...
              </TagLabel>
            </Tag>
          </If>
          {selected && <RemainingTags selectedNumber={selected.length} itemsNumber={items.length} limit={ENTITIES_LABEL_QUERY_LIMIT} />}
        </Flex>
      ) : (
        placeholder
      )}
    </Box>
  );
};

export default MultiSelectLabel;
