import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';

interface Props {
  active: boolean;
  _id: GraphQL_ObjectId;
}

const ToggleIsRead = ({ active, _id }: Props) => {
  const { markNotification } = NotificationsStore.useStoreActions((actions) => actions);
  const { t } = useTranslation();
  const disabled = NotificationsStore.useStoreState((store) => store.disabled);

  return (
    <Flex justify="center" pt="2" px="1">
      <Tooltip
        portalProps={{ appendToParentPortal: false }}
        label={t(active ? 'NotificationsInbox.markAsUnread' : 'NotificationsInbox.markAsRead')}
        placement="bottom-end"
        gutter={0}
      >
        <Button
          isDisabled={disabled}
          minW="0"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent', border: 'none' }}
          onClick={(event) => {
            event.stopPropagation();
            markNotification({ id: _id, value: !active });
          }}
        >
          <Flex w="10px" h="10px" p="0.5" bg="gray.200" borderRadius="50%">
            <Box w="100%" h="100%" bg="theme.500" borderRadius="50%" hidden={!active} />
          </Flex>
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default ToggleIsRead;
