import React from 'react';
import styled from 'styled-components';

const Textarea = styled.textarea`
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  ${(props) => `
        margin-right: ${props.margin && props.margin.right}px;
        margin-left: ${props.margin && props.margin.left}px;
        margin-top: ${props.margin && props.margin.top}px;
        margin-bottom: ${props.margin && props.margin.bottom}px;
    `}
`;

export default ({ placeholder, value, onChange, ...props }) => {
  return <Textarea {...props} placeholder={placeholder} value={value} onChange={onChange} />;
};
