import { FormControl, FormErrorMessage, FormLabel, InputGroup, InputLeftAddon, NumberInput, NumberInputField } from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Currency as CurrencyEnum } from '__types/graphql';
import { CustomFieldComponentProps } from 'components/CustomField/structures';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';

const Currency = ({ name, label, description, required, control, error, ...formProps }: CustomFieldComponentProps) => {
  const { t } = useTranslation();
  const organization = TenantStore.useStoreState((store) => store.organization);

  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputGroup size="sm">
            <InputLeftAddon>{organization?.sales.payment.defaultCurrency || CurrencyEnum.NOK}</InputLeftAddon>
            <NumberInput
              bg="white"
              min={0}
              onChange={(_valueAsString: string, valueAsNumber: number) => onChange(valueAsNumber)}
              placeholder={description || t('common.numberPlaceholder')}
              value={value || 0}
              w="100%"
            >
              <NumberInputField borderRightRadius="md" />
            </NumberInput>
          </InputGroup>
        )}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Currency;
