import { ApolloProvider } from '@apollo/client';
import { KeyboardAccessible } from '@salire-as/ui';
import { AVAILABLE_CLIENTS, ConfigContext } from 'contexts/config';
import { StoreProvider, createStore } from 'easy-peasy';
import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { IntercomProvider } from 'react-use-intercom';

import client from 'client';

import { Main } from './main';

const App = () => {
  const oidcClient = AVAILABLE_CLIENTS.find((c) => c.domains.some((d) => d.includes(document.location.hostname)));
  const config = { oidc: { client: oidcClient } };
  return (
    <IntercomProvider appId="f2o1h3px">
      <KeyboardAccessible>
        <ApolloProvider client={client}>
          <StoreProvider store={createStore({})}>
            <DndProvider backend={HTML5Backend}>
              <ConfigContext.Provider value={config}>
                <Main />
              </ConfigContext.Provider>
            </DndProvider>
          </StoreProvider>
        </ApolloProvider>
      </KeyboardAccessible>
    </IntercomProvider>
  );
};

export default App;
