import { Flex, FlexProps, Spinner } from '@chakra-ui/react';
import React, { ForwardRefRenderFunction, forwardRef } from 'react';

import { calcFullHeight } from 'utils/calcFullHeight';

interface Props extends FlexProps {
  spinnerColor?: string;
}

const Loading: ForwardRefRenderFunction<HTMLDivElement, Props> = ({ spinnerColor = 'spinnerColor', ...flexProps }, ref) => (
  <Flex height={calcFullHeight('100vh')} flex={1} justify="center" align="center" overflow="hidden" {...flexProps} ref={ref}>
    <Spinner data-cy="page-loading-spinner" size="lg" color={spinnerColor} />
  </Flex>
);

export default forwardRef(Loading);
