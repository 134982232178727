import { action, createContextStore, thunk } from 'easy-peasy';

import { OIDC } from 'shared/utils/oidc';
import { CibaAccessResponse } from 'shared/utils/oidc/ciba-access-response';
import { TokenResponse } from 'shared/utils/oidc/token-response';
import { poll } from 'shared/utils/poll';

import { ProfileOptionsState, Status } from './structures';

const ProfileOptionsStore = createContextStore<ProfileOptionsState>({
  supportAccess: {
    request: {
      to: undefined,
      status: Status.NOT_INITIALIZED,
    },
    updateRequest: action((state, payload) => {
      state.request = {
        ...state.request,
        ...payload,
      };
    }),
    cancelRequest: action((state) => {
      state.request = {
        ...state.request,
        status: Status.CANCELED,
        to: undefined,
      };
    }),
    requestAccess: thunk(async (actions, payload, { getState }) => {
      const predicateBuilder = (res: CibaAccessResponse) => {
        const maxRetries = Math.ceil(res.expiresIn / 10);
        let counter = 0;
        return ([data, error]: [TokenResponse, Error]) => {
          if (counter > maxRetries || Status.CANCELED === getState().request.status) {
            return false;
          }
          counter += 1;
          return !!error || !data.access_token;
        };
      };

      const [response, err] = await OIDC.requestCibaAccess(payload);
      if (err) {
        actions.updateRequest({
          error: err,
          status: Status.ERROR,
        });
        return;
      }

      const res = response as CibaAccessResponse;
      actions.updateRequest({ id: res.authReqId, status: Status.REQUESTED, error: null });
      const polling = await poll(() => OIDC.fetchToken(res?.authReqId as string), predicateBuilder(res));

      if (Status.CANCELED === getState().request.status) {
        return;
      }

      if (polling[1]) {
        actions.accessDenied();
      } else {
        actions.accessGranted(polling[0]);
      }
    }),
    accessGranted: action((state, payload) => {
      state.request = {
        ...state.request,
        status: Status.ACCESS_GRANTED,
        tokenResponse: payload,
      };
    }),
    accessDenied: action((state) => {
      state.request.status = Status.ACCESS_DENIED;
    }),
  },
});

export default ProfileOptionsStore;
