import { NotificationFragment_SharingNotification_ } from 'fragments/__types/NOTIFICATION';

import { EntityName, List } from '__types/graphql';

export const getValue = (notification: NotificationFragment_SharingNotification_) => {
  return (notification.resource as List)?.name ?? notification.kind;
};

export const getEntityName = (notification: NotificationFragment_SharingNotification_) => {
  return notification.kind.toLowerCase();
};

export const getLinkToCollections = (notification: NotificationFragment_SharingNotification_) => {
  const domain = ((notification.resource as List)?.domain ?? notification.kind).toUpperCase();

  if (domain === EntityName.CUSTOMER) {
    return '/customers';
  }
  if (domain === EntityName.SALE) {
    return '/sale';
  }
  if (domain === EntityName.ORDER) {
    return '/orders';
  }
  return '/';
};
