import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash } from 'react-icons/fi';

interface Props {
  onClick: () => void;
  hidden: boolean;
}

export const RenderDeletePerson = ({ onClick, hidden }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex
      hidden={hidden}
      _hover={{
        bg: 'gray.50',
      }}
      cursor="pointer"
      px="10px"
      py="5px"
      onPointerDown={onClick}
      align="center"
    >
      <FiTrash />
      <Text ml="10px">{t('Person.delete')}</Text>
    </Flex>
  );
};
