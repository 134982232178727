import { Flex, FlexProps } from '@chakra-ui/react';
import { NotificationFragment } from 'fragments/__types/NOTIFICATION';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';

import ToggleIsRead from '../ToggleIsRead';

interface Props extends FlexProps {
  to?: string;
  notification: NotificationFragment;
}

const Wrapper: FC<Props> = ({ children, notification, to, ...props }) => {
  const basepath = TenantStore.useStoreState((store) => store.basepath);

  return (
    <Flex
      justifyContent="space-between"
      cursor="pointer"
      bg="white"
      w="100%"
      _hover={{ bg: 'gray.50' }}
      py="2.5"
      pl="5"
      pr="2"
      minH="65px"
      borderBottom="1px solid"
      borderColor="gray.50"
    >
      <Flex flex={1} as={typeof to === 'string' ? Link : undefined} to={to && basepath + to} sx={{ '& a:focus': { outline: 'none' } }} {...props}>
        {children}
      </Flex>
      <ToggleIsRead _id={notification._id} active={notification.isRead} />
    </Flex>
  );
};

export default Wrapper;
