import { Button, ButtonProps, Skeleton, SkeletonProps } from '@chakra-ui/react';
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';

interface Props extends ButtonProps {
  isLoaded?: boolean;
  skeletonProps?: SkeletonProps;
  children?: React.ReactNode;
  to?: string;
}

const ChakraGoBack = ({ isLoaded = true, skeletonProps = undefined, children, to, ...props }: Props) => {
  const { navigate } = TenantStore.useStoreActions((actions) => actions);

  return (
    <Skeleton isLoaded={isLoaded} mr="15px" {...skeletonProps}>
      <Button
        leftIcon={<FiArrowLeft />}
        iconSpacing="0px"
        onClick={() => (to ? navigate(to) : history.back())}
        size="sm"
        {...props}
        variant="ghost"
        fontSize="22px"
        minWidth="0"
        p="0"
      >
        {children}
      </Button>
    </Skeleton>
  );
};

export default ChakraGoBack;
