import { Box, Divider, Flex, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { NavBarContainer, NavBarDefault, NavBarHeader, NavBarMobile, useNavBarContext } from '@salire-as/ui';
import useGetCurrentUser from 'api-hooks/use-get-current-user';
import React, { useEffect, useRef } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import getPages from 'Pages/pages';
import HelpCenter from 'components/HelpCenter';
import Notifications from 'components/Notifications';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import useResponsive from 'hooks/useResponsive';

import { getCurrentRoute } from '../ChakraTabs/utils';
import MenuItem from './MenuItem';
import { UserIcon } from './UserIcon';

const SidebarMenu = () => {
  const { pages } = getPages();
  const menuItems = pages.filter((page) => page.menu);

  const basepath = TenantStore.useStoreState((store) => store.basepath);
  const organization = TenantStore.useStoreState((store) => store.organization);
  const isMenuExpanded = TenantStore.useStoreState((store) => store.isMenuExpanded);

  const { isMobile } = useResponsive();
  const location = useLocation();
  const match = getCurrentRoute(basepath, pages, location);
  const notificationsPortalRef = useRef<HTMLDivElement>(null);

  const currentUser = useGetCurrentUser();

  const username = currentUser?.name;
  const navbarContext = useNavBarContext();

  const { setIsMenuExpanded } = TenantStore.useStoreActions((actions) => actions);

  useEffect(() => {
    setIsMenuExpanded(Boolean(navbarContext?.isOpen));
  }, [navbarContext]);

  if (match?.route.hideMenu) {
    return null;
  }

  return (
    <Box zIndex={['auto', '9999']} sx={{ '& > .chakra-portal': { zIndex: -1 } }} ref={notificationsPortalRef}>
      <NavBarMobile>
        <Flex gap="2" ml="auto">
          <Notifications portalContainerRef={notificationsPortalRef} />
          <UserIcon />
        </Flex>
      </NavBarMobile>
      <NavBarContainer>
        {organization && username && <NavBarHeader logo={organization.logo || undefined} organization={organization?.name} user={username} />}
        <NavBarDefault>
          {!isMobile && <Notifications portalContainerRef={notificationsPortalRef} />}
          {menuItems.map((page) => page.menu && <MenuItem page={page} key={page.name} />)}
        </NavBarDefault>
        {!isMobile && (
          <Box w="full" mt="auto" textAlign="center">
            {isMenuExpanded && <HelpCenter />}
            {!isMenuExpanded && (
              <Popover>
                <PopoverTrigger>
                  <IconButton isRound aria-label="Help center" icon={<FaQuestionCircle />} />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent w="auto">
                    <PopoverBody display="flex" flexDir="column" alignItems="center">
                      <HelpCenter />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            )}
            <Divider />
            <Box p="3">
              <UserIcon />
            </Box>
          </Box>
        )}
      </NavBarContainer>
    </Box>
  );
};

export default SidebarMenu;
