import { useQuery } from '@apollo/client';

import GET_USER_NAME from './GetUserName.graphql';
import { GetUserName } from './__types/GetUserName';

const useGetCurrentUser = () => {
  const { data } = useQuery<GetUserName>(GET_USER_NAME);
  const currentUser = data?.getUser;
  return currentUser;
};

export default useGetCurrentUser;
