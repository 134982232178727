import { Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';

import { MAX_SEARCH_CHARACTERS, MIN_SEARCH_CHARACTERS } from 'shared/constants';
import { isBetween } from 'utils/isBetween';

interface Props {
  max?: number;
  min?: number;
  value?: string;
}

export const SearchWarningMessage = ({ value, max = MAX_SEARCH_CHARACTERS, min = MIN_SEARCH_CHARACTERS }: Props) => {
  const { t } = useTranslation();
  if (!value || isBetween(value.length, min, max)) {
    return null;
  }

  return (
    <>
      <Icon verticalAlign="text-top" as={FiInfo} />
      <Text fontSize="xs">{t('common.searchLength', { min, max })}</Text>
    </>
  );
};
