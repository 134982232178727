import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

import Loading from 'components/Loading';
import getSlug from 'shared/utils/getSlug';
import setCurrentOrganization from 'shared/utils/setCurrentOrganization';

import GET_ORGANIZATION_BY_SLUG from './GetOrganizationBySlug.graphql';
import OutsideApp from './OutsideApp';
import TenantApp from './TenantApp';
import { GetOrganizationBySlug, GetOrganizationBySlugVariables } from './__types/GetOrganizationBySlug';
import { TenantStore } from './store';
import { setLastOpenOrganization } from './utils';

const RouterSwitcher = () => {
  const organization = TenantStore.useStoreState((store) => store.organization);
  const setOrganization = TenantStore.useStoreActions((actions) => actions.setOrganization);
  const isTenant = TenantStore.useStoreState((store) => store.isTenant);
  const slug = getSlug();
  const auth = useAuth();

  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && isTenant) {
      auth.signinRedirect();
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  const { loading } = useQuery<GetOrganizationBySlug, GetOrganizationBySlugVariables>(GET_ORGANIZATION_BY_SLUG, {
    skip: !auth.isAuthenticated || !!organization,
    variables: {
      slug,
    },
    onCompleted: ({ getOrganizationBySlug }) => {
      setCurrentOrganization(getOrganizationBySlug.slug, getOrganizationBySlug._id);
      setOrganization(getOrganizationBySlug);
      setLastOpenOrganization(getOrganizationBySlug.slug);
    },
  });

  if (auth.activeNavigator || loading) {
    return <Loading />;
  }

  if (organization && auth.isAuthenticated) {
    return <TenantApp />;
  }

  return <OutsideApp />;
};

export default RouterSwitcher;
