import { ApolloLink } from '@apollo/client';

let version: string | undefined;

const headersLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const versionHeader = headers.get('x-version');

      if (!version) {
        version = versionHeader;
      } else if (versionHeader && version !== versionHeader) {
        const event = new CustomEvent('versionNotification');
        window.dispatchEvent(event);
      }
    }

    return response;
  });
});

export default headersLink;
