import { action } from 'easy-peasy';

import { SettersOnly } from 'shared/types/SettersOnly';

export const addSetters = <S extends Record<string, unknown>>(setterProperties: S) => {
  return {
    ...setterProperties,
    ...Object.keys(setterProperties).reduce((setters, property) => {
      return {
        ...setters,
        ...{
          [`set${property[0].toUpperCase() + property.slice(1)}`]: action<Record<string, unknown>>((state, value) => {
            state[property] = value;
          }),
        },
      };
    }, {} as SettersOnly<S>),
  };
};
