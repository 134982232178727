import { Checkbox as CheckboxControl, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';

import { CustomFieldComponentProps } from 'components/CustomField/structures';

const Checkbox = ({ name, label, control, error, required, ...formProps }: CustomFieldComponentProps) => (
  <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CheckboxControl isChecked={value} onChange={({ target: { checked } }) => onChange(checked)}>
          <FormLabel htmlFor={name} mb="0">
            {label}
          </FormLabel>
        </CheckboxControl>
      )}
    />
    <FormErrorMessage>{error?.message}</FormErrorMessage>
  </FormControl>
);

export default Checkbox;
