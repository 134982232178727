import { useQuery } from '@apollo/client';
import { Grid, ModalBody, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'components/useModal';

import GET_USER_ORGANIZATIONS from './GetUserOrganizations.graphql';
import Organization from './Organization';
import { GetUserOrganizations } from './__types/GetUserOrganizations';

interface Props {
  closeAction: () => void;
}

const SwitchOrganization = ({ closeAction }: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery<GetUserOrganizations>(GET_USER_ORGANIZATIONS);

  const { Modal } = useModal(true, {
    onClose: () => {
      closeAction();
    },
  });

  return (
    <Modal title={t('Profile.SwitchOrganization.title')} scrollBehavior="inside">
      <ModalBody>
        <Text mb="4">{t('Profile.SwitchOrganization.description')}</Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {data?.getUser.organizations.map((organization) => (
            <Organization onClose={closeAction} {...organization} key={organization._id} />
          ))}
        </Grid>
      </ModalBody>
    </Modal>
  );
};

export default SwitchOrganization;
