import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TenantStore } from '../store';

interface Props {
  to: string;
  width?: string;
  children?: ReactNode;
  hidden?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  style?: CSSProperties;
  isExternal?: boolean;
}

const TenantLink = ({ children, to, width, hidden, onClick, style, isExternal }: Props) => {
  const basepath = TenantStore.useStoreState((store) => store.basepath);
  const styles = {
    ...(width ? { width } : {}),
    ...style,
  };
  const { pathname } = useLocation();

  if (isExternal) {
    return (
      <a href={to} style={styles}>
        {children}
      </a>
    );
  }

  return (
    <Link hidden={hidden} style={styles} to={`${basepath}${to}`} state={{ from: pathname }} onClick={onClick}>
      {children}
    </Link>
  );
};

export default TenantLink;
