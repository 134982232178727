import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const AppIcon = (props: IconProps = {}) => (
  <Icon {...props}>
    <g transform="translate(-2.000000,28.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M109 241 c-13 -10 -30 -16 -36 -14 -7 3 -13 0 -13 -6 0 -6 5 -11 10
-11 19 0 10 -31 -12 -40 -16 -6 -18 -9 -7 -9 19 -1 32 -26 25 -50 -11 -34 99
-65 144 -41 20 10 74 100 61 100 -5 0 -14 14 -21 30 -7 17 -22 37 -34 45 -29
21 -89 19 -117 -4z m116 -26 c38 -37 31 -44 -36 -42 -48 2 -64 6 -73 20 -9 15
-7 21 8 33 30 22 74 17 101 -11z m-108 -41 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m83 -14 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-52 -16 c2 -1 -6 -8 -18 -14 -18 -10 -24 -9 -31 1
-15 24 -10 30 19 23 15 -4 29 -8 30 -10z m102 -3 c0 -4 -9 -20 -21 -35 -22
-28 -69 -35 -99 -16 -13 9 -11 13 10 30 14 10 34 21 45 23 37 8 65 7 65 -2z"
      />
    </g>
  </Icon>
);
