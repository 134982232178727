import i18n, { TFunction } from 'i18next';
import parseDuration from 'parse-duration';
import { initReactI18next } from 'react-i18next';
import * as Yup from 'yup';

import { TIME_UNITS } from './constants';
import addCustomMethods from './customMethods';
import en from './en.json';
import nb from './nb.json';
import { buildYupLocale } from './yup';

const resources = {
  en: {
    translation: en,
  },
  nb: {
    translation: nb,
  },
};

const buildParseDuration = (error: unknown, t: TFunction) => {
  for (const mainUnit of TIME_UNITS) {
    const units = t(`HumanizedDuration.Units.${mainUnit}`).split(', ');
    units.forEach((unit) => {
      parseDuration[unit] = parseDuration[mainUnit];
    });
  }
};

export const buildCallback = (error: unknown, t: TFunction) => {
  buildYupLocale(error, t);
  buildParseDuration(error, t);
};

i18n.use(initReactI18next).init(
  {
    // we init with resources
    lng: 'nb',
    resources,
    fallbackLng: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  },
  buildCallback,
);

addCustomMethods();

export const yup = Yup;
export const localParseDuration = parseDuration;
export default i18n;
export { buildYupLocale };
