import { Avatar, Badge, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { NotificationFragment_SaleNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';

import { Currency } from '__types/graphql';
import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import useLocale from 'hooks/useLocale';
import { getSaleCardPath } from 'shared/utils/links';

import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';

interface Props {
  notification: NotificationFragment_SaleNotification_;
}

const WonSale = ({ notification }: Props) => {
  const { formatCurrency } = useLocale(notification.sale.currency || Currency.NOK);
  const onClose = NotificationsStore.useStoreState((store) => store.onClose);

  return (
    <Wrapper notification={notification} to={getSaleCardPath(notification.sale._id)} onClick={() => onClose?.()}>
      <Flex>
        <Avatar name={notification.sale.creator.name} size="sm" mr="2.5" borderRadius="3px" fontWeight="medium" />
        <Box>
          <Text fontSize="sm" color="gray.600" mb="3px" as="span">
            <Trans
              i18nKey={`NotificationsInbox.${notification.type}.innerText`}
              values={{ creator: notification.sale.creator.name, saleValue: formatCurrency(notification.sale.currencySubtotal) }}
              components={{
                text: <Text color="gray.700" d="inline" />,
              }}
            />
          </Text>
          <Flex align="center">
            <Badge bgColor="green.400" color="white" fontSize="11px" fontWeight="medium" mr="2.5" px="2" py="3px" borderRadius="25px">
              {`#${notification.sale.ref}`}
            </Badge>
            <Tooltip label={moment(notification.sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
              <Text fontSize="xs" color="gray.500">
                {notification.sentAt && getCorrectNotificationDateToDisplay(notification.sentAt)}
              </Text>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default WonSale;
