import { InMemoryCache } from '@apollo/client';
import { isEqual, merge, uniqBy } from 'lodash';

function mergeConversations(existing = [], incoming = []) {
  return uniqBy([...incoming, ...existing], '__ref');
}

function mergeOrganizationSales(existing = {}, incoming = {}) {
  return merge({}, existing, incoming);
}

function mergeEventAttendee(existing = [], incoming = []) {
  return uniqBy([...incoming, ...existing], 'customer.__ref');
}

function mergeActivities(existing = [], incoming = []) {
  const merged = [...existing];

  const check = (toCheck: never) => {
    const isExisting = merged.find((mergedOne) => isEqual(toCheck, mergedOne));
    if (!isExisting) merged.unshift(toCheck);
  };

  incoming.forEach(check);
  return merged;
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        activities: {
          merge: mergeActivities,
        },
      },
    },
    Event: {
      fields: {
        attendee: {
          merge: mergeEventAttendee,
        },
      },
    },
    Organization: {
      fields: {
        sales: {
          merge: mergeOrganizationSales,
        },
      },
    },
    Customer: {
      fields: {
        category: {
          merge: false,
        },
        assigned: {
          merge: false,
        },
        conversations: {
          merge: mergeConversations,
        },
      },
    },
    Calendar: {
      fields: {
        backgroundColor: {
          merge(existing, incoming) {
            return existing ?? incoming;
          },
        },
      },
    },
  },
  possibleTypes: {
    Activity: [
      'SaleActivity',
      'SaleActivityWithStatus',
      'SaleActivityWithExpectedDate',
      'SaleActivityWithProbability',
      'OrderActivity',
      'CustomerActivity',
      'EventActivity',
      'TaskActivity',
      'TaskActivityWithWorkflow',
      'TaskActivityWithDueDate',
      'RelationActivity',
      'NoteActivity',
      'MessageActivity',
      'FileActivity',
      'TimeTrackingActivity',
    ],
    ConversationRecipientsInterface: ['ConversationMessage', 'ConversationRecipients'],
    Notification: [
      'SaleNotification',
      'EventNotification',
      'ExpiringContractNotification',
      'TaskNotification',
      'MentionNotification',
      'ExportableNotification',
      'MailerNotification',
      'BackChannelAuthRequestNotification',
      'SharingNotification',
    ],
  },
});

export default cache;
