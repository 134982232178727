import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import Loading from 'components/Loading';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import { VITE_ORGANIZATION_SELECTION_PATH } from 'shared/constants';

export const Callback = () => {
  const auth = useAuth();
  const org = TenantStore.useStoreState((store) => store.organization);

  useEffect(() => {
    if (auth.isAuthenticated && !org) {
      window.open(`${document.location.origin}${VITE_ORGANIZATION_SELECTION_PATH}`, '_self');
    }
  }, [auth.isAuthenticated]);

  return <Loading />;
};
