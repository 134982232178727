import { action, computed, createContextStore, persist, thunk } from 'easy-peasy';
import { omit } from 'lodash';

import { EntityName } from '__types/graphql';
import client from 'client';
import { SaveOnboarding, SaveOnboardingVariables } from 'components/TenantRouter/RouterSwitcher/__types/SaveOnboarding';
import { TENANT_PATH } from 'shared/constants';
import { PropType } from 'shared/types/PropType';
import { addSetters } from 'shared/utils/addSetters';
import { truthy } from 'shared/utils/truthy';

import GET_SETTINGS from './GetSettings.graphql';
import SAVE_ONBOARDING from './SaveOnboarding.graphql';
import SAVE_TASK_SETTINGS from './SaveTaskSettings.graphql';
import { GetOrganizationBySlug } from './__types/GetOrganizationBySlug';
import { GetSettings, GetSettingsVariables } from './__types/GetSettings';
import { SaveTaskSettings, SaveTaskSettingsVariables } from './__types/SaveTaskSettings';
import { RuntimeModel, TenantState } from './structures';

export const TenantStore = createContextStore<TenantState, Record<string, unknown>, RuntimeModel>(
  (runtimeModel) =>
    persist<TenantState>(
      {
        ...addSetters({
          organization: undefined,
          user: null,
          adminRoleTab: 0,
          isMenuExpanded: true,
          entitySettings: {},
          areEntitySettingsDirty: {},
        }),
        saveOnboarding: thunk(async (actions, input) => {
          try {
            const { data } = await client.mutate<SaveOnboarding, SaveOnboardingVariables>({
              mutation: SAVE_ONBOARDING,
              variables: {
                input,
              },
            });

            if (data) {
              actions.setOrganization(data.saveOnboarding);
            }
            return true;
          } catch {
            return false;
          }
        }),
        isTenant: computed(() => window.location.pathname.includes(TENANT_PATH)),
        tenantSlug: computed((state) => (state.isTenant ? window.location.pathname.split('/')?.[2] : '')),
        basepath: computed((state) => (state.isTenant ? `${TENANT_PATH}${state.tenantSlug}` : '')),
        rawNavigate: thunk((_, path) => {
          runtimeModel?.navigate(path);
        }),
        navigate: thunk((_, path, { getState }) => {
          const state = getState();
          runtimeModel?.navigate(state.basepath + path);
        }),
        pathname: computed(() => {
          return window.location.pathname.split('/')?.splice(3)?.join('/');
        }),
        customerTypesDisabled: computed((state) => {
          return state.organization
            ? (Object.entries(state.organization.customer.types)
                .map(([key, value]) => !value && key)
                .filter(truthy) as Array<Exclude<keyof PropType<GetOrganizationBySlug, 'getOrganizationBySlug.customer.types'>, '__typename'>>)
            : [];
        }),
        adminRoleSubjectsExpanded: {},
        setAdminRoleSubjectExpanded: action((state, [key, isExpanded]) => {
          state.adminRoleSubjectsExpanded[key] = isExpanded;
        }),
        setCustomerTypes: action((state, customerTypes) => {
          if (state.organization) state.organization.customer.types = customerTypes;
        }),
        loadEntitySettings: thunk(async (actions, variables, { getStoreState }) => {
          const { data } = await client.query<GetSettings, GetSettingsVariables>({
            query: GET_SETTINGS,
            variables,
          });

          const key = [variables.domain, variables.area].join('|');

          if (data.getSettings.__typename === 'TaskSettings') {
            actions.setEntitySettings({
              ...getStoreState().entitySettings,
              [key]: data.getSettings.taskSettings,
            });

            actions.setAreEntitySettingsDirty({
              ...getStoreState().areEntitySettingsDirty,
              [key]: false,
            });
          }
        }),
        updateEntitySettings: action((state, [key, value]) => {
          const settings = state.entitySettings[key];

          if (settings) {
            state.entitySettings = {
              ...state.entitySettings,
              [key]: {
                ...settings,
                ...value,
              },
            };

            state.areEntitySettingsDirty = {
              ...state.areEntitySettingsDirty,
              [key]: true,
            };
          }
        }),
        saveEntitySettings: thunk(async (actions, key, { getStoreState }) => {
          const [domain, area] = key.split('|').map((element) => element || null);
          const payload = omit(getStoreState().entitySettings[key], '__typename');

          if ((domain === EntityName.TASK || area === EntityName.TASK) && payload) {
            await client.mutate<SaveTaskSettings, SaveTaskSettingsVariables>({
              mutation: SAVE_TASK_SETTINGS,
              variables: {
                domain: domain as EntityName,
                area: area as EntityName | null,
                payload,
              },
            });
          }

          actions.setAreEntitySettingsDirty({
            ...getStoreState().areEntitySettingsDirty,
            [key]: false,
          });
        }),
        updateAreEntitySettingsDirty: action((state, [key, value]) => {
          state.areEntitySettingsDirty = {
            ...state.areEntitySettingsDirty,
            [key]: value,
          };
        }),
      },
      {
        allow: ['isMenuExpanded'],
        storage: {
          getItem: () => JSON.parse(localStorage.getItem(`Tenant:${window.location.pathname.split('/')?.[2]}`) as string),
          setItem: (key, data) => {
            return localStorage.setItem(`Tenant:${window.location.pathname.split('/')?.[2]}`, JSON.stringify(data));
          },
          removeItem: () => {
            return localStorage.removeItem(`Tenant:${window.location.pathname.split('/')?.[2]}`);
          },
        },
      },
    ),
  {
    name: 'TenantStore',
  },
);
