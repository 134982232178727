import { useRef, useLayoutEffect, DependencyList } from 'react';

export default function useInitLayoutEffect(effect: () => void, dependencies: DependencyList = [], cleanup?: () => void) {
  const isInitialMount = useRef(true);

  useLayoutEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      effect();
    }
    if (cleanup) {
      return cleanup;
    }
  }, dependencies);
}
