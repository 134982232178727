import { useBreakpointValue } from '@chakra-ui/react';
import { mapValues } from 'lodash';

import { UseResponsive } from './structures';

const useResponsive: UseResponsive = (props, responsiveValues) => {
  const { defaultMobile = true } = props || {};
  const isMobile = useBreakpointValue([true, false]) ?? defaultMobile;
  const mobileKey = isMobile ? 'mobile' : 'desktop';
  const values = mapValues(responsiveValues, (value) => {
    return isMobile ? value[0] : value[1];
  });

  return { isMobile, mobileKey, ...values };
};

export default useResponsive;
