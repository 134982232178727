import React, { useEffect, useState } from 'react';

import useModal from 'components/useModal';
import { wrapPromise } from 'shared/utils/promise';

import PermissionModal from './permission-modal';

export interface State {
  state: NotificationPermission;
}

const isNotificationApiSupported = () => 'Notification' in window;

const useDesktopNotification = () => {
  const [state, setState] = useState<State>({ state: isNotificationApiSupported() ? Notification.permission : 'denied' });
  const modal = useModal(false, { closeButton: false });

  const requestPermission = async () => {
    if (isNotificationApiSupported() && state.state === 'default') {
      modal.onOpen();
      const result = await Notification.requestPermission();
      setState({ state: result });
      modal.onClose();
    }

    if ('permissions' in navigator) {
      const [result] = await wrapPromise(navigator.permissions.query({ name: 'notifications' }));
      if (result) {
        result.onchange = function () {
          setState({ state: result.state === 'prompt' ? 'default' : result.state });
        };
      }
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  return {
    ...state,
    requestPermission,
    Modal: <PermissionModal {...modal} state={state.state} />,
    onOpen: modal.onOpen,
    onClose: modal.onClose,
  };
};

export default useDesktopNotification;
