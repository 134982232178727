import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InViewTrigger from 'components/InViewTrigger';
import Loading from 'components/Loading';

import { ITEMS_PER_PAGE } from '../constants';
import { CommonProps, DropdownProps, LabelConditionalProps, QueryTypeLike, RequiredVariables, VariantConditionalProps } from '../structures';
import { isExtractData } from '../utils';
import Label from './Label';

const Dropdown = <QueryType extends QueryTypeLike, Variables>(
  props: CommonProps<QueryType, Variables> & DropdownProps<QueryType> & LabelConditionalProps<QueryType> & VariantConditionalProps<QueryType>,
) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const { variables, query, search, label, labelComponent: LabelComponent, onClose, onToggle, extraOptions, dataFilter, selected, onCompleted } = props;

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery<QueryType, Variables & RequiredVariables>(query, {
    variables: { limit: ITEMS_PER_PAGE, skip: 0, search: search.trim(), ...variables } as Variables & RequiredVariables,
    onCompleted: (responseData) => {
      if (responseData.data.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }
      onCompleted?.(responseData);
    },
  });

  const loadMore = () => {
    fetchMore<QueryType, Variables & RequiredVariables>({
      variables: {
        skip: data?.data.length || 0,
      } as Variables & RequiredVariables,
      updateQuery: (prev, { fetchMoreResult }) => {
        const countItems = fetchMoreResult?.data.length || 0;
        if (countItems === 0) {
          setHasMore(false);
          return prev;
        } else {
          const update = Object.assign(
            {},
            {
              data: [...prev.data, ...(fetchMoreResult?.data || [])],
            },
          ) as QueryType;

          if (countItems < ITEMS_PER_PAGE) setHasMore(false);
          return update;
        }
      },
    });
  };

  const labelProps = { selected, onSelect: props.select, dropdownVariant: props.dropdownVariant };

  const filteredOutData = dataFilter ? data?.data.filter(dataFilter) : data?.data;

  return (
    <Flex direction="column" maxH="248px" ref={scrollRef} overflow="auto">
      {isLoading ? (
        <Loading minH="248px" />
      ) : (
        <>
          {extraOptions && extraOptions.map((Option) => <Option key={Option.name} onClose={onClose} onToggle={onToggle} />)}
          {filteredOutData?.map(
            (element) =>
              isExtractData<QueryType>(element) &&
              (LabelComponent ? (
                <LabelComponent key={element._id} data={element} {...labelProps} />
              ) : (
                label && <Label key={element._id} data={element} label={label} {...labelProps} />
              )),
          )}
          {scrollRef.current !== null && (
            <InViewTrigger
              key={scrollRef.current.tagName}
              callback={() => loadMore()}
              isWatching={(data?.data.length || 0) >= ITEMS_PER_PAGE}
              options={{
                root: scrollRef.current,
                rootMargin: '0px 0px 600px 0px',
              }}
            >
              <Loading minHeight="32px" hidden={!hasMore} mt="5" />
            </InViewTrigger>
          )}
          {filteredOutData?.length === 0 && (
            <Flex p="2.5" justifyContent="center">
              {t('Dropdown.noMatches')}
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export default Dropdown;
