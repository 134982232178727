import { Flex } from '@chakra-ui/react';
import { range } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import Picker from './Picker';

interface Props {
  date: Date;
  onChange: (date: Date) => void;
  yearRange?: number;
}

const Navbar = ({ date, onChange, yearRange = 5 }: Props) => {
  const months = moment.months();
  const years = range(
    moment().subtract(yearRange, 'year').year(),
    moment()
      .add(yearRange + 1, 'year')
      .year(),
  );

  const onMonthChange = (month: string) => onChange(moment({ year: moment(date).year(), month: months.indexOf(month), day: 15 }).toDate());
  const onYearChange = (year: number) => onChange(moment({ year, month: moment(date).month() }).toDate());

  return (
    <Flex>
      <Picker options={months} value={months[moment(date).month()]} mr="1" onChange={onMonthChange} />
      <Picker options={years} value={moment(date).year()} onChange={onYearChange} />
    </Flex>
  );
};

export default Navbar;
