import { useEffect, useState } from 'react';

import { MAX_SEARCH_CHARACTERS, MIN_SEARCH_CHARACTERS, SEARCH_DELAY_MS } from 'shared/constants';
import { isBetween } from 'utils/isBetween';

interface Props {
  delay?: number;
  max?: number;
  min?: number;
}

export const useDebouncedSearchValue = (val: string, { max = MAX_SEARCH_CHARACTERS, min = MIN_SEARCH_CHARACTERS, delay = SEARCH_DELAY_MS }: Props) => {
  const [result, setResult] = useState<string>(val);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (val === '' || (val && isBetween(val.length, min, max))) {
        setResult(val);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [val, delay]);

  return result;
};
