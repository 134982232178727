/* eslint-disable no-shadow */
import styled from 'styled-components';

import close from 'images/forms/close.svg';
import edit from 'images/forms/edit.svg';

export default styled.button`
  background: transparent url(${close}) no-repeat;
  background-size: ${(props) => (props.size ? props.size - 5 : 20 - 5)}px !important;
  background-position: center center;
  cursor: pointer;
  width: ${(props) => (props.size ? props.size : 20)}px;
  height: ${(props) => (props.size ? props.size : 20)}px;
  border: none;
  outline: none;

  ${(props) =>
    props.type === 'edit' &&
    `
        background: transparent url(${edit}) no-repeat;
        background-size: ${(props) => (props.size ? props.size - 5 : 20 - 5)}px !important;
        background-position: center center;
    `}
`;
