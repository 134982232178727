import { Avatar, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { NotificationFragment_TaskNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';

import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import { getTaskPath } from 'shared/utils/links';

import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';

interface Props {
  notification: NotificationFragment_TaskNotification_;
}

const TaskAssignedExpiring = ({ notification }: Props) => {
  const { sentAt, task, createdBy } = notification;
  const { title, dueAt, _id } = task;

  const onClose = NotificationsStore.useStoreState((store) => store.onClose);

  return (
    <Wrapper notification={notification} to={getTaskPath(_id)} onClick={() => onClose?.()}>
      <Flex>
        <Avatar name={createdBy?.name} size="sm" mr="2.5" borderRadius="3px" fontWeight="medium" />
        <Box>
          <Text fontSize="sm" color="gray.600" mb="3px" as="span">
            <Trans
              i18nKey={`NotificationsInbox.${notification.type}.innerText`}
              values={{ time: moment(dueAt).to(sentAt, true), title }}
              components={{
                text: <Text color="gray.700" d="inline" />,
              }}
            />
          </Text>
          <Flex align="center">
            <Tooltip label={moment(sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
              <Text fontSize="xs" color="gray.500">
                {sentAt && getCorrectNotificationDateToDisplay(sentAt)}
              </Text>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default TaskAssignedExpiring;
