import isoCountries, { Alpha2Code } from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import no from 'i18n-iso-countries/langs/no.json';

import { ISO_COUNTRIES_LANGUAGE } from 'shared/constants';
import { Country } from 'shared/interfaces/Country';

isoCountries.registerLocale(en);
isoCountries.registerLocale(no);

export const getCountries = (lang: string): Country[] =>
  Object.entries(isoCountries.getNames(ISO_COUNTRIES_LANGUAGE[lang as keyof typeof ISO_COUNTRIES_LANGUAGE])).map((entry) => ({
    code: entry[0] as Alpha2Code,
    name: entry[1],
    search: {
      en: isoCountries.getName(entry[0], 'en'),
      no: isoCountries.getName(entry[0], 'no'),
    },
  }));
