import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Tabs, Text } from '@chakra-ui/react';
import { useNavBarContext, useTheme } from '@salire-as/ui';
import React, { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';

import useDesktopNotification from 'hooks/useDesktopNotification';
import { MENU_ANIMATION_DURATION } from 'shared/constants';

import Content from './Content';
import Header from './Header';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  portalContainerRef?: RefObject<HTMLElement | null>;
}

const Inbox = ({ isOpen, onClose, portalContainerRef }: Props) => {
  const { t } = useTranslation();
  const { isOpen: isMenuExpanded, isMobile } = useNavBarContext();
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { state, onOpen, Modal } = useDesktopNotification();
  const notificationsDisabled = state !== 'granted';

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" portalProps={isMobile ? {} : { containerRef: portalContainerRef }} size="sm">
      <DrawerOverlay zIndex="0">
        <DrawerContent
          left={['0px', isMenuExpanded ? '250px !important' : '60px !important']}
          top="0px !important"
          height="100vh"
          transition={['', `left ${MENU_ANIMATION_DURATION} ease-in-out`]}
        >
          <DrawerCloseButton top="20px" right="20px" />
          <DrawerHeader p="5" fontWeight="medium" color="gray.700" bgColor="gray.100">
            {t('NotificationsInbox.title')}
          </DrawerHeader>
          <DrawerBody p="0" ref={ref}>
            {notificationsDisabled && (
              <Flex gap=".5em" justifyContent="space-between" alignItems="center" p="2" role="group">
                <Text display="flex" gap=".5em" alignItems="center">
                  <FiAlertTriangle color="orange" fontWeight="bold" /> {t('NotificationsInbox.messages.warning.notificationsDisabled')}
                </Text>
                <Button variant="submit" size="sm" onClick={() => onOpen()}>
                  {t('common.enable')}
                </Button>
              </Flex>
            )}
            <Tabs isLazy colorScheme={theme.selectedTheme.id} d="flex" flexDir="column" h="100%">
              <Header />
              <Content />
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
      {Modal}
    </Drawer>
  );
};

export default Inbox;
