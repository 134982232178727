import { Text } from '@chakra-ui/react';
import currency from 'currency-formatter';
import { isNil } from 'lodash';
import * as React from 'react';

const useLocale = (code = 'NOK') => {
  return {
    country: 'not implemnted',
    currency: code,
    FormatCurrency: ({ children, ...props }) => {
      if (isNil(children)) return null;
      return (
        <Text as="span" d="inline-block" {...props}>
          {currency.format(children, { code })}
        </Text>
      );
    },
    formatCurrency: (price) => {
      return currency.format(price, { code });
    },
  };
};

export default useLocale;
