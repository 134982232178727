import React, { useState } from 'react';
import styled from 'styled-components';

const Image = styled.div`
  width: ${(props) => props.dimention}px;
  height: ${(props) => props.dimention}px;
  border-radius: ${(props) => (props.radius ? props.radius : 0)}px;
  background: #eee url('${(props) => (props.image === null ? props.placeholder : props.image)}') no-repeat center center;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
  .edit {
    width: 100%;
    height: 100%;
    background: rgba(181, 181, 181, 0.36);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
  }
  .fileInput {
    opacity: 0;
  }
  &:hover {
    .edit {
      opacity: 1;
    }
  }
  ${(props) => `
        margin-right: ${props.margin && props.margin.right}px;
        margin-left: ${props.margin && props.margin.left}px;
        margin-top: ${props.margin && props.margin.top}px;
        margin-bottom: ${props.margin && props.margin.bottom}px;
    `}
`;

export default ({
  hasImage = null,
  placeholder = 'https://imbindonesia.com/images/placeholder/camera.jpg',
  dimention = 50,
  radius = 0,
  onSelect,
  ...props
}) => {
  // State for image
  const [image, setImage] = useState(hasImage);

  // Read file to show as image
  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    // You have to declare the file loading
    reader.readAsDataURL(file);
  };

  // Add file to state
  const addFile = (e) => {
    // Return file
    const selected = e.target.files[0];
    onSelect(selected);

    // Read the file
    readFile(selected);
  };

  // OnClick to fire file selector
  const onClick = () => {
    const ref = document.querySelector('.fileInput');
    ref.click();
  };

  return (
    <Image {...props} onClick={onClick} image={image} dimention={dimention} radius={radius}>
      <div className="edit">Upload image</div>
      <input type="file" className="fileInput" onChange={addFile} />
    </Image>
  );
};
