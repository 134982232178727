import { Organization } from './structures';

export const isOrganizationArray = (data: unknown): data is Organization[] =>
  Array.isArray(data) &&
  data.every(
    (organization) =>
      typeof organization === 'object' &&
      organization !== null &&
      'name' in organization &&
      'slug' in organization &&
      typeof organization.name === 'string' &&
      typeof organization.slug === 'string',
  );

export const setLastOpenOrganization = (slug: string) => {
  localStorage.setItem('lastOpenOrganization', slug);
};
