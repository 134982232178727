import i18n from 'i18next';
import moment from 'moment-timezone';
import { phone } from 'phone';
import * as Yup from 'yup';

import { STANDARD_DATE_FORMAT, STANDARD_TIME_FORMAT } from 'shared/constants';
import { getTimeFromDate } from 'utils/getTimeFromDate';

const addCustomMethods = () => {
  Yup.addMethod<Yup.StringSchema>(Yup.string, 'minutesGreater', function (key, minutes, label) {
    return this.test('minutesGreater', function (value) {
      const compareToValue = this.parent?.[key] as moment.MomentInput;
      const duration = moment(value, STANDARD_TIME_FORMAT).diff(moment(compareToValue, STANDARD_TIME_FORMAT), 'minutes');
      const isValid = duration >= minutes;
      if (!isValid)
        return this.createError({
          message: i18n.t('common.minutesGreater', { time: compareToValue || key, minutes, label, context: label ? 'label' : '' }),
        });
      return isValid;
    });
  });

  Yup.addMethod<Yup.StringSchema>(Yup.string, 'minutesGreaterWithDates', function (compareToKey, dateFromKey, dateToKey, minutes, label) {
    return this.test('minutesGreaterWithDates', function (value) {
      const compareToValue = this.parent[compareToKey];
      const dateFrom = this.parent[dateFromKey];
      const dateTo = this.parent[dateToKey];

      let duration;
      if (!compareToValue) return true;
      if (dateFrom && dateTo && moment(dateFrom).format(STANDARD_DATE_FORMAT) !== moment(dateTo).format(STANDARD_DATE_FORMAT)) {
        return true;
      } else {
        duration = moment(value, STANDARD_TIME_FORMAT).diff(moment(compareToValue, STANDARD_TIME_FORMAT), 'minutes');
      }
      const isValid = duration >= minutes;
      if (!isValid) {
        return this.createError({
          message: i18n.t('common.minutesGreater', { time: compareToValue || compareToKey, minutes, label, context: label ? 'label' : '' }),
        });
      }
      return isValid;
    });
  });

  Yup.addMethod<Yup.StringSchema>(Yup.string, 'isSameOrAfter', function (key, field) {
    return this.test('isSameOrAfter', function (value) {
      const compareToValue = this.parent?.[key] as moment.MomentInput;
      const isValid = moment(value).isSameOrAfter(moment(compareToValue));
      if (!isValid) return this.createError({ message: i18n.t('common.isSameOrAfter', { field }) });
      return isValid;
    });
  });

  Yup.addMethod<Yup.DateSchema>(Yup.date, 'laterThan', function (key, minutes, label) {
    return this.test('laterThan', function (value) {
      const compareToValue = this.parent?.[key] as moment.MomentInput;
      const duration = moment(value, STANDARD_TIME_FORMAT).diff(moment(compareToValue, STANDARD_TIME_FORMAT), 'minutes');
      const isValid = duration >= minutes;
      if (!isValid)
        return this.createError({
          message: i18n.t('common.minutesGreater', {
            time: getTimeFromDate(compareToValue as Date) || key,
            minutes,
            label,
            context: label ? 'label' : '',
          }),
        });
      return isValid;
    });
  });

  Yup.addMethod<Yup.StringSchema>(Yup.string, 'phone', function (nullable?: boolean) {
    return this.test('phone', function (value) {
      if (!value && nullable) return true;
      else if (!value) return this.createError({ message: i18n.t('common.invalidPhone') });
      if (!value.startsWith('+')) return this.createError({ message: i18n.t('common.invalidPhoneCountryCode') });
      const { isValid } = phone(value);
      if (!isValid) return this.createError({ message: i18n.t('common.invalidPhone') });
      return isValid;
    });
  });
};

export default addCustomMethods;
