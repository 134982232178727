import { PureQueryOptions, RefetchQueriesFunction, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SearchPersonFragment } from 'fragments/__types/SEARCH_PERSON';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import client from 'client';
import { subject, useAbility } from 'client/ability';
import CustomField from 'components/CustomField';
import { generateDynamicCustomFieldsSchema } from 'components/CustomField/utils';
import { ChakraMainButton } from 'components/Form';
import ChakraDeleteButton from 'components/Form/ChakraDeleteButton';
import { PersonModel } from 'hooks/usePersonHandler/structures';
import { CALLBACK_TYPE } from 'shared/constants';
import { Fields } from 'shared/types/Fields';
import { filterMetaFieldsInput } from 'utils/filterMetaFieldsInput';
import mapMetaFields from 'utils/mapMetaFields';

import CREATE_PERSON from '../../CreatePerson.graphql';
import DELETE_PERSON from '../../DeletePerson.graphql';
import UPDATE_PERSON from '../../UpdatePerson.graphql';
import { CreatePerson, CreatePersonVariables } from '../../__types/CreatePerson';
import { DeletePerson, DeletePersonVariables } from '../../__types/DeletePerson';
import { UpdatePerson, UpdatePersonVariables } from '../../__types/UpdatePerson';
import GET_PEOPLE_CUSTOM_FIELDS from './GetPeopleCustomFields.graphql';
import GET_PERSON from './GetPerson.graphql';
import { GetPeopleCustomFields } from './__types/GetPeopleCustomFields';
import { GetPerson, GetPersonVariables } from './__types/GetPerson';
import { getValidationSchema } from './utils';

interface Props {
  onClose: () => void;
  person?: GraphQL_ObjectId;
  customer?: GraphQL_ObjectId;
  onComplete?: (person: SearchPersonFragment, type: CALLBACK_TYPE) => void;
  onChange?: (person?: SearchPersonFragment) => void;
  refetch?: () => void;
  refetchQueries?: (string | PureQueryOptions)[] | RefetchQueriesFunction;
  showMarkAsFavorite?: boolean;
}

const Form = ({ person, customer, onClose, onComplete, onChange, refetch, refetchQueries = [], showMarkAsFavorite = true }: Props) => {
  // Translate
  const { t } = useTranslation();
  const [defaultValues, setDefalutValues] = useState<Partial<Omit<SearchPersonFragment, 'meta'>> & { meta?: { fields: Fields } }>({});

  const { data: customFieldsData } = useQuery<GetPeopleCustomFields>(GET_PEOPLE_CUSTOM_FIELDS);

  // Form state
  const methods = useForm<PersonModel>({
    resolver: yupResolver(
      customFieldsData?.getCustomFields
        ? getValidationSchema().concat(generateDynamicCustomFieldsSchema(customFieldsData.getCustomFields))
        : getValidationSchema(),
    ),
    defaultValues: {
      favorite: true,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    formState,
    reset,
    control,
  } = methods;

  // Get defaultValues
  useEffect(() => {
    if (person) {
      client
        .query<GetPerson, GetPersonVariables>({
          query: GET_PERSON,
          variables: {
            person,
          },
        })
        .then(({ data }) => {
          const personData = omit(data.getPerson, ['__typename', 'createdAt', '_id', 'creator', 'meta']);
          reset({ ...personData, meta: { fields: data.getPerson.meta?.fields as Fields } });
          setDefalutValues({ ...personData, meta: { fields: data.getPerson.meta?.fields as Fields } });
        });
    }
  }, []);

  const ability = useAbility();

  const isEditable =
    (!person && true) ||
    (defaultValues?.creator
      ? ability.can(
          'update',
          subject('Person', {
            creator: defaultValues?.creator?._id,
          }),
        )
      : true) ||
    false;

  const isDeletable =
    (defaultValues?.creator
      ? ability.can(
          'delete',
          subject('Person', {
            creator: defaultValues?.creator?._id,
          }),
        )
      : true) || false;

  // Mutation
  const [update, { loading: updateLoading }] = useMutation<UpdatePerson, UpdatePersonVariables>(UPDATE_PERSON);
  const [create, { loading: createLoading }] = useMutation<CreatePerson, CreatePersonVariables>(CREATE_PERSON, {
    refetchQueries,
  });

  const [remove, { loading: deleteLoading }] = useMutation<DeletePerson, DeletePersonVariables>(DELETE_PERSON, {
    refetchQueries,
  });

  const onSubmit = (input: PersonModel) => {
    if (person) {
      update({
        variables: {
          person,
          input: {
            ...omit(input, ['deletedAt']),
            meta: { fields: mapMetaFields(filterMetaFieldsInput(input.meta?.fields)) },
          },
        },
        update: (_, result) => {
          if (result?.data?.updatePerson) {
            onComplete?.(result.data.updatePerson, CALLBACK_TYPE.UPDATE);
            refetch?.();
          }
        },
      });
    } else {
      create({
        variables: {
          input: {
            ...omit(input, ['deletedAt']),
            meta: { fields: mapMetaFields(filterMetaFieldsInput(input.meta?.fields)) },
            customer,
          },
        },
        update: (_, result) => {
          if (result?.data?.createPerson) {
            onComplete?.(result.data.createPerson, CALLBACK_TYPE.CREATE);
            refetch?.();
          }
        },
      });
    }
    onClose();
  };

  const deletePerson = () => {
    if (person) {
      remove({
        variables: {
          person,
        },
        update: (cache, result) => {
          if (result?.data?.deletePerson) {
            cache.evict({ id: cache.identify({ ...result?.data?.deletePerson }) });
            cache.gc();

            onComplete?.(result.data.deletePerson, CALLBACK_TYPE.DELETE);
            onChange?.(undefined);
            refetch?.();
            onClose();
          }
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormControl isInvalid={!!errors.name} mb="4">
          <FormLabel>{t('People.name.label')}</FormLabel>
          <Input
            data-cy="customer-card-create-person-name"
            isReadOnly={!isEditable}
            size="sm"
            {...register('name')}
            placeholder={t('People.name.placeholder')}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email} mb="4">
          <FormLabel>{t('People.email.label')}</FormLabel>
          <Input
            data-cy="customer-card-create-person-email"
            isReadOnly={!isEditable}
            size="sm"
            {...register('email')}
            placeholder={t('People.email.placeholder')}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.phone} mb="4">
          <FormLabel>{t('People.phone.label')}</FormLabel>
          <Input
            data-cy="customer-card-create-person-phone"
            isReadOnly={!isEditable}
            size="sm"
            {...register('phone')}
            placeholder={t('People.phone.placeholder')}
          />
          <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.role} mb="4">
          <FormLabel>{t('People.role.label')}</FormLabel>
          <Input
            data-cy="customer-card-create-person-role"
            isReadOnly={!isEditable}
            size="sm"
            {...register('role')}
            placeholder={t('People.role.placeholder')}
          />
          <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
        </FormControl>
        {showMarkAsFavorite && (
          <FormControl isInvalid={!!errors.favorite} mb="4">
            <Controller
              name="favorite"
              control={control}
              render={({ field: { value, onChange: onChangeCheckbox } }) => (
                <Checkbox isChecked={value ?? undefined} onChange={({ target: { checked } }) => onChangeCheckbox(checked)}>
                  {t('People.favorite.label')}
                </Checkbox>
              )}
            />
            <FormErrorMessage>{errors.favorite?.message}</FormErrorMessage>
          </FormControl>
        )}
        {customFieldsData?.getCustomFields?.map(({ name, _id, ...customField }) => (
          <CustomField mb="4" key={`meta.fields.${_id}`} size="sm" name={`meta.fields.${_id}`} label={name} _id={_id} {...customField} />
        ))}
        <Flex justifyContent={person ? 'space-between' : 'flex-end'}>
          {person && <ChakraDeleteButton size="sm" hidden={!isDeletable} isLoading={deleteLoading} onClick={deletePerson} />}
          <ChakraMainButton
            data-cy="customer-card-create-person-submit-button"
            isDisabled={!formState.isDirty}
            hidden={!isEditable}
            size="sm"
            type="submit"
            isLoading={createLoading || updateLoading}
          >
            {t('common.save')}
          </ChakraMainButton>
        </Flex>
      </FormProvider>
    </form>
  );
};

export default Form;
