import { ButtonProps } from '@chakra-ui/react';
import React, { forwardRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { Contract } from '__types/graphql';
import SearchDropdown from 'components/SearchDropdown';
import { DROPDOWN_VARIANT } from 'components/SearchDropdown/constants';
import { EntityWithId } from 'shared/types/EntityWithId';

import GET_MANY_CONTRACTS from './GetManyContracts.graphql';
import { GetManyContracts, GetManyContractsVariables } from './__types/GetManyContracts';

interface Props extends Omit<ButtonProps, 'onChange' | 'value' | 'onSelect'> {
  value?: EntityWithId<Partial<Contract>>[] | null;
  onChange: (contract: EntityWithId<Partial<Contract>>[]) => void;
  placeholder?: string;
  tabIndex?: number;
  portalContainerRef?: RefObject<HTMLElement | null>;
}

const ChakraMultiSelectContractComponent = ({ value, onChange, placeholder, ...props }: Props) => {
  const { t } = useTranslation();

  const onSelect = (contract: EntityWithId<Partial<Contract>>[]) => {
    onChange?.(contract);
  };

  return (
    <SearchDropdown<GetManyContracts, Omit<GetManyContractsVariables, 'skip' | 'limit'>>
      query={GET_MANY_CONTRACTS}
      label="name"
      triggerLabel="name"
      dropdownVariant={DROPDOWN_VARIANT.MULTI_SELECT}
      selected={value ?? []}
      title={placeholder ?? t('Sale.Checkout.Contract.placeholder')}
      onSelect={onSelect}
      {...props}
    />
  );
};

const ChakraMultiSelectContract = forwardRef(ChakraMultiSelectContractComponent);

export default ChakraMultiSelectContract;
