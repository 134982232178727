import { remove } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid #eee;
  border-radius: 10px;
  width: 100%;
  background: white;
  padding: 10px 20px;
  .item {
    margin-right: 5px;
    background: #2c1868;
    color: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
  }
  input {
    background: transparent;
    border: none;
  }
`;

export default ({ name, onChange }) => {
  // State
  const [tags, setTags] = useState([]);

  useEffect(() => {
    onChange({
      target: {
        name,
        value: tags,
      },
    });
  }, [tags]);

  // Add/remove functions
  const add = (tag) => setTags([...tags, tag]);
  const removeItem = (tag) => setTags(tags.filter((o) => o !== tag));
  const removeLast = () => {
    setTags(
      remove(tags, (o, index) => {
        return index !== tags.length - 1;
      }),
    );
  };

  // handler
  const handler = (e) => {
    const empty = e.target.value === '';
    // Remove last tag
    if (e.keyCode === 8 && empty) {
      removeLast();
    }
    // Create new tag
    if ([',', 'Enter'].includes(e.key)) {
      e.preventDefault();
      add(e.target.value);
      e.target.value = '';
    }
  };

  return (
    <Fragment>
      <Wrapper>
        {tags.map((item) => (
          <span className="item">
            {item} <span onClick={() => removeItem(item)}>x</span>
          </span>
        ))}
        <input onKeyDown={handler} />
      </Wrapper>
    </Fragment>
  );
};
