import { Button, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yup } from 'languages/index';
import { CibaRequest } from 'shared/utils/oidc/ciba-request.type';

export interface Props {
  onSubmit: (formData: Partial<Record<string, unknown>>) => void;
  defaultValues?: Partial<CibaRequest>;
}

export const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
});

export const Form = ({ onSubmit, defaultValues }: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<Partial<CibaRequest>>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex>
        <FormControl flex={1} isInvalid={!!errors.email} mr="2.5">
          <Input
            data-cy="request-access-email"
            size="sm"
            {...register('email')}
            name="email"
            placeholder={t('Profile.RequestAccess.form.placeholders.email')}
          />
          <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
        </FormControl>
        <Button data-cy="request-access-submit-button" type="submit" size="sm" variant="submit">
          {t('Profile.RequestAccess.actions.request')}
        </Button>
      </Flex>
    </form>
  );
};
