import { Box, BoxProps } from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GrFormClose } from 'react-icons/gr';

interface Props extends BoxProps {
  isOpen?: boolean;
  isSelected?: boolean;
  onClear?: MouseEventHandler<SVGElement>;
  size?: string | number;
}

const ChakraChevron = ({ isOpen = false, isSelected = false, onClear, size, ...props }: Props) => {
  let icon: JSX.Element;
  if (isSelected) {
    icon = (
      <GrFormClose
        onClick={(event) => {
          event.stopPropagation();
          onClear?.(event);
        }}
        size={size}
      />
    );
  } else if (isOpen) {
    icon = <FiChevronUp size={size} />;
  } else {
    icon = <FiChevronDown size={size} />;
  }
  return (
    <Box d="inline" {...props}>
      {icon}
    </Box>
  );
};

export default ChakraChevron;
