import { Flex } from '@chakra-ui/react';
import React from 'react';

import Star from './Star';
import { RATING_RANGE } from './contants';

interface Props {
  onChange: (rating: number | null) => void;
  value?: number;
}

const RatePicker = ({ onChange, value }: Props) => {
  const select = (index: number) => {
    if (index + 1 === value) {
      index === 0 ? onChange(null) : onChange(index);
    } else {
      onChange(index + 1);
    }
  };

  return (
    <Flex>
      {[...Array(RATING_RANGE).keys()].map((index) => (
        <Star key={`rating-star-${index}`} value={value} index={index} onClick={() => select(index)} />
      ))}
    </Flex>
  );
};

export default RatePicker;
