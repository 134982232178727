import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'languages';

export const resolver = yupResolver(
  yup.object().shape({
    zip: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    county: yup.string().notRequired(),
  }),
);
