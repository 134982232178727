import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { NotificationFragment_MailerNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';

import SquareIcon from 'components/SquareIcon';
import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import { getMailerIntegrationPath } from 'shared/utils/links';

import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';

interface Props {
  notification: NotificationFragment_MailerNotification_;
}

const Mailer = ({ notification }: Props) => {
  const { sentAt } = notification;
  const onClose = NotificationsStore.useStoreState((store) => store.onClose);

  return (
    <Wrapper notification={notification} to={getMailerIntegrationPath()} onClick={() => onClose?.()}>
      <Flex>
        <SquareIcon icon={FiAlertTriangle} isActive iconActiveColor="theme.400" bgActiveColor="theme.100" mr="2.5" size="8" />
        <Box>
          <Text fontSize="sm" color="gray.600" mb="3px" as="span">
            <Trans
              i18nKey={`NotificationsInbox.${notification.type}.innerText`}
              components={{
                text: <Text color="gray.700" />,
              }}
            />
          </Text>
          <Flex align="center">
            <Tooltip label={moment(sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
              <Text fontSize="xs" color="gray.500">
                {sentAt && getCorrectNotificationDateToDisplay(sentAt)}
              </Text>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default Mailer;
