import styled from 'styled-components';

export default styled.button`
  background: ${(props) => props.theme.button.background};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  top: 5px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
`;
