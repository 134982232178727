import { BoxProps, Icon, Square } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface Props extends BoxProps {
  isActive: boolean;
  size?: string;
  iconSize?: string;
  icon: IconType;
  bgColor?: string;
  bgActiveColor?: string;
  iconColor?: string;
  iconActiveColor?: string;
}

const SquareIcon = ({
  isActive,
  size = '10',
  iconSize = '6',
  icon,
  bgColor = 'gray.50',
  bgActiveColor = 'gray.100',
  iconColor = 'gray.500',
  iconActiveColor = 'theme.600',
  ...rest
}: Props) => (
  <Square size={size} bg={isActive ? bgActiveColor : bgColor} borderRadius="4px" {...rest}>
    <Icon as={icon} w={iconSize} h={iconSize} color={isActive ? iconActiveColor : iconColor} />
  </Square>
);

export default SquareIcon;
