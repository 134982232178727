import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { NotificationFragment_ExportableNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';
import { FiAlertTriangle, FiFile, FiFileText } from 'react-icons/fi';

import SquareIcon from 'components/SquareIcon';
import { getExportHistoryPath } from 'shared/utils/links';

import { NotificationsStore } from '../../../../../TenantRouter/RouterSwitcher/TenantApp/store';
import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';

interface Props {
  notification: NotificationFragment_ExportableNotification_;
  variant: 'complete' | 'empty' | 'error';
}

const VARIANT_COMPONENTS = {
  complete: { icon: FiFileText, text: 'NotificationsInbox.EXPORT_COMPLETE.innerText' },
  empty: { icon: FiFile, text: 'NotificationsInbox.EXPORT_EMPTY.innerText' },
  error: { icon: FiAlertTriangle, text: 'NotificationsInbox.EXPORT_ERROR.innerText' },
};

const Export = ({ notification, variant }: Props) => {
  const { sentAt } = notification;
  const onClose = NotificationsStore.useStoreState((store) => store.onClose);
  const { icon, text } = VARIANT_COMPONENTS[variant];

  return (
    <Wrapper notification={notification} to={getExportHistoryPath()} onClick={() => onClose?.()}>
      <Flex>
        <SquareIcon icon={icon} isActive iconActiveColor="theme.400" bgActiveColor="theme.100" mr="2.5" size="8" />
        <Box>
          <Text fontSize="sm" color="gray.600" mb="3px" as="span">
            <Trans
              i18nKey={text}
              components={{
                text: <Text color="gray.700" d="inline" />,
              }}
            />
          </Text>
          <Flex align="center">
            <Tooltip label={moment(sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
              <Text fontSize="xs" color="gray.500">
                {sentAt && getCorrectNotificationDateToDisplay(sentAt)}
              </Text>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default Export;
