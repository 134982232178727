import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  text: string;
}

const EmptyList = ({ text }: Props) => {
  return (
    <Flex h="100%" justify="center" align="center" p="5">
      <Text fontSize="20" fontWeight="medium" color="gray.500">
        {text}
      </Text>
    </Flex>
  );
};

export default EmptyList;
