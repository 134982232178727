import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFieldComponentProps } from 'components/CustomField/structures';

const TextBox = ({ name, label, description, required, register, error, ...formProps }: CustomFieldComponentProps) => {
  const { t } = useTranslation();
  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea size="sm" bg="white" {...register(name)} placeholder={description || t('CustomFields.textBoxPlaceholder')} />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default TextBox;
