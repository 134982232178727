import { ButtonProps } from '@chakra-ui/react';
import React, { forwardRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import SearchDropdown from 'components/SearchDropdown';
import { DROPDOWN_VARIANT } from 'components/SearchDropdown/constants';
import { EntityWithId } from 'shared/types/EntityWithId';
import { PropType } from 'shared/types/PropType';
import { Unpacked } from 'shared/types/Unpacked';

import GET_MANY_CONTRACTS from './GetManyContracts.graphql';
import { GetManyContracts, GetManyContractsVariables } from './__types/GetManyContracts';

interface Props extends Omit<ButtonProps, 'onChange' | 'value' | 'onSelect'> {
  value?: EntityWithId<Partial<Unpacked<PropType<GetManyContracts, 'data'>>>> | null;
  onChange: (contract: Unpacked<PropType<GetManyContracts, 'data'>>) => void;
  placeholder?: string;
  tabIndex?: number;
  portalContainerRef?: RefObject<HTMLElement | null>;
}

const ChakraSelectContractComponent = ({ value, onChange, placeholder, ...props }: Props) => {
  const { t } = useTranslation();

  const onSelect = (contract: Unpacked<PropType<GetManyContracts, 'data'>>) => {
    onChange?.(contract);
  };

  const onCompleted = (data: GetManyContracts) => {
    if (!value && data.data.length === 1) {
      onChange(data.data[0]);
    }
  };

  return (
    <SearchDropdown<GetManyContracts, Omit<GetManyContractsVariables, 'skip' | 'limit'>>
      query={GET_MANY_CONTRACTS}
      label="name"
      triggerLabel="name"
      dropdownVariant={DROPDOWN_VARIANT.SINGLE}
      selected={value ? [value] : []}
      title={value?.name ?? placeholder ?? t('Sale.Checkout.Contract.placeholder')}
      onSelect={onSelect}
      onCompleted={onCompleted}
      {...props}
    />
  );
};

const ChakraSelectContract = forwardRef(ChakraSelectContractComponent);

export default ChakraSelectContract;
