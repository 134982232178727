import React, { FC, Suspense } from 'react';

import ChakraLayout from 'components/ChakraLayout';
import Loading from 'components/Loading';

const Layout: FC = ({ children }) => (
  <ChakraLayout>
    <Suspense fallback={<Loading />}>{children}</Suspense>
  </ChakraLayout>
);

export default Layout;
