import { trimEnd } from 'lodash';

const useSamePage = (pagePath: string, basePath = '/') => {
  const currentPath = location.pathname;
  const dashboardPath = trimEnd(basePath, '/');

  const pageFullPath = basePath + pagePath;

  return pageFullPath === dashboardPath ? currentPath === dashboardPath : currentPath?.includes(pageFullPath);
};

export default useSamePage;
