import { Box, Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import DayPicker, { DateUtils, DayModifiers, Modifier, Modifiers, RangeModifier } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { STANDARD_DATE_FORMAT, STANDARD_DATE_TIME_FORMAT } from 'shared/constants';
import { getDateWithoutTimezone } from 'utils/getDateWithoutTimezone';

import ChakraChevron from '../ChakraChevron';
import Navbar from './Navbar';
import Time from './Time';
import { DATE_PICKER_STYLES, DATE_PICKER_VARIANT, TIME_PICKER_VARIANT } from './constants';
import { DatePickerFormValues, RangedProps, SingleProps, TimeRangeProps } from './structures';
import { VALIDATION_SCHEMA, cloneMomentWithMidday, getFormattedDatePickerValue } from './utils';

// DATEPICKER TAKES AND RETURN DATES WITH TIMEZONE
const DatePicker = (props: RangedProps | SingleProps | TimeRangeProps) => {
  const roundedNow = moment().add(5 - (moment().minute() % 5), 'minutes');
  const {
    value: {
      date,
      dateRange = { from: undefined, to: undefined },
      from: initialTimeFrom = date ? moment(date) : roundedNow,
      to: initialTimeTo = date ? moment(date).add(15, 'minute') : roundedNow.clone().add(15, 'minute'),
    },
    shadeModifiers,
    dateVariant = DATE_PICKER_VARIANT.SINGLE,
    timePickerVariant = TIME_PICKER_VARIANT.NONE,
    yearRange = 5,
    placeholder,
    placement,
    portalContainerRef,
    customTrigger: CustomTrigger,
    modifiersStyles,
    disabledDays,
    ...buttonProps
  } = props;

  const formattedTimeFrom = moment(initialTimeFrom).format('HH:mm');
  const formattedTimeTo = moment(initialTimeTo).format('HH:mm');

  const initialMonth = useMemo(() => {
    if (dateVariant === DATE_PICKER_VARIANT.DOUBLE) {
      if (dateRange.from) {
        return getDateWithoutTimezone(dateRange.from);
      }
    } else if (date) {
      return getDateWithoutTimezone(date);
    }
    return getDateWithoutTimezone(moment());
  }, [dateVariant, dateRange.from, date]);

  const [pickerDate, setPickerDate] = useState(initialMonth);

  const { t } = useTranslation();

  const dayPickerRef = useRef<DayPicker>(null);
  const popoverContent = useRef<HTMLElement>(null);

  const { onOpen, isOpen, onClose } = useDisclosure();

  const locale = moment.locale();

  useOutsideClick({
    ref: popoverContent,
    handler: () => onClose(),
  });

  const modifiers = {
    ...shadeModifiers,
    start: getDateWithoutTimezone(dateRange.from),
    end: getDateWithoutTimezone(dateRange.to),
    singleDay: getDateWithoutTimezone(date),
  } as Partial<Modifiers>;

  const methods = useForm<DatePickerFormValues>({
    resolver: timePickerVariant === TIME_PICKER_VARIANT.NONE ? undefined : yupResolver(VALIDATION_SCHEMA(timePickerVariant)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      from: formattedTimeFrom,
      to: formattedTimeTo,
      dateFrom: dateVariant === DATE_PICKER_VARIANT.DOUBLE ? dateRange.from?.toDate() : date?.toDate(),
      dateTo: dateRange.to?.toDate(),
    },
  });

  const { register, getValues, reset, trigger, setValue } = methods;

  register('dateFrom');
  register('dateTo');

  const formattedDatePickerValue = getFormattedDatePickerValue(formattedTimeFrom, formattedTimeTo, dateVariant, timePickerVariant, date?.toDate(), {
    from: dateRange.from,
    to: dateRange.to,
  });

  const handleSingleDaySelect = (day: Date, { selected }: DayModifiers) => {
    const [from, to] = getValues(['from', 'to']);

    if (props.dateVariant === DATE_PICKER_VARIANT.SINGLE && props.timePickerVariant === TIME_PICKER_VARIANT.DOUBLE) {
      const standardFormattedDay = moment(day).format(STANDARD_DATE_FORMAT);
      props.onChange(
        selected
          ? undefined
          : [moment(standardFormattedDay + ' ' + from, STANDARD_DATE_TIME_FORMAT), moment(standardFormattedDay + ' ' + to, STANDARD_DATE_TIME_FORMAT)],
      );
    } else if (props.dateVariant === DATE_PICKER_VARIANT.SINGLE && props.timePickerVariant === TIME_PICKER_VARIANT.SINGLE) {
      const standardFormattedDay = moment(day).format(STANDARD_DATE_FORMAT);
      props.onChange(selected ? undefined : moment(standardFormattedDay + ' ' + from, STANDARD_DATE_TIME_FORMAT));
    } else if (props.dateVariant === DATE_PICKER_VARIANT.SINGLE) {
      props.onChange(selected ? undefined : cloneMomentWithMidday(moment(day)));
    }
  };

  const handleRangeSelect = (day: Date) => {
    const { from: rangeFrom, to: rangeTo } = DateUtils.addDayToRange(day, {
      from: getDateWithoutTimezone(cloneMomentWithMidday(dateRange.from)),
      to: getDateWithoutTimezone(cloneMomentWithMidday(dateRange.to)),
    } as RangeModifier);
    const [from, to] = getValues(['from', 'to']);

    if (props.dateVariant === DATE_PICKER_VARIANT.DOUBLE && props.timePickerVariant === TIME_PICKER_VARIANT.DOUBLE) {
      const standardFormattedRangeFrom = moment(rangeFrom).format(STANDARD_DATE_FORMAT);

      props.onChange(
        rangeFrom
          ? [
              moment(standardFormattedRangeFrom + ' ' + from, STANDARD_DATE_TIME_FORMAT),
              rangeTo
                ? moment(moment(rangeTo).format(STANDARD_DATE_FORMAT) + ' ' + to, STANDARD_DATE_TIME_FORMAT)
                : moment(standardFormattedRangeFrom + ' ' + to, STANDARD_DATE_TIME_FORMAT),
            ]
          : undefined,
      );
    } else if (props.dateVariant === DATE_PICKER_VARIANT.DOUBLE) {
      if (rangeFrom) {
        const momentFrom = moment(rangeFrom).startOf('day');
        if (rangeTo) {
          const momentTo = moment(rangeTo.setHours(0)).endOf('day');
          props.onChange([momentFrom, momentTo]);
        } else {
          const momentFromTillEndOfDay = moment(rangeFrom).endOf('day');
          props.onChange([momentFrom, momentFromTillEndOfDay]);
        }
      } else {
        props.onChange(undefined);
      }
    }
  };

  const handleTimeChange = () => {
    const [from, to] = getValues(['from', 'to']);

    if (props.dateVariant === DATE_PICKER_VARIANT.DOUBLE && props.timePickerVariant === TIME_PICKER_VARIANT.DOUBLE) {
      props.onChange(
        dateRange.from
          ? [
              moment(moment(dateRange.from).format(STANDARD_DATE_FORMAT) + ' ' + from, STANDARD_DATE_TIME_FORMAT),
              dateRange.to
                ? moment(moment(dateRange.to).format(STANDARD_DATE_FORMAT) + ' ' + to, STANDARD_DATE_TIME_FORMAT)
                : moment(moment(dateRange.from).format(STANDARD_DATE_FORMAT) + ' ' + to, STANDARD_DATE_TIME_FORMAT),
            ]
          : undefined,
      );
    } else if (props.dateVariant === DATE_PICKER_VARIANT.SINGLE && props.timePickerVariant === TIME_PICKER_VARIANT.DOUBLE) {
      const standardFormattedDate = moment(date).format(STANDARD_DATE_FORMAT);
      props.onChange(
        date
          ? [moment(standardFormattedDate + ' ' + from, STANDARD_DATE_TIME_FORMAT), moment(standardFormattedDate + ' ' + to, STANDARD_DATE_TIME_FORMAT)]
          : undefined,
      );
    } else if (props.dateVariant === DATE_PICKER_VARIANT.SINGLE && props.timePickerVariant === TIME_PICKER_VARIANT.SINGLE) {
      const standardFormattedDate = moment(date).format(STANDARD_DATE_FORMAT);
      props.onChange(moment(standardFormattedDate + ' ' + from, STANDARD_DATE_TIME_FORMAT));
    }
  };

  const onNavbarMonthChange = (value: Date) => {
    setPickerDate(value);
  };

  useEffect(() => {
    setValue('dateFrom', dateVariant === DATE_PICKER_VARIANT.DOUBLE ? dateRange.from?.toDate() : date?.toDate());
    setValue('dateTo', dateRange.to?.toDate());
    trigger('to');
  }, [dateRange.from, dateRange.to, date, dateVariant]);

  useEffect(() => {
    reset({ from: formattedTimeFrom, to: formattedTimeTo });
  }, [formattedTimeFrom, formattedTimeTo]);

  useEffect(() => {
    setPickerDate(initialMonth);
  }, [initialMonth]);

  return (
    <Popover isLazy placement={placement ?? 'bottom-start'} isOpen={isOpen} initialFocusRef={dayPickerRef}>
      <PopoverTrigger>
        {CustomTrigger ? (
          <Button onClick={() => onOpen()} {...omit(buttonProps, ['onChange'])} fontWeight="normal">
            {CustomTrigger}
          </Button>
        ) : (
          <Button
            onClick={onOpen}
            justifyContent="space-between"
            variant="outline"
            rightIcon={<ChakraChevron isOpen={isOpen} />}
            fontWeight="normal"
            {...omit(buttonProps, ['onChange'])}
          >
            {formattedDatePickerValue || placeholder || t(dateVariant === DATE_PICKER_VARIANT.DOUBLE ? 'common.pickDateRange' : 'common.pickDate')}
          </Button>
        )}
      </PopoverTrigger>
      <Portal containerRef={portalContainerRef}>
        <PopoverContent width="max-content" borderRadius="5px" ref={popoverContent} onSubmit={(event) => event.stopPropagation()}>
          <PopoverBody p="0">
            <Box sx={DATE_PICKER_STYLES}>
              <DayPicker
                disabledDays={disabledDays}
                ref={dayPickerRef}
                locale={locale}
                localeUtils={MomentLocaleUtils}
                firstDayOfWeek={1}
                month={pickerDate}
                showOutsideDays
                showWeekNumbers
                onDayClick={dateVariant === DATE_PICKER_VARIANT.DOUBLE ? handleRangeSelect : handleSingleDaySelect}
                selectedDays={
                  dateVariant === DATE_PICKER_VARIANT.DOUBLE
                    ? ([
                        getDateWithoutTimezone(cloneMomentWithMidday(dateRange.from)),
                        {
                          from: getDateWithoutTimezone(cloneMomentWithMidday(dateRange.from)),
                          to: getDateWithoutTimezone(cloneMomentWithMidday(dateRange.to)),
                        },
                      ] as Modifier[])
                    : getDateWithoutTimezone(date)
                }
                modifiers={modifiers}
                captionElement={({ date: dateObject }) => <Navbar date={dateObject} onChange={onNavbarMonthChange} yearRange={yearRange} />}
                modifiersStyles={modifiersStyles}
              />
              <FormProvider {...methods}>
                <form>
                  {timePickerVariant !== TIME_PICKER_VARIANT.NONE && (
                    <Box px="2.5" mb="4">
                      <Time
                        variant={dateVariant === DATE_PICKER_VARIANT.DOUBLE ? TIME_PICKER_VARIANT.DOUBLE : timePickerVariant}
                        onChange={() => handleTimeChange()}
                      />
                    </Box>
                  )}
                </form>
              </FormProvider>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default memo(DatePicker);
