import { useConfig } from 'contexts/config';
import React from 'react';
import { Helmet } from 'react-helmet';

import { TenantStore } from '../../store';

interface Props {
  title?: string;
}

const TenantHelmet = ({ title }: Props) => {
  const user = TenantStore.useStoreState((store) => store.user);
  const config = useConfig();

  return (
    <Helmet>
      <title>{`${config.oidc.client?.name} | ${title ? title + ' |' : ''} ${user?.organization?.name}`}</title>
    </Helmet>
  );
};

export default TenantHelmet;
