import { Box, Button, Flex } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FloatingUserActions from 'components/FloatingUserActions';
import { calcFullHeight } from 'utils/calcFullHeight';

const NotFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Flex h={calcFullHeight('100vh')} flexDir="column" flex={1} justifyContent="center" alignItems="center">
      <Box fontSize="18px" mb="2.5">
        {t('NotFound.title')}
      </Box>
      <Box>
        <Link to="/">
          <Button colorScheme={theme.selectedTheme.id}>{t('NotFound.goBack')}</Button>
        </Link>
      </Box>
      <FloatingUserActions />
    </Flex>
  );
};

export default NotFound;
