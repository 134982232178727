import { Location, matchRoutes } from 'react-router-dom';

import Page from 'shared/types/Page';

export const getCurrentRoute = (basepath: string, pages: Page[], location: Location) => {
  const routes = pages.map((page) => ({ ...page, path: basepath + '/' + page.path }));
  const match = matchRoutes(routes, location);

  return match?.map((routeMatch) => ({
    ...routeMatch,
    route: {
      ...routeMatch.route,
      path: routeMatch.route.path?.includes(':tabName') ? routeMatch.route.path : routeMatch.route.path + '/:tabName',
    } as Page,
  }))[0];
};

export const cutPath = (path = '') => {
  const elements = path.split('/');
  const newPath: string[] = [];
  for (const element of elements) {
    newPath.push(element);
    if (element === ':tabName') {
      break;
    }
  }
  return newPath.join('/');
};

export const fillPath = (path: string, params: Record<string, string>) => {
  return path.replace(/:([a-zA-Z]*)/gm, (_, match) => params[match]);
};
