import React from 'react';
import styled from 'styled-components';

import checked from 'images/forms/checked.svg';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #acabad;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #4b74c7;
    border-color: #4b74c7;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
    background: url(${checked}) no-repeat;
    background-size: 10px;
    background-position: center center;
  }

  ${(props) => `
        margin-right: ${props.margin && props.margin.right}px;
        margin-left: ${props.margin && props.margin.left}px;
        margin-top: ${props.margin && props.margin.top}px;
        margin-bottom: ${props.margin && props.margin.bottom}px;
    `}
`;

export default ({ checked: checkedValue = false, onClick, name, ...props }) => {
  return (
    <Wrapper {...props} onClick={() => onClick({ name, checked: checkedValue })}>
      <input name={name} type="checkbox" checked={checkedValue} onChange={() => {}} />
      <span className="checkmark"></span>
    </Wrapper>
  );
};
