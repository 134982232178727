import { motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Select = styled(motion.div)`
  background: #fff;
  padding: 0px;
  width: ${(props) => (props.style && props.style.width ? props.style.width : 200)}px;
  border-radius: 5px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
  max-height: ${(props) => (props.style && props.style.height ? props.style.height : 200)}px;
  overflow: hidden;
  overflow-y: scroll;
  li {
    padding: 10px 15px;
    list-style: none;
    margin: 0;
    cursor: pointer;
    &:hover {
      background: #f9fbff;
    }
  }
  .selected {
    background: #f4f4f6;
  }
`;

export default React.forwardRef(({ close, onSelect, selected, renderOptions: OptionsComponent, options, settings, ...props }, ref) => {
  const selectedOption = selected && document.getElementById(settings && settings.optionsKey ? selected[settings.optionsKey] : selected);

  useEffect(() => {
    if (selectedOption) {
      const parent = selectedOption.parentElement;
      const child = selectedOption;
      const isOutside = child.offsetTop > parent.clientHeight;

      if (isOutside) {
        parent.scrollTop = child.getBoundingClientRect().top + parent.scrollTop - parent.getBoundingClientRect().top;
      }
    }
  }, [selectedOption, ref]);

  if (OptionsComponent)
    return (
      <Select {...settings} ref={ref}>
        <OptionsComponent close={close} />
      </Select>
    );
  return (
    <Select {...settings} ref={ref}>
      <TopAction {...settings} />
      {!options && <span>No options</span>}
      {options &&
        options.map((option, key) => {
          return (
            <li
              className={selected === option ? 'selected' : 'unselected'}
              key={key}
              id={settings && settings.optionsKey ? option[settings.optionsKey] : option}
              onClick={() => {
                onSelect(option);
                close();
              }}
            >
              <Option {...settings} data={option} />
            </li>
          );
        })}
    </Select>
  );
});

const TopAction = ({ topAction }) => {
  if (topAction) return <li onClick={topAction.actionFunction}>{topAction.value}</li>;
  return null;
};

const Option = ({ data, optionsKey }) => {
  if (optionsKey) return <span>{data[optionsKey]} </span>;
  return data;
};
