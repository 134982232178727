import { Button, ButtonGroup, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useConfig } from 'contexts/config';
import { NotificationFragment_BackChannelAuthRequestNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';

import SquareIcon from 'components/SquareIcon';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import useSimpleToast from 'hooks/useSimpleToast';
import getAuthorizationToken from 'shared/utils/getAuthorizationToken';
import { execute } from 'shared/utils/promise';

import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';

export interface Props {
  notification: NotificationFragment_BackChannelAuthRequestNotification_;
}

export const BackChannelAuth = ({ notification }: Props) => {
  const { sentAt, requester, authReqId, isRead } = notification;
  const { t } = useTranslation();
  const { error, success } = useSimpleToast();
  const [isPersisting, setIsPersisting] = useState(false);
  const organization = TenantStore.useStoreState((state) => state.organization);
  const config = useConfig();

  const handleAccept = async () => {
    if (isPersisting) {
      return;
    }
    setIsPersisting(true);
    const result = await execute<void>(
      fetch(config.oidc.client?.uris.backChannelAuthRequestAccept as string, {
        method: 'POST',
        body: JSON.stringify({
          authReqId,
        }),
        credentials: 'include',
        headers: {
          'Authorization': getAuthorizationToken() as string,
          'Content-Type': 'application/json',
          'organization': organization?._id as string,
        },
      }),
    );

    setIsPersisting(false);

    if (result[1]) {
      error(t('common.error'), { description: result[1].message });
    } else {
      success(t('common.success'), { description: t('NotificationsInbox.backChannelAuth.messages.success.requestAccepted') });
    }
  };

  return (
    <Wrapper notification={notification}>
      <Flex>
        <SquareIcon icon={FiUnlock} isActive iconActiveColor="theme.400" bgActiveColor="theme.100" mr="2.5" size="8" />
        <Flex alignItems="center" justifyContent="space-between" flexFlow="row wrap" gap="1">
          <Text fontSize="sm" color="gray.600" mb="3px" as="span">
            <Trans
              i18nKey={`NotificationsInbox.${notification.type}.innerText`}
              values={{ userName: requester?.name }}
              components={{
                text: <Text color="gray.700" />,
              }}
            />
          </Text>
          {!isRead && (
            <ButtonGroup gap="1">
              <Button isLoading={isPersisting} size="sm" leftIcon={<FaCheckCircle />} onClick={handleAccept}>
                <Trans
                  i18nKey={'common.accept'}
                  components={{
                    text: <Text color="gray.700" />,
                  }}
                />
              </Button>
            </ButtonGroup>
          )}
          <Flex align="center">
            <Tooltip label={moment(sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
              <Text fontSize="xs" color="gray.500">
                {sentAt && getCorrectNotificationDateToDisplay(sentAt)}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
