import { createStandaloneToast, useToast, UseToastOptions } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { TOAST_METHODS } from './constants';
import { SimpleToastMethods, UseSimpleToast } from './structures';

const useSimpleToast = (defaults?: UseToastOptions, standalone = false): UseSimpleToast => {
  const toast = standalone ? createStandaloneToast() : useToast();

  const methods = TOAST_METHODS.reduce<SimpleToastMethods>(
    (current, method) => ({
      ...current,
      [method]: (title?: ReactNode, options?: UseToastOptions | undefined) => {
        return toast({
          title,
          status: method,
          isClosable: true,
          ...defaults,
          ...options,
        });
      },
    }),
    {} as SimpleToastMethods,
  );

  return {
    toast,
    ...methods,
  };
};

export default useSimpleToast;
