import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ForwardRefRenderFunction, forwardRef } from 'react';

interface Props extends FlexProps {
  isDragging?: boolean;
  isExpanded?: boolean;
  backgroundColor?: string;
  borderColor?: string;
}

const ChakraDragBox: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, isDragging, backgroundColor = 'white', borderColor, ...props },
  ref,
) => {
  return (
    <Flex
      borderWidth={2}
      borderColor={isDragging ? 'theme.600' : borderColor || backgroundColor}
      borderRadius="lg"
      userSelect="none"
      mt="3"
      p="4"
      _hover={{ bg: backgroundColor, borderColor: borderColor || isDragging ? 'theme.600' : backgroundColor }}
      ref={ref}
      bg={backgroundColor}
      justify="space-between"
      align="center"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default forwardRef<HTMLDivElement, Props>(ChakraDragBox);
