import { Tag, TagLabel } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import React from 'react';

interface Props {
  selectedNumber: number | undefined;
  itemsNumber: number | undefined;
  limit: number;
}

const RemainingTags = ({ selectedNumber, itemsNumber, limit }: Props) => {
  const theme = useTheme();
  if (!selectedNumber || !itemsNumber) return null;
  return selectedNumber > limit ? (
    <Tag mb="5px" colorScheme={theme.selectedTheme.id} rounded="full" size="sm" mr="5px">
      <TagLabel>+{selectedNumber - itemsNumber}</TagLabel>
    </Tag>
  ) : null;
};

export default RemainingTags;
