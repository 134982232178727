import { useQuery } from '@apollo/client';
import { Skeleton, Select, SelectProps } from '@chakra-ui/react';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import GET_ROLES_FOR_SELECT from './GetRolesForSelect.graphql';
import { GetRolesForSelect } from './__types/GetRolesForSelect';

const ChakraSelectRole: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (props, ref) => {
  const { data, loading } = useQuery<GetRolesForSelect>(GET_ROLES_FOR_SELECT);

  return (
    <Skeleton isLoaded={!loading}>
      <Select {...props} ref={ref}>
        {data?.getManyRoles.map((role) => (
          <option key={role._id} value={role._id}>
            {role.name}
          </option>
        ))}
      </Select>
    </Skeleton>
  );
};

export default forwardRef<HTMLSelectElement, SelectProps>(ChakraSelectRole);
