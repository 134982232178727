import { OIDC, OIDCStorage } from './oidc';

const getAuthorizationToken = () => {
  try {
    const stringifiedToken = OIDCStorage.getInstance().getItem(OIDC.getKey());

    if (!stringifiedToken) return;

    const access_token = JSON.parse(stringifiedToken).access_token as string;

    return `Bearer ${access_token}`;
  } catch {
    return;
  }
};

export default getAuthorizationToken;
