import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import blackArrow from 'images/forms/arrow-down-navigate.svg';
import whiteArrow from 'images/forms/arrow-down-white.svg';
import close from 'images/forms/close.svg';
import refresh from 'images/forms/refresh.svg';

const Button = styled.button`
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  img {
    width: 22px;
  }
  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: disabled;
    `}
  ${(props) =>
    !props.type &&
    `
        background: ${props.theme.button.background};
        color: ${props.theme.button.color};
    `}
    ${(props) =>
    props.type === 'light' &&
    `
        background: ${props.theme.greyColor};
        color: ${props.theme.color};
    `}
    ${(props) =>
    props.type === 'select' &&
    `
        border-radius: 5px;
        padding-right: 35px;
        background: ${props.open ? '#354561' : props.theme.greyColor} url(${props.open ? whiteArrow : blackArrow}) no-repeat right 15px center;
        background-size: 12px !important;
        color: ${props.open ? '#FFF' : '#747880'};
    `}
    ${(props) =>
    props.type === 'selectDisabled' &&
    `
            border-radius: 5px;
            padding-right: 35px;
            background-size: 12px !important;
            background-color: ${props.open ? '#354561' : props.theme.greyColor};
            color: ${props.open ? '#FFF' : '#747880'};
        `}
    ${(props) =>
    props.type === 'remove' &&
    `
        border-radius: 5px;
        padding-right: 35px;
        background: ${props.theme.greyColor} url(${close}) no-repeat right 15px center !important;
        background-size: 12px !important;
        color: ${props.theme.color};
        text-transform: normal;
    `}
    ${(props) =>
    props.type === 'warning' &&
    `
        background: #d53838;
        color: #FFF;
    `}
    ${(props) =>
    props.type === 'white' &&
    `
        background: #FFF;
    `}
    ${(props) =>
    props.type === 'border' &&
    `
        background: transparent;
        border: 1px solid ${props.theme.button.background};
        color: ${props.theme.button.background};
    `}
    ${(props) =>
    props.type === 'refresh' &&
    `
        border-radius: 5px;
        background: ${props.theme.greyColor} url(${refresh}) no-repeat center center !important;
        background-size: 20px !important;
        color: ${props.theme.color};
        padding: 10px 25px;
        height: 35px;
    `}
    ${(props) =>
    props.size === 'small-round' &&
    `
        padding: 5px 15px;
        border-radius: 35px;
        font-size: 13px;
    `}
    ${(props) =>
    props.size === 'small' &&
    `
        padding: 8px 16px;
        font-size: 13px;
    `}
    ${(props) => `
        margin-right: ${props.margin && props.margin.right}px;
        margin-left: ${props.margin && props.margin.left}px;
        margin-top: ${props.margin && props.margin.top}px;
        margin-bottom: ${props.margin && props.margin.bottom}px;
    `}
`;

export default React.forwardRef(({ goback, text, onClick, path, children, loading, ...props }, ref) => {
  let action = onClick;
  if (props.type === 'refresh') {
    action = () => {
      onClick();
    };
  }
  if (goback)
    return (
      <Button onClick={() => window.history.back()} ref={ref} {...props}>
        {children}
      </Button>
    );
  if (path)
    return (
      <Link to={path}>
        <Button ref={ref} {...props}>
          {children}
        </Button>
      </Link>
    );
  return (
    <Button ref={ref} onClick={action} {...props}>
      {children} {loading && <span>Loading</span>}
    </Button>
  );
});
