import * as Sentry from '@sentry/react';
import 'core-js/stable';
import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import 'regenerator-runtime/runtime';

import { disableInputScroll } from 'utils/disableInputScroll';
import { viewportHeightCustomProperty } from 'utils/viewportHeightCustomProperty';

import App from './app';
import './languages';
import './static/style.css';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/aiflow\.no/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
  environment: import.meta.env.VITE_ENVIRONMENT as string,
});

disableInputScroll();
viewportHeightCustomProperty();
render(<App />, document.getElementById('app'));
