import { Box } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import InViewTrigger from 'components/InViewTrigger';
import Loading from 'components/Loading';
import { NOTIFICATION_QUERY_LIMIT } from 'components/TenantRouter/RouterSwitcher/TenantApp/constants';
import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import useInitLayoutEffect from 'hooks/useInitLayoutEffect';

import EmptyList from '../EmptyList';
import Item from '../Item';

const History = () => {
  const { t } = useTranslation();
  const readNotifications = NotificationsStore.useStoreState((store) => store.readNotifications);
  const { getReadNotifications } = NotificationsStore.useStoreActions((actions) => actions);
  const hasMoreRead = NotificationsStore.useStoreState((store) => store.hasMoreRead);
  const isLoadingRead = NotificationsStore.useStoreState((store) => store.isLoadingRead);
  const scrollRef = useRef<HTMLDivElement>(null);

  useInitLayoutEffect(() => {
    if (readNotifications === null) {
      getReadNotifications();
    }
  });

  return (
    <Box ref={scrollRef} overflow="auto" h="100%">
      {isLoadingRead && <Loading spinnerColor="spinnerColor" height="100%" />}
      {readNotifications?.map((notification) => (
        <Item key={notification._id} notification={notification} />
      ))}
      {readNotifications && !readNotifications.length && <EmptyList text={t('NotificationsInbox.noHistory')} />}
      {scrollRef.current !== null && (
        <InViewTrigger
          key={scrollRef.current.tagName}
          callback={() => getReadNotifications()}
          isWatching={(readNotifications?.length || 0) >= NOTIFICATION_QUERY_LIMIT}
          options={{
            root: scrollRef.current,
            rootMargin: '0px 0px 350px 0px',
          }}
        >
          <Loading height="32px" hidden={!hasMoreRead} mt="5" />
        </InViewTrigger>
      )}
    </Box>
  );
};

export default History;
