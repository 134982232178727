import { Button, ButtonProps, useColorModeValue } from '@chakra-ui/react';
import React, { ForwardRefRenderFunction, forwardRef } from 'react';

export const ChakraMainButtonBase: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = ({ children, ...props }, ref) => {
  const bg = useColorModeValue('theme.500', 'theme.800');
  const bg_hover = useColorModeValue('theme.600', 'theme.700');
  return (
    <Button ref={ref} bg={bg} _hover={{ bg: bg_hover }} color="white" {...props}>
      {children}
    </Button>
  );
};

export const ChakraMainButton = forwardRef(ChakraMainButtonBase);

export default forwardRef(ChakraMainButtonBase);
