import { Select, SelectProps } from '@chakra-ui/react';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import getTimeSlots from 'utils/getTimeSlots';

const ChakraSelectTime: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (props, ref) => {
  const times = getTimeSlots({ start: 0, end: 24 });

  return (
    <Select ref={ref} {...props}>
      {times.map((time) => (
        <option key={time} value={time}>
          {time}
        </option>
      ))}
    </Select>
  );
};

export default forwardRef<HTMLSelectElement, SelectProps>(ChakraSelectTime);
