import { Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import React, { ForwardRefRenderFunction, forwardRef, memo } from 'react';

import { ChakraProbabilityProps } from './interface';

const ChakraProbabilityComponent: ForwardRefRenderFunction<HTMLDivElement, ChakraProbabilityProps> = ({ value, onChange, ...others }, ref) => {
  const [internalValue, setInternalValue] = React.useState(value);

  React.useEffect(() => {
    if (value !== internalValue) setInternalValue(value);
  }, [value]);

  return (
    <Slider
      color="theme.500"
      min={1}
      max={100}
      ref={ref}
      onChange={setInternalValue}
      onChangeEnd={onChange}
      value={internalValue}
      defaultValue={value}
      {...others}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxSize={8}>
        <Box color="gray.600" fontSize="12px">
          {internalValue}%
        </Box>
      </SliderThumb>
    </Slider>
  );
};

export const ChakraProbability = memo(forwardRef(ChakraProbabilityComponent));

export default memo(forwardRef(ChakraProbabilityComponent));
