import moment from 'moment-timezone';

import i18n from 'languages';

export const getCorrectNotificationDateToDisplay = (date: GraphQL_DateTime) => {
  const momentDate = moment(date);
  if (momentDate.isSame(moment(), 'day')) {
    return momentDate.format('LT');
  } else if (momentDate.isSame(moment().subtract(1, 'day'), 'day')) {
    return i18n.t('common.yesterday');
  }
  return momentDate.format('L');
};

export const getFormattedTimeDifference = (dateFrom: GraphQL_DateTime | null, dateTo: GraphQL_DateTime | null) => {
  if (!dateTo || !dateFrom) return null;

  const diff = moment(dateTo).diff(moment(dateFrom), 'days');
  return diff < 10 ? moment(dateTo).from(moment(dateFrom)) : moment(dateTo).format('L');
};
