import { EntityName } from '__types/graphql';
import SelectCustomer from 'components/SearchDropdown/Instances/SelectCustomer';
import SelectPerson from 'components/SearchDropdown/Instances/SelectPerson';
import SelectUser from 'components/SearchDropdown/Instances/SelectUser';

export const SELECT_COMPONENTS = {
  [EntityName.CUSTOMER]: SelectCustomer,
  [EntityName.PERSON]: SelectPerson,
  [EntityName.USER]: SelectUser,
};
