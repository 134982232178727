import { execute } from './promise';

function wait(ms: number) {
  let timeoutId: unknown;

  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId as number);
    }
    return new Promise((res) => {
      timeoutId = setTimeout(res, ms);
    });
  };
}

export const poll = async <T>(promise: () => Promise<Response>, condition: (result: [T, Error]) => boolean, ms = 10000) => {
  const delay = wait(ms);
  let result;
  do {
    await delay();
    result = (await execute(promise() as Promise<Response>)) as [T, Error];
  } while (condition(result as [T, Error]));
  return result;
};
