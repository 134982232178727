import React from 'react';
import styled from 'styled-components';

const Input = styled.div`
  display: flex;
  border: 1px solid #eee;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  background: white;
  align-items: center;
  ${(props) =>
    props.error &&
    `
      border-color: red;
    `}
  ${(props) => `
        margin-right: ${props.margin && props.margin.right}px;
        margin-left: ${props.margin && props.margin.left}px;
        margin-top: ${props.margin && props.margin.top}px;
        margin-bottom: ${props.margin && props.margin.bottom}px;
    `}
    input {
    border: none;
    background: transparent;
    padding: 10px 20px;
    width: calc(100% - 20px);
    transition: 0.2s all;
    z-index: 22;
    ${(props) =>
      props.size === 'large' &&
      `
            padding: 20px 30px;
            border: 2px solid #eee;
            font-size: 16px;
        `}
    &::placeholder {
      color: #8390a0;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: transpareent;
      box-shadow: none;
      transition: background-color 5000s ease-in-out 0s;
    }
    /*

        &:focus {
            border-color: ${(props) => props.theme.mainColor};
        }
        */
  }
  .symbol {
    display: inline-block;
    text-align: left;
    z-index: 1;
    margin-right: 10px;
    color: #a8acb2;
  }
`;

export default React.forwardRef(({ id, autoComplete, margin, type, error, secret, value, onEnter, onChange, onKeyUp, onPaste, style, ...props }, ref) => {
  const onKeyDown = (e) => {
    // Listerner for enter press
    if (onEnter && e.key === 'Enter') {
      e.preventDefault();
      onEnter();
    }
  };
  return (
    <Input margin={margin} style={style} error={error}>
      <input
        ref={ref}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        id={id}
        type={secret && 'password'}
        value={value}
        onPaste={onPaste}
        onChange={onChange}
        autoComplete={autoComplete ? autoComplete : 'off'}
        {...props}
      />
      <Symbol type={type} />
    </Input>
  );
});

const Symbol = ({ type }) => {
  if (type === 'percentage') return <div className="symbol">%</div>;
  if (type === 'currency') return <div className="symbol">NOK</div>;
  if (type === 'items') return <div className="symbol">items</div>;
  return null;
};
