import { action, computed, createContextStore, thunk } from 'easy-peasy';
import { isEmpty } from 'lodash';
import NOTIFICATION_ADDED from 'schemas/subscriptions/NOTIFICATION_ADDED.graphql';
import { NotificationAdded } from 'schemas/subscriptions/__types/graphql';

import client from 'client';
import { addSetters } from 'shared/utils/addSetters';

import GET_NOTIFICATIONS from './GetNotifications.graphql';
import MARK_NOTIFICATION from './MarkNotification.graphql';
import { GetNotifications, GetNotificationsVariables } from './__types/GetNotifications';
import { MarkNotification, MarkNotificationVariables } from './__types/MarkNotification';
import { NOTIFICATION_QUERY_LIMIT } from './constants';
import { NotificationsStoreModel } from './structures';

export const NotificationsStore = createContextStore<NotificationsStoreModel>(
  {
    ...addSetters({
      numberOfUnreadNotifications: 0,
      unreadNotifications: null,
      readNotifications: null,
      hasMoreUnread: true,
      hasMoreRead: true,
      isLoadingUnread: true,
      isLoadingRead: true,
      onClose: null,
      disabled: false,
      error: null,
      newNotifications: [],
    }),
    isMarkAllDisabled: computed((state) => !state.unreadNotifications?.length),
    getNumberOfUndreadNotifications: thunk(async (actions) => {
      const { data } = await client.query<GetNotifications, GetNotificationsVariables>({
        fetchPolicy: 'network-only',
        query: GET_NOTIFICATIONS,
        variables: { skipData: true },
      });
      if (data) {
        actions.setNumberOfUnreadNotifications(data.countNotifications);
      }
    }),
    markAllAsRead: thunk(async (actions) => {
      try {
        const { data } = await client.mutate<MarkNotification, MarkNotificationVariables>({
          mutation: MARK_NOTIFICATION,
          variables: {
            markAll: true,
            markAs: true,
          },
        });

        actions.setNumberOfUnreadNotifications(0);
        actions.setUnreadNotifications([]);

        if (data) {
          actions.reloadRead({ markAll: true });
        }
        return true;
      } catch (error) {
        return false;
      }
    }),
    getUnreadNotifications: thunk(async (actions, options, { getStoreState }) => {
      const { hasMoreUnread, unreadNotifications } = getStoreState();
      if (isEmpty(unreadNotifications)) actions.setIsLoadingUnread(true);
      if (hasMoreUnread || options?.reload) {
        try {
          const skip = options?.reload ? 0 : unreadNotifications?.length ?? 0;
          const limit = options?.reload ? (unreadNotifications?.length ?? 0) + Number(!hasMoreUnread) : NOTIFICATION_QUERY_LIMIT;
          const { data } = await client.query<GetNotifications, GetNotificationsVariables>({
            query: GET_NOTIFICATIONS,
            variables: {
              skip,
              limit,
              filter: { isRead: false },
            },
            fetchPolicy: 'network-only',
          });

          if (data) {
            if (options?.reload) {
              actions.setUnreadNotifications(data.notifications);
              actions.setNumberOfUnreadNotifications(data.countNotifications);
            } else {
              actions.setUnreadNotifications([...(unreadNotifications ?? []), ...(data.notifications || [])]);
            }
            actions.setHasMoreUnread((data.notifications || []).length >= NOTIFICATION_QUERY_LIMIT);
          }

          actions.setIsLoadingUnread(false);
        } catch (error) {
          console.error(error);
          actions.setIsLoadingUnread(false);
        }
      }
    }),
    getReadNotifications: thunk(async (actions, options, { getStoreState }) => {
      const { hasMoreRead, readNotifications } = getStoreState();
      if (isEmpty(readNotifications)) actions.setIsLoadingRead(true);
      if (hasMoreRead || options?.reload) {
        try {
          let limit = NOTIFICATION_QUERY_LIMIT;
          if (readNotifications && options?.reload && !(options?.markAll && (readNotifications?.length ?? 0) <= NOTIFICATION_QUERY_LIMIT)) {
            limit = (readNotifications?.length ?? 0) + Number(!hasMoreRead);
          }
          const skip = options?.reload ? 0 : readNotifications?.length ?? 0;
          const { data } = await client.query<GetNotifications, GetNotificationsVariables>({
            query: GET_NOTIFICATIONS,
            variables: {
              skip,
              limit,
              filter: { isRead: true },
            },
            fetchPolicy: 'network-only',
          });

          if (data) {
            if (options?.reload) {
              actions.setReadNotifications(data.notifications);
            } else {
              actions.setReadNotifications([...(readNotifications ?? []), ...(data.notifications || [])]);
            }
            actions.setHasMoreRead((data.notifications || []).length >= NOTIFICATION_QUERY_LIMIT);
          }

          actions.setIsLoadingRead(false);
        } catch (error) {
          console.error(error);
          actions.setIsLoadingRead(false);
        }
      }
    }),
    reloadUnread: thunk(async (actions) => {
      await actions.getUnreadNotifications({ reload: true });
    }),
    reloadRead: thunk(async (actions, options) => {
      const { markAll } = options ?? {};
      await actions.getReadNotifications({ reload: true, markAll });
    }),
    markNotification: thunk(async (actions, payload, { getStoreState }) => {
      try {
        const { numberOfUnreadNotifications, unreadNotifications, readNotifications } = getStoreState();

        actions.setDisabled(true);

        if (payload.value) {
          actions.setNumberOfUnreadNotifications(numberOfUnreadNotifications - 1);
          actions.setUnreadNotifications((unreadNotifications ?? []).filter((notification) => notification._id !== payload.id));
        } else {
          actions.setNumberOfUnreadNotifications(numberOfUnreadNotifications + 1);
          actions.setReadNotifications((readNotifications ?? []).filter((notification) => notification._id !== payload.id));
        }

        const { data } = await client.mutate<MarkNotification, MarkNotificationVariables>({
          mutation: MARK_NOTIFICATION,
          variables: {
            markAs: payload.value,
            markSelected: payload.id,
          },
        });

        if (data) {
          await actions.reloadUnread();
          await actions.reloadRead();
        }
        return true;
      } catch (error) {
        console.error(error);
        return false;
      } finally {
        actions.setDisabled(false);
      }
    }),
    initSubscription: thunk((actions, _, { getStoreState }) => {
      const unsubscribe = client.subscribe<NotificationAdded>({ query: NOTIFICATION_ADDED }).subscribe(
        (notification) => {
          const { unreadNotifications } = getStoreState();
          if (notification.data?.notificationAdded) {
            if (unreadNotifications !== null) actions.setUnreadNotifications([notification.data.notificationAdded, ...(unreadNotifications || [])]);
            actions.getNumberOfUndreadNotifications();
            actions.setNewNotifications([notification.data.notificationAdded]);
          }
        },
        (err) => actions.setError(err),
      );

      return unsubscribe;
    }),
    setError: action((state, payload) => {
      state.error = payload;
    }),
  },
  {
    name: 'NotificationsStore',
  },
);
