import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

interface Props {
  onClick: () => void;
}

export const RenderCreatePerson = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex
      _hover={{
        bg: 'gray.50',
      }}
      cursor="pointer"
      px="10px"
      py="5px"
      onPointerDown={onClick}
      align="center"
    >
      <FiPlus />
      <Text ml="10px">{t('Person.create')}</Text>
    </Flex>
  );
};
