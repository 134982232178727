import React, { FC } from 'react';

interface Props {
  hidden?: boolean;
}

const If: FC<Props> = ({ hidden, children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hidden ? null : children}</>;
};

export default If;
