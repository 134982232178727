import { Flex, Image, ModalBody, ModalHeader, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiBell, FiBellOff } from 'react-icons/fi';

import { UseModalReturn } from 'components/useModal';
import { APP_ICON } from 'shared/constants';

import { State } from '..';

interface Props {
  state: State['state'];
}

const PermissionModal = ({ Modal, state }: UseModalReturn & Props) => {
  const { t } = useTranslation();
  const permissionDenied = state === 'denied';

  if (state === 'granted') {
    return null;
  }

  return (
    <Modal size="lg" isCentered>
      <ModalHeader>
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Image src={APP_ICON} />
          <Flex alignItems="center" gap=".15em">
            {permissionDenied && <FiBellOff />}
            {!permissionDenied && <FiBell />}
            <Text>{t('Notifications.modals.permission.subHeader')}</Text>
          </Flex>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Text textAlign="center">{t(`Notifications.modals.permission.${permissionDenied ? 'manage' : 'description'}`)}</Text>
      </ModalBody>
    </Modal>
  );
};

export default PermissionModal;
