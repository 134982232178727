import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorPage from './ErrorPage';

const ChakraLayout: FC<BoxProps> = ({ children, ...rest }) => {
  const bg = useColorModeValue('gray.50', 'gray.800');

  return (
    <Box bg={bg} minH="100%" w="100%" {...rest}>
      <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
    </Box>
  );
};

export default ChakraLayout;
