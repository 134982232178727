import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';

import { CustomFieldComponentProps } from 'components/CustomField/structures';
import { DatePicker } from 'components/Form';
import { DATE_PICKER_VARIANT, TIME_PICKER_VARIANT } from 'components/Form/DatePicker/constants';

const Date = ({ name, label, required, control, error, ...formProps }: CustomFieldComponentProps) => {
  const ref = useRef(null);

  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps} ref={ref}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            fontWeight="normal"
            size="sm"
            bg="white"
            isFullWidth
            dateVariant={DATE_PICKER_VARIANT.SINGLE}
            timePickerVariant={TIME_PICKER_VARIANT.SINGLE}
            value={{ ...(value ? { date: moment(value) } : {}) }}
            onChange={(date) => onChange(date ? moment.utc(date).toISOString() : null)}
            portalContainerRef={ref}
          />
        )}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Date;
