import { Badge, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { NotificationFragment_ExpiringContractNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';
import { FiDollarSign } from 'react-icons/fi';

import SquareIcon from 'components/SquareIcon';
import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import { getSaleCardPath } from 'shared/utils/links';

import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';

interface Props {
  notification: NotificationFragment_ExpiringContractNotification_;
}

const ExpiringContract = ({ notification }: Props) => {
  const { sale, sentAt } = notification;
  const onClose = NotificationsStore.useStoreState((store) => store.onClose);

  return (
    <Wrapper notification={notification} to={getSaleCardPath(sale._id)} onClick={() => onClose?.()}>
      <Flex>
        <SquareIcon icon={FiDollarSign} isActive iconActiveColor="theme.400" bgActiveColor="theme.100" mr="2.5" size="8" />
        <Box>
          <Text fontSize="sm" color="gray.600" mb="3px" as="span">
            <Trans
              i18nKey={`NotificationsInbox.${notification.type}.innerText`}
              values={{ customer: sale.customer.name, time: moment(sentAt).to(sale.expiresAt) }}
              components={{
                text: <Text color="gray.700" d="inline" />,
              }}
              tOptions={{
                context: moment(sale.expiresAt).diff(sentAt) < 0 ? 'past' : '',
              }}
            />
          </Text>
          <Flex align="center">
            <Badge bgColor="green.400" color="white" fontSize="11px" fontWeight="medium" mr="2.5" px="2" py="3px" borderRadius="25px">
              {`#${sale.ref}`}
            </Badge>
            <Tooltip label={moment(sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
              <Text fontSize="xs" color="gray.500">
                {sentAt && getCorrectNotificationDateToDisplay(sentAt)}
              </Text>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default ExpiringContract;
