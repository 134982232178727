import { FieldType } from '__types/graphql';

import Checkbox from './Pickers/Checkbox';
import Currency from './Pickers/Currency';
import Date from './Pickers/Date';
import DateRange from './Pickers/DateRange';
import Id from './Pickers/Id';
import Number from './Pickers/Number';
import Rating from './Pickers/Rating';
import Select from './Pickers/Select';
import Text from './Pickers/Text';
import TextBox from './Pickers/TextBox';

export const CUSTOM_FIELDS_COMPONENTS = {
  [FieldType.CHECKBOX]: Checkbox,
  [FieldType.CURRENCY]: Currency,
  [FieldType.DATE]: Date,
  [FieldType.DATE_RANGE]: DateRange,
  [FieldType.ID]: Id,
  [FieldType.NUMBER]: Number,
  [FieldType.RATING]: Rating,
  [FieldType.SELECT]: Select,
  [FieldType.TEXT]: Text,
  [FieldType.TEXTBOX]: TextBox,
};
