import { Box, Flex } from '@chakra-ui/react';
import { NavBar } from '@salire-as/ui';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import getPages from 'Pages/pages';
import ChakraLayout from 'components/ChakraLayout';
import { getCurrentRoute } from 'components/ChakraLayout/ChakraTabs/utils';
import SidebarMenu from 'components/ChakraLayout/SidebarMenu';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import { calcFullHeight } from 'utils/calcFullHeight';

const TenantPage = () => {
  const basepath = TenantStore.useStoreState((store) => store.basepath);
  const { pages } = getPages();
  const location = useLocation();
  const { route } = getCurrentRoute(basepath, pages, location) || {};
  const isMenuExpanded = TenantStore.useStoreState((store) => store.isMenuExpanded);

  if (route) {
    Sentry.setTag('page', route.path);
  }

  return (
    <Flex h={calcFullHeight('100vh')} w="100%" flexDir={['column', 'row']}>
      <NavBar defaultIsOpen={isMenuExpanded}>
        <SidebarMenu />
      </NavBar>
      <Box w="100%" overflowY="auto" id="mainContainer" h="100%">
        <ChakraLayout>
          <Outlet />
        </ChakraLayout>
      </Box>
    </Flex>
  );
};

export default TenantPage;
