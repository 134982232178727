import { Button, ModalBody, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import useModal from 'components/useModal';
import useSimpleToast from 'hooks/useSimpleToast';
import { OIDC } from 'shared/utils/oidc';
import { CibaRequest } from 'shared/utils/oidc/ciba-request.type';

import ProfileOptionsStore from '../Store';
import { RequestAccess as RequestAccessType, Status } from '../Store/structures';
import { Form } from './Form';

export interface Props {
  closeAction?: () => void;
}

export const RequestAccess = ({ closeAction }: Props) => {
  const { t } = useTranslation();
  const { error } = useSimpleToast();
  const currentRequest = ProfileOptionsStore.useStoreState((store) => store.supportAccess.request);
  const cancelRequest = ProfileOptionsStore.useStoreActions((actions) => actions.supportAccess.cancelRequest);
  const requestAccess = ProfileOptionsStore.useStoreActions((actions) => actions.supportAccess.requestAccess);
  const organization = TenantStore.useStoreState((store) => store.organization);
  const { Modal } = useModal(true, {
    onClose: () => closeAction?.(),
  });
  const defaultValues = useMemo(() => ({ email: currentRequest.to }), [currentRequest.to]);

  const submit = async (values: Partial<CibaRequest>) => {
    if (Status.REQUESTED === currentRequest.status) {
      return;
    }
    requestAccess({ ...values, organization: organization?._id as string } as RequestAccessType);
  };

  useEffect(() => {
    if (Status.ACCESS_GRANTED === currentRequest.status) {
      OIDC.setAuthCookie(JSON.stringify(currentRequest.tokenResponse));
    } else if (Status.ACCESS_DENIED === currentRequest.status || Status.ERROR === currentRequest.status) {
      error(t('common.error'), { description: t('Profile.RequestAccess.messages.info.noResponse') });
      if (currentRequest.error?.message.includes('Unauthorized')) {
        window.open('/', '_self');
      }
    }
  }, [currentRequest.status]);

  return (
    <Modal title={t('Profile.RequestAccess.title')} scrollBehavior="inside">
      <ModalBody>
        {Status.REQUESTED === currentRequest.status ? (
          <>
            <Text mb="4">{t('Profile.RequestAccess.messages.info.waitingForApproval')}</Text>
            <Button colorScheme="red" onClick={() => cancelRequest()}>
              {t('common.cancel')}
            </Button>
          </>
        ) : (
          <>
            <Text mb="4">{t('Profile.RequestAccess.description')}</Text>
            <Form onSubmit={submit} defaultValues={defaultValues} />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
