import { ApolloLink, FetchResult, Observable, Operation } from '@apollo/client/core';
import { print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-ws';

import getAuthorizationToken from 'shared/utils/getAuthorizationToken';
import getCurrentOrganization from 'shared/utils/getCurrentOrganization';

class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

const url = import.meta.env.VITE_GRAPHQL_WS_ENDPOINT as string;

if (!url) throw new Error('Seems like you forgot to add VITE_GRAPHQL_WS_ENDPOINT env var');

export const wsLink = new WebSocketLink({
  url,
  retryAttempts: 5,
  connectionParams: () => ({
    authorization: getAuthorizationToken(),
    organization: getCurrentOrganization(),
  }),
});
