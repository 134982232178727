import { Button, ButtonProps, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure, useOutsideClick } from '@chakra-ui/react';
import React, { useRef } from 'react';

import ChakraChevron from 'components/Form/ChakraChevron';

interface Props extends Omit<ButtonProps, 'onChange'> {
  options: (string | number)[];
  value: string | number;
  onChange: (value: string | number) => void;
}

const Picker = ({ options, value, onChange, ...buttonProps }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popoverContent = useRef<HTMLElement>(null);

  const { onOpen, isOpen, onClose } = useDisclosure();

  useOutsideClick({
    ref: popoverContent,
    handler: () => onClose(),
  });

  return (
    <Popover placement="bottom-start" initialFocusRef={buttonRef} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          ref={buttonRef}
          rightIcon={<ChakraChevron isOpen={isOpen} />}
          variant="ghost"
          fontWeight="medium"
          p="1"
          h="auto"
          textTransform="capitalize"
          onClick={onOpen}
          sx={{
            '& .chakra-button__icon': {
              transform: 'translateY(-1px)',
            },
          }}
          {...buttonProps}
        >
          {value}
        </Button>
      </PopoverTrigger>
      <PopoverContent maxH="300px" overflow="auto" width="min-content" ref={popoverContent}>
        <PopoverBody p="0">
          {options.map((option) => (
            <Text
              key={option}
              onClick={() => {
                onChange(option);
                onClose();
              }}
              px="3"
              py="2"
              bg={option === value ? 'gray.50' : 'white'}
              cursor="pointer"
              textTransform="capitalize"
              _hover={{ bg: 'gray.50' }}
            >
              {option}
            </Text>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Picker;
