import styled from 'styled-components';

export default styled.label`
  color: #a3a6aa;
  padding-bottom: 10px;
  display: block;
  line-height: 20px;
  font-size: 12px;
  ${(props) =>
    props.size === 'normal' &&
    `
        font-size: 14px;
        line-height: 22px;
    `}
  ${(props) => `
        margin-right: ${props.margin && props.margin.right}px;
        margin-left: ${props.margin && props.margin.left}px;
        margin-top: ${props.margin && props.margin.top}px;
        margin-bottom: ${props.margin && props.margin.bottom}px;
    `}
`;
