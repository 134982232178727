import { Box, Flex, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import moment from 'moment-timezone';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ChakraTimeInput from 'components/Form/ChakraTimeInput';

import { TIME_PICKER_VARIANT } from '../constants';
import { DatePickerFormValues } from '../structures';
import { getDuration } from '../utils';

interface Props {
  variant?: TIME_PICKER_VARIANT;
  onChange: () => void;
}

const Time = ({ variant = TIME_PICKER_VARIANT.SINGLE, onChange: onTimeChange }: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useFormContext<DatePickerFormValues>();
  const { t } = useTranslation();

  const from = useWatch({
    name: 'from',
    control,
  });

  const handleFromChange = (time: string, prevFrom?: string) => {
    if (prevFrom) {
      const prevTo = getValues('to');
      const duration = getDuration(prevFrom, prevTo);
      const to = moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm');
      setValue('to', to);
    }
  };

  return (
    <Box w="100%">
      <HStack w="100%" spacing="2.5">
        <Flex flex={1}>
          <FormControl isRequired flex={1} isInvalid={!!errors?.from}>
            <FormLabel>{variant === TIME_PICKER_VARIANT.SINGLE ? t('DatePicker.time') : t('DatePicker.startTime')}</FormLabel>
            <Controller
              control={control}
              name="from"
              render={({ field: { value, onChange } }) => (
                <ChakraTimeInput
                  size="sm"
                  value={value}
                  onChange={(time) => {
                    onChange(time);
                    handleFromChange(time, from);
                    onTimeChange();
                    trigger('to');
                  }}
                />
              )}
            />
          </FormControl>
        </Flex>
        <Flex flex={1}>
          {variant === TIME_PICKER_VARIANT.DOUBLE && (
            <FormControl isRequired flex={1} isInvalid={!!errors?.to}>
              <FormLabel>{t('DatePicker.endTime')}</FormLabel>
              <Controller
                control={control}
                name="to"
                render={({ field: { value, onChange } }) => (
                  <ChakraTimeInput
                    size="sm"
                    value={value}
                    onChange={(time) => {
                      onChange(time);
                      onTimeChange();
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        </Flex>
      </HStack>
      <Box maxW="100%" color="red" fontSize="xs">
        {errors &&
          Object.values(errors).map((error) => (
            <Text key={`${error?.ref}-${error?.message}`} mt="1">
              {error?.message}
            </Text>
          ))}
      </Box>
    </Box>
  );
};

export default Time;
