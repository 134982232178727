import { Tooltip, useBreakpointValue } from '@chakra-ui/react';
import { NavBarItem, useNavBarContext } from '@salire-as/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TenantLink from 'components/TenantRouter/RouterSwitcher/TenantLink';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import useSamePage from 'hooks/useSamePage';
import Page from 'shared/types/Page';

interface Props {
  page: Page;
}

const MenuItem = ({ page }: Props) => {
  const { link, path, name, menu, isExternal } = page;
  const icon = menu && page.icon;
  const basepath = TenantStore.useStoreState((store) => store.basepath);
  const current = useSamePage(link || path, basepath);
  const { t } = useTranslation();
  const isTooltipDisabled = useBreakpointValue({ base: true, md: false });
  const { onToggleNavBar, isMobile } = useNavBarContext();

  return (
    <TenantLink to={link || path} isExternal={isExternal} style={{ width: '100%' }} onClick={isMobile ? onToggleNavBar : undefined}>
      <Tooltip hasArrow label={t(`Pages.${name}`)} placement="right" offset={[0, 20]} isDisabled={isTooltipDisabled}>
        <NavBarItem label={t(`Pages.${name}`)} icon={icon} isActive={current} />
      </Tooltip>
    </TenantLink>
  );
};

export default MenuItem;
