import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Callback } from 'Pages/Callback';

import Layout from './Layout';
import NotFound from './NotFound';

const Login = React.lazy(() => import('Pages/Login'));
const SignDocument = React.lazy(() => import('Pages/Sign/Document'));
const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => navigate(to));
  return null;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const OutsideApp = () => (
  <SentryRoutes>
    <Route path="/" element={<Redirect to="/login" />} />
    <Route
      path="callback"
      element={
        <Layout>
          <Callback />
        </Layout>
      }
    />
    <Route
      path="login"
      element={
        <Layout>
          <Login />
        </Layout>
      }
    />
    <Route
      path="sign/*"
      element={
        <Layout>
          <SignDocument />
        </Layout>
      }
    />
    <Route path="*" element={<NotFound />} />
  </SentryRoutes>
);

export default OutsideApp;
