import { Box, Checkbox, Flex, Icon, Input, InputGroup, InputLeftElement, Skeleton } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import { escapeRegExp, isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';

import ChakraCustomMenu from '../ChakraCustomMenu';

const ChakraMultiSelect = ({
  valueKey,
  optionsKey,
  placeholder,
  withSearch = false,
  value = [],
  prefix = undefined,
  options = [],
  onChange,
  isLoaded = true,
  withSelectAll = false,
  ...props
}) => {
  const { t } = useTranslation();
  const button = isEmpty(value) ? placeholder || t('common.select') : `${prefix ?? t('common.selected')} (${value.length})`;
  const initialFocusRef = useRef();
  const selectAll = { [optionsKey]: 'ALL', [valueKey]: t('common.selectAll') };
  const opts = withSelectAll ? [selectAll].concat(options) : options;
  const allSelectedRef = useRef(options.length === value.length);

  const getOptionsValue = (option) => {
    if (optionsKey) {
      return option[optionsKey];
    } else return option;
  };

  const getValue = (option) => {
    if (valueKey) {
      return option[valueKey];
    } else return option;
  };

  const isChecked = (option) => {
    if (option.value === selectAll.value) {
      return value.length === options.length;
    }
    return value.includes(getOptionsValue(option));
  };

  const onSelect = (option) => {
    if (option.value === selectAll.value) {
      const newValues = allSelectedRef.current ? [] : options.map(getOptionsValue);
      allSelectedRef.current = !allSelectedRef.current;
      return onChange?.(newValues);
    }
    const update = isChecked(option) ? value.filter((i) => i !== getOptionsValue(option)) : value.concat([getOptionsValue(option)]);
    allSelectedRef.current = false;
    return onChange?.(update);
  };

  return (
    <Skeleton isLoaded={isLoaded}>
      <ChakraCustomMenu initialFocusRef={initialFocusRef} {...props}>
        <>{button}</>
        <>
          <List withSearch={withSearch} initialFocusRef={initialFocusRef} onSelect={onSelect} isChecked={isChecked} getValue={getValue} options={opts} />
        </>
      </ChakraCustomMenu>
    </Skeleton>
  );
};

function List({ initialFocusRef, withSearch, onSelect, isChecked, getValue, options }) {
  const { t } = useTranslation();
  const [search, set] = React.useState('');
  const { selectedTheme } = useTheme();

  const searchFilter = (status) => {
    if (!search) return true;
    if (status.name.match(new RegExp(escapeRegExp(search.trim()), 'i'))) return true;
  };

  const renderOption = (o, key) => (
    <Flex
      _hover={{
        bg: 'gray.50',
        cursor: 'pointer',
      }}
      width="100%"
      key={key}
      py="2"
      px="3"
    >
      <Checkbox onChange={() => onSelect(o)} colorScheme={selectedTheme.id} isChecked={isChecked(o)}>
        {getValue(o)}
      </Checkbox>
    </Flex>
  );

  const renderOptions = options.filter(searchFilter).map(renderOption);

  return (
    <React.Fragment>
      <Box>
        {withSearch && (
          <Box margin="0.5rem 0.5rem 0.5rem 0.5rem">
            <InputGroup size="sm">
              <InputLeftElement children={<Icon as={FiSearch} />} />
              <Input value={search} ref={initialFocusRef} onChange={(e) => set(e.currentTarget.value)} size="sm" placeholder={t('common.search')} />
            </InputGroup>
          </Box>
        )}
        {renderOptions}
      </Box>
    </React.Fragment>
  );
}

export default React.memo(ChakraMultiSelect);
export const ChakaraMultiSelect = React.memo(ChakraMultiSelect);
