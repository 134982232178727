import { Ability, subject } from '@casl/ability';
import { createContextualCan, useAbility as useAbilityCasl } from '@casl/react';
import { createContext } from 'react';

export const AbilityContext = createContext(new Ability());
export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbility = () => {
  return useAbilityCasl(AbilityContext);
};

export { subject };
