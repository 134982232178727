import { Checkbox, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FiCheck } from 'react-icons/fi';

import Field from 'components/SearchDropdown/Field';
import { DROPDOWN_VARIANT } from 'components/SearchDropdown/constants';
import { ExtractData, QueryTypeLike } from 'components/SearchDropdown/structures';

interface Props<QueryType> {
  data: ExtractData<QueryType>;
  onSelect: (
    value: Partial<ExtractData<QueryType>> & {
      _id: GraphQL_ObjectId;
    },
  ) => void;
  label: keyof ExtractData<QueryType>;
  dropdownVariant: DROPDOWN_VARIANT;
  selected?: (Partial<ExtractData<QueryType>> & { _id: string })[];
}

const Label = <QueryType extends QueryTypeLike>({ data, onSelect, label, dropdownVariant, selected }: Props<QueryType>) => {
  const isSelected = selected?.find(({ _id }) => _id === data._id);

  return (
    <Field data={data} onSelect={onSelect} onKeyDown={(event) => event.stopPropagation()}>
      {dropdownVariant === DROPDOWN_VARIANT.SINGLE ? (
        <Flex maxW="100%" alignItems="center">
          {isSelected && <Icon w="4" h="4" mr="1" color="theme.500" as={FiCheck} />}
          <Text isTruncated>{data[label]}</Text>
        </Flex>
      ) : (
        <Checkbox
          w="100%"
          tabIndex={-1}
          isChecked={!!isSelected}
          as="div"
          sx={{ '.chakra-checkbox__label': { width: 'calc(100% - 24px)', display: 'flex' } }}
          onKeyDown={(event) => event.stopPropagation()}
        >
          <Text maxW="100%" isTruncated>
            {data[label]}
          </Text>
        </Checkbox>
      )}
    </Field>
  );
};

export default Label;
