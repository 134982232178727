import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const VersionNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const checkVersionNotification = () => {
    setIsOpen(true);
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener('versionNotification', checkVersionNotification);

    return () => {
      window.removeEventListener('versionNotification', checkVersionNotification);
    };
  }, []);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} colorScheme="red">
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="medium">
            {t('VersionNotification.title')}
          </AlertDialogHeader>

          <AlertDialogBody>{t('VersionNotification.body')}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button colorScheme="red" onClick={reload} ml="3">
              {t('VersionNotification.reload')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default VersionNotification;
