import getAuthorizationToken from 'shared/utils/getAuthorizationToken';
import getCurrentOrganization from 'shared/utils/getCurrentOrganization';

export const setHeaders = (headers: Record<string, string> = {}) => {
  const token = getAuthorizationToken();
  const organization = getCurrentOrganization();

  if (token)
    Object.assign(headers, {
      authorization: token,
    });

  if (organization)
    Object.assign(headers, {
      organization,
    });

  return headers;
};
