import { NavBarUserMenu } from '@salire-as/ui';
import useGetCurrentUser from 'api-hooks/use-get-current-user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { useAbility } from 'client/ability';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import { RequestAccess } from 'components/User/RequestAccess';
import ProfileOptionsStore from 'components/User/Store';
import SwitchOrganization from 'components/User/SwitchOrganization';
import useAction from 'hooks/useAction';
import { getSlug } from 'utils/getSlug';

export const UserIcon = () => {
  const { t } = useTranslation();
  const navigate = TenantStore.useStoreActions((actions) => actions.navigate);
  const currentUser = useGetCurrentUser();
  const ability = useAbility();
  const slug = getSlug();
  const organization = TenantStore.useStoreState((state) => state.organization);

  const username = currentUser?.name;
  const auth = useAuth();

  const { ActionModals, actionKeys, handleActionClick } = useAction(
    {
      profile: {
        title: t('Profile.Icon.profile'),
        function: () => {
          navigate('/profile');
        },
      },
      switchOrganization: {
        title: t('Profile.Icon.switchOrganization'),
        component: SwitchOrganization,
      },
      requestAccess: {
        title: t('Profile.Icon.requestAccess'),
        component: RequestAccess,
        hasPermission: ability.can('module', 'BackOffice'),
      },
      billing: {
        title: t('Profile.Icon.billing'),
        function: async () => window.open(`${document.location.origin}${import.meta.env.VITE_BILLING_APP_URL}/${slug}/subscriptions`, '_self'),
        hasPermission: !organization?.isLegacy && ability.can('manage', 'OrganizationBilling'),
      },
      signOut: {
        title: t('Profile.Icon.signOut'),
        function: async () => await auth.signoutRedirect(),
        displayDivider: true,
      },
    },
    { variables: null },
  );

  const version = `${t('Profile.version')} ${import.meta.env.VITE_PACKAGE_VERSION}`;

  if (username) {
    return (
      <>
        <NavBarUserMenu name={username} onActionClick={handleActionClick} actionKeys={actionKeys} version={version} />
        <ProfileOptionsStore.Provider>
          <ActionModals />
        </ProfileOptionsStore.Provider>
      </>
    );
  }
  return null;
};
