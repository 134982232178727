import { CookieStorage } from 'cookie-storage';
import { CookieOptions } from 'cookie-storage/lib/cookie-options';

export interface StorageOptions extends CookieOptions {}

const DEFAULT_OPTIONS = {
  path: '/',
} as StorageOptions;

export class OIDCStorage {
  private static instance: CookieStorage;

  static getInstance(options?: StorageOptions) {
    if (!OIDCStorage.instance) {
      OIDCStorage.instance = new CookieStorage(options || DEFAULT_OPTIONS);
    }

    return OIDCStorage.instance;
  }
}
