import React, { Children, cloneElement, FC, isValidElement, ReactNode, useEffect, useRef } from 'react';

import useOnScreen from 'hooks/useOnScreen';

interface Props {
  options?: IntersectionObserverInit;
  callback: () => void;
  lostCallback?: () => void;
}

const Watcher: FC<Props> = ({ options, callback, children, lostCallback }) => {
  const ref = useRef(null);
  const countRef = useRef(0);
  const isVisible = useOnScreen(ref, options);

  useEffect(() => {
    if (isVisible) {
      callback();
    } else if (countRef.current) {
      lostCallback?.();
    }
    countRef.current++;
  }, [isVisible]);

  return (
    <>
      {Children.map<ReactNode, ReactNode>(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { ref });
        }
      })}
    </>
  );
};

export default Watcher;
