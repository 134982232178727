import moment from 'moment-timezone';

import i18n, { yup } from 'languages';
import { STANDARD_TIME_FORMAT } from 'shared/constants';

import { DATE_PICKER_VARIANT, TIME_PICKER_VARIANT } from './constants';
import { DatePickerRange } from './structures';

export const isDateRangeValid = (dateRange: DatePickerRange) => {
  return dateRange.from && dateRange.to ? dateRange.from.toISOString() !== dateRange.to.toISOString() : false;
};

export const cloneMomentWithMidday = (date?: moment.Moment) => (date ? date.clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) : undefined);

export const getFormattedDatePickerValue = (
  timeFrom: string,
  timeTo: string,
  dateVariant: DATE_PICKER_VARIANT,
  timePickerVariant: TIME_PICKER_VARIANT,
  date?: Date,
  dateRange?: DatePickerRange,
) => {
  const formattedTimeFrom = moment(timeFrom, 'HH:mm').format('LT');
  const formattedTimeTo = moment(timeTo, 'HH:mm').format('LT');
  const formattedDate = moment(date).format('dddd, L');
  const formattedDateFrom = moment(dateRange?.from, 'L').format('L');
  const formattedDateTo = moment(dateRange?.to, 'L').format('L');

  if (dateVariant === DATE_PICKER_VARIANT.DOUBLE) {
    return dateRange?.from && dateRange?.to
      ? timePickerVariant === TIME_PICKER_VARIANT.NONE
        ? `${formattedDateFrom} - ${formattedDateTo}`
        : `${formattedDateFrom} ${formattedTimeFrom} - ${formattedDateTo} ${formattedTimeTo}`
      : '';
  } else {
    return date
      ? timePickerVariant === TIME_PICKER_VARIANT.NONE
        ? formattedDate
        : timePickerVariant === TIME_PICKER_VARIANT.DOUBLE
        ? `${formattedDate} ${formattedTimeFrom} - ${formattedTimeTo}`
        : `${formattedDate} ${formattedTimeFrom}`
      : '';
  }
};

export const VALIDATION_SCHEMA = (variant: TIME_PICKER_VARIANT) =>
  yup.object().shape({
    from: yup.string(),
    dateFrom: yup.date(),
    dateTo: yup.date(),
    ...(variant === TIME_PICKER_VARIANT.DOUBLE
      ? { to: yup.string().minutesGreaterWithDates('from', 'dateFrom', 'dateTo', 5, i18n.t('DatePicker.endTime')) }
      : {}),
  });

export const getDuration = (from: string, to: string): number => {
  return moment(to, STANDARD_TIME_FORMAT).diff(moment(from, STANDARD_TIME_FORMAT), 'minutes') || 15;
};
