import { Action, Thunk } from 'easy-peasy';

import { ApiError } from 'shared/types/ApiError';
import { TokenResponse } from 'shared/utils/oidc/token-response';

export interface RequestAccess {
  email: string;
  organization: string;
}

export const Status = {
  REQUESTED: 'REQUESTED',
  ACCESS_GRANTED: 'ACCESS_GRANTED',
  ACCESS_DENIED: 'ACCESS_DENIED',
  CANCELED: 'CANCELED',
  NOT_INITIALIZED: 'NOT_INITIALIZED',
  ERROR: 'ERROR',
} as const;

export type StatusKey = keyof typeof Status;

export interface RequestAccessModel {
  request: {
    to: string | undefined;
    id?: string;
    status: typeof Status[StatusKey];
    tokenResponse?: TokenResponse;
    error?: Error | ApiError | null;
  };
  requestAccess: Thunk<RequestAccessModel, RequestAccess>;
  accessGranted: Action<RequestAccessModel, TokenResponse>;
  updateRequest: Action<RequestAccessModel, Partial<RequestAccessModel['request']>>;
  cancelRequest: Action<RequestAccessModel>;
  accessDenied: Action<RequestAccessModel>;
  cancelCurrentRequest?: Action<RequestAccessModel>;
}

export interface ProfileOptionsState {
  supportAccess: RequestAccessModel;
}
