import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import getTimeSlots from 'utils/getTimeSlots';

interface Props extends Omit<InputGroupProps, 'onChange'> {
  name?: string;
  value?: string;
  onChange: (time: string) => void;
}

const ChakraTimeInput: FC<Props> = ({ value, onChange, name, ...inputGroupProps }) => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const submit = (time: string) => {
    onChange(time);
    onClose();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (target.value.length === 5) {
      submit(target.value);
      return event;
    }
  };

  const slots = getTimeSlots({ start: 0, end: 24 });

  const popoverRef = useRef<HTMLElement>(null);

  const handleEnter =
    (time: string) =>
    ({ key }: KeyboardEvent<HTMLDivElement>) => {
      if (key === 'Enter') submit(time);
    };

  useOutsideClick({
    ref: popoverRef,
    handler: () => onClose(),
  });

  useEffect(() => {
    if (value && isOpen && slots.includes(value) && popoverRef.current) {
      const selectedTime = document.getElementById(value);
      if (selectedTime) popoverRef.current.scrollTop = selectedTime.offsetTop - 50;
    }
  }, [isOpen, value]);

  return (
    <Popover isLazy isOpen={isOpen} onOpen={onOpen} initialFocusRef={inputRef} placement="bottom-start">
      <PopoverTrigger>
        <InputGroup {...inputGroupProps}>
          <Input
            type="time"
            ref={inputRef}
            letterSpacing="wide"
            name={name}
            value={value}
            onInput={handleChange}
            sx={{
              '::-webkit-calendar-picker-indicator': {
                bg: 'none',
                display: 'none',
              },
            }}
          />
          <InputRightElement>
            <Icon as={isOpen ? FiChevronUp : FiChevronDown} />
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent ref={popoverRef} maxH="300px" overflow="auto">
        {slots.map((time) => (
          <Box
            id={time}
            onKeyDown={handleEnter(time)}
            _focus={{ bg: 'gray.50' }}
            tabIndex={value === time ? 1 : 0}
            px="3"
            py="1"
            key={time}
            onPointerDown={() => submit(time)}
            cursor="pointer"
            _hover={{ bg: 'gray.50' }}
            bg={value === time ? 'gray.50' : 'white'}
          >
            {time}
          </Box>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default ChakraTimeInput;
