import React, { FC, ReactElement } from 'react';

import Watcher from './Watcher';

interface Props {
  isWatching?: boolean;
  options?: IntersectionObserverInit;
  callback: () => void;
  lostCallback?: () => void;
}

const InViewTrigger: FC<Props> = ({ children, isWatching, options, callback, lostCallback }) => {
  if (!isWatching) return children as ReactElement;
  return (
    <Watcher options={options} callback={callback} lostCallback={lostCallback}>
      {children}
    </Watcher>
  );
};

export default InViewTrigger;
