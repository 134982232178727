import React from 'react';

import SearchDropdown from 'components/SearchDropdown';
import { DropdownTriggerConditionalProps, InstanceProps, VariantConditionalProps } from 'components/SearchDropdown/structures';

import SEARCH_PEOPLE from './SearchPeople.graphql';
import { SearchPeople, SearchPeopleVariables } from './__types/SearchPeople';

const SelectPerson = (
  props: InstanceProps<SearchPeople, SearchPeopleVariables> & DropdownTriggerConditionalProps<SearchPeople> & VariantConditionalProps<SearchPeople>,
) => <SearchDropdown<SearchPeople, Omit<SearchPeopleVariables, 'skip' | 'limit'>> query={SEARCH_PEOPLE} label="name" {...props} />;

export default SelectPerson;
