import { Fields } from 'shared/types/Fields';

import isEntity from './isEntity';

const mapMetaFields = (fields: Fields) =>
  Object.fromEntries(
    Object.entries(fields).map(([_id, value]) => {
      if (isEntity(value)) {
        return [_id, value._id];
      } else if (Array.isArray(value)) {
        return [_id, value.map((element) => (isEntity(element) ? element._id : element))];
      } else if (value === '') {
        return [_id, null];
      }
      return [_id, value];
    }),
  );

export default mapMetaFields;
