import { GetSlotsArgs } from 'shared/interfaces/GetSlotsArgs';

const getTimeSlots = ({ start, end }: GetSlotsArgs) => {
  const quarterHours = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  const times = [];
  for (let i = start; i < end; i++) {
    for (let j = 0; j < quarterHours.length; j++) {
      let time = i + ':' + quarterHours[j];
      if (i < 10) {
        time = '0' + time;
      }
      times.push(time);
    }
  }
  return times;
};

export default getTimeSlots;
