import { get } from 'lodash';

import { EntityName } from '__types/graphql';

import { SELECT_COMPONENTS } from './constants';
import { SelectComponent } from './structures';

export const getSelectComponent = (entityName: EntityName): SelectComponent => {
  return get(SELECT_COMPONENTS, entityName);
};
