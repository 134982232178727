import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFieldComponentProps } from 'components/CustomField/structures';

const Text = ({ name, label, description, required, register, error, ...formProps }: CustomFieldComponentProps) => {
  const { t } = useTranslation();
  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input bg="white" {...register(name)} placeholder={description || t('CustomFields.textPlaceholder')} size="sm" />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Text;
