import { get } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CustomField as CustomFieldProps } from './structures';
import { getCustomFieldComponent } from './utils';

const CustomField = (customField: CustomFieldProps) => {
  const Component = getCustomFieldComponent(customField.type);
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext();
  const error = get(errors, customField.name);
  return <Component control={control} register={register} error={error} {...customField} />;
};

export default CustomField;
