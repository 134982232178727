import React from 'react';

import useModal from 'components/useModal';

import Handler from './Handler';
import { HookProps } from './structures';

export default function usePersonHandler(props?: HookProps) {
  const modalProps = useModal();

  return {
    ...modalProps,
    PersonHandler: <Handler {...modalProps} {...props} />,
  };
}
