const handleResponse = async <T>(response: Response): Promise<[T | null, Error | null]> => {
  if (!response.ok) {
    return [null, (await response.json()) as Error];
  }

  if (response.status === 204) {
    return [null, null];
  }

  return [await response.json(), null];
};
export const execute = async <T>(promise: Promise<Response>): Promise<[T | Response | null, Error | null]> => {
  try {
    return await handleResponse((await promise) as Response);
  } catch (error) {
    return [null, error];
  }
};

export const wrapPromise = async <T>(promise: Promise<T>): Promise<[T | null, Error | null]> => {
  try {
    return [await promise, null];
  } catch (error) {
    return [null, error];
  }
};
