import { ApolloClient, ApolloLink, from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import authLink from './authLink';
import cache from './cache';
import errorLink from './errorLink';
import headersLink from './headersLink';
import { wsLink } from './ws';

const httpLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT as string,
  //credentials: 'include',
  //includeExtensions: true,
}) as unknown as ApolloLink;

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([headersLink, authLink, errorLink, httpLink]),
);

const client = new ApolloClient({
  link,
  cache,
});

export default client;
