import { ButtonProps } from '@chakra-ui/react';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { FiPlus } from 'react-icons/fi';

import ChakraMainButton from '../ChakraMainButton';

const ChakraAddButton: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = ({ children, ...props }, ref) => {
  return (
    <ChakraMainButton ref={ref} leftIcon={<FiPlus size="20px" />} {...props}>
      {children}
    </ChakraMainButton>
  );
};

export default forwardRef(ChakraAddButton);
