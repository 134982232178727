import { Modal as ModalComponent, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { UseModalComponent, UseModalOptions } from './structures';

export type UseModalReturn = ReturnType<typeof useModal>;

export const useModal = (defaultIsOpen = false, options?: UseModalOptions) => {
  const disclosureProps = useDisclosure(defaultIsOpen ? { isOpen: defaultIsOpen } : undefined);
  const { isOpen, onClose } = disclosureProps;

  const Modal = ({ children, title, layer, ...props }: UseModalComponent) => {
    return (
      <ModalComponent
        closeOnOverlayClick
        isOpen={options?.isOpen === undefined ? isOpen : options?.isOpen}
        onClose={options?.onClose || onClose}
        isCentered
        {...props}
      >
        <ModalOverlay zIndex={layer} />
        <ModalContent data-cy="chakra-modal" rounded="5px" padding="10px" pb="20px" transform="scale(0.95)">
          <ModalHeader
            hidden={!title}
            whiteSpace={options?.isHeaderTruncated ?? true ? 'nowrap' : 'normal'}
            maxWidth="93%"
            wordBreak="break-all"
            isTruncated={options?.isHeaderTruncated ?? true}
            fontWeight="600"
            fontSize="16px"
          >
            {title}
          </ModalHeader>
          {options?.closeButton !== false && <ModalCloseButton onClick={options?.onCancel} mt="15px" mr="15px" />}
          {children}
        </ModalContent>
      </ModalComponent>
    );
  };

  return {
    ...disclosureProps,
    Modal,
  };
};

export default useModal;
