import { Box } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import InViewTrigger from 'components/InViewTrigger';
import Loading from 'components/Loading';
import { NOTIFICATION_QUERY_LIMIT } from 'components/TenantRouter/RouterSwitcher/TenantApp/constants';
import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import useInitLayoutEffect from 'hooks/useInitLayoutEffect';

import EmptyList from '../EmptyList';
import Item from '../Item';

const Unread = () => {
  const { t } = useTranslation();
  const unreadNotifications = NotificationsStore.useStoreState((store) => store.unreadNotifications);
  const hasMoreUnread = NotificationsStore.useStoreState((store) => store.hasMoreUnread);
  const isLoadingUnread = NotificationsStore.useStoreState((store) => store.isLoadingUnread);
  const { getUnreadNotifications } = NotificationsStore.useStoreActions((actions) => actions);
  const scrollRef = useRef<HTMLDivElement>(null);

  useInitLayoutEffect(() => {
    if (unreadNotifications === null) {
      getUnreadNotifications();
    }
  });

  return (
    <Box ref={scrollRef} overflow="auto" h="100%">
      {isLoadingUnread && <Loading spinnerColor="spinnerColor" height="100%" />}
      {unreadNotifications
        ?.filter((notification) => !!notification.type)
        .map((notification) => (
          <Item key={notification._id} notification={notification} />
        ))}
      {unreadNotifications && !unreadNotifications.length && <EmptyList text={t('NotificationsInbox.noUnread')} />}
      {scrollRef.current !== null && (
        <InViewTrigger
          key={scrollRef.current.tagName}
          callback={() => getUnreadNotifications()}
          isWatching={(unreadNotifications?.length || 0) >= NOTIFICATION_QUERY_LIMIT}
          options={{
            root: scrollRef.current,
            rootMargin: '0px 0px 350px 0px',
          }}
        >
          <Loading height="32px" hidden={!hasMoreUnread} mt="5" />
        </InViewTrigger>
      )}
    </Box>
  );
};

export default Unread;
