import { FormControl, FormErrorMessage, FormLabel, NumberInput, NumberInputField } from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomFieldComponentProps } from 'components/CustomField/structures';

const NumberPicker = ({ name, label, description, required, control, error, ...formProps }: CustomFieldComponentProps) => {
  const { t } = useTranslation();
  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <NumberInput
            bg="white"
            onChange={(_valueAsString: string, valueAsNumber: number) => onChange(valueAsNumber)}
            placeholder={description || t('CustomFields.numberPlaceholder')}
            size="sm"
            value={value || 0}
            w="100%"
          >
            <NumberInputField borderRadius="md" />
          </NumberInput>
        )}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default NumberPicker;
