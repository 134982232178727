import { useQuery } from '@apollo/client';
import { Badge, Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { CalendarSettingsFragment } from 'fragments/__types/CALENDAR_SETTINGS';
import { NotificationFragment_EventNotification_ } from 'fragments/__types/NOTIFICATION';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';
import { TbCalendar } from 'react-icons/tb';

import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import { FULL_CALENDAR_VIEW } from 'shared/constants';
import { getEventTypeColor } from 'shared/utils/getEventTypeColor';
import { getEventInCalendarPath } from 'shared/utils/links';

import Wrapper from '../Wrapper';
import { getCorrectNotificationDateToDisplay } from '../utils';
import GET_CALENDAR_MODE from './GetCalendarMode.graphql';
import { GetCalendarMode } from './__types/GetCalendarMode';

interface Props {
  notification: NotificationFragment_EventNotification_;
}

const EventReminder = ({ notification }: Props) => {
  const { event, sentAt } = notification;

  const { data } = useQuery<GetCalendarMode>(GET_CALENDAR_MODE);

  const onClose = NotificationsStore.useStoreState((store) => store.onClose);
  if (data) {
    return (
      <Wrapper
        notification={notification}
        to={getEventInCalendarPath(event._id, event.start, (data?.getSettings as CalendarSettingsFragment).payload?.mode as FULL_CALENDAR_VIEW)}
        onClick={() => onClose?.()}
      >
        <Flex>
          <Icon as={TbCalendar} color="theme.500" mr="2.5" boxSize="6" />
          <Box>
            <Text fontSize="sm" color="gray.800" mb="2">
              <Trans
                i18nKey={`NotificationsInbox.${notification.type}.innerText`}
                values={{ customer: event.customer?.name, time: moment(event.start).to(sentAt, true) }}
                components={{
                  text: <Text color="gray.700" d="inline" />,
                }}
                tOptions={{
                  context: event.customer?.name ? 'with_customer' : '',
                }}
              />
            </Text>
            <Flex align="center">
              {event.eventType?.name && (
                <Badge
                  bgColor={getEventTypeColor(event.eventType.color)}
                  fontSize="11px"
                  fontWeight="medium"
                  mr="2.5"
                  px="2"
                  py="1"
                  borderRadius="md"
                  textTransform="initial"
                >
                  {event.eventType?.name}
                </Badge>
              )}
              <Tooltip label={moment(sentAt).format('LL LT')} portalProps={{ appendToParentPortal: false }}>
                <Text fontSize="xs" color="gray.500">
                  {sentAt && getCorrectNotificationDateToDisplay(sentAt)}
                </Text>
              </Tooltip>
            </Flex>
          </Box>
        </Flex>
      </Wrapper>
    );
  }
  return null;
};

export default EventReminder;
