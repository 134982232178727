import moment from 'moment-timezone';

import { DATE_PARAM_FORMAT, FULL_CALENDAR_VIEW } from 'shared/constants';

export const getExportHistoryPath = () => '/admin/settings/exportHistory';

export const getCustomerCardPath = (id?: GraphQL_ObjectId | null, params?: string) => `/customers/card/${id}${params ?? ''}`;

export const getSaleCardPath = (id?: GraphQL_ObjectId | null, params?: string) => `/sale/item/${id}${params ?? ''}`;

export const getOrderCardPath = (id?: GraphQL_ObjectId | null, params?: string) => `/orders/item/${id}${params ?? ''}`;

export const getConversationPath = (id?: GraphQL_ObjectId | null) => `/conversations/conversation/${id}`;

export const getTaskPath = (id?: GraphQL_ObjectId | null) => `/tasks/${id ?? ''}`;

export const getTimeTrackingPath = (id?: GraphQL_ObjectId | null) => `/time-tracking/${id}`;

export const getOrderTaskPath = (orderId?: GraphQL_ObjectId | null, taskId?: GraphQL_ObjectId | null) => `/orders/item/${orderId}/tasks/${taskId}`;

export const getSaleTaskPath = (saleId?: GraphQL_ObjectId | null, taskId?: GraphQL_ObjectId | null) => `/sale/item/${saleId}/tasks/${taskId}`;

export const getCustomerTaskPath = (customerId?: GraphQL_ObjectId | null, taskId?: GraphQL_ObjectId | null) =>
  `/customers/card/${customerId}/tasks/${taskId}`;

export const getEventInCalendarPath = (id?: GraphQL_ObjectId | null, start?: GraphQL_DateTime | null, mode?: FULL_CALENDAR_VIEW | null) => {
  if (!mode || mode === FULL_CALENDAR_VIEW.TIME_GRID_DAY) {
    return `/calendar/timeGridDay/${moment(start).format(DATE_PARAM_FORMAT)}/${id}`;
  } else if (mode === FULL_CALENDAR_VIEW.DAY_GRID_MONTH) {
    const startOfMonth = moment(start).startOf('month').format(DATE_PARAM_FORMAT);
    const endOfMonth = moment(start).endOf('month').format(DATE_PARAM_FORMAT);
    return `/calendar/${mode}/${startOfMonth}/${endOfMonth}/${id}`;
  } else {
    const startOfWeek = moment(start).startOf('week').format(DATE_PARAM_FORMAT);
    const endOfWeek = moment(start).endOf('week').format(DATE_PARAM_FORMAT);
    return `/calendar/${mode}/${startOfWeek}/${endOfWeek}/${id}`;
  }
};

export const getMailerIntegrationPath = () => '/profile/integrations';
