import { merge } from 'lodash';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import getAuthorizationToken from '../getAuthorizationToken';
import { execute } from '../promise';
import { CibaAccessResponse } from './ciba-access-response';
import { CibaRequest } from './ciba-request.type';
import { OIDCStorage } from './oidc-storage';

export const DEFAULT_OIDC_CONFIG = {
  disablePKCE: false,
  response_type: 'code',
  scope: 'openid',
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: OIDCStorage.getInstance() }),
} as UserManagerSettings;

export const getOIDCConfig = (overwrites?: Partial<AuthProviderProps>) => {
  if (overwrites) {
    return merge(DEFAULT_OIDC_CONFIG, overwrites);
  }

  return DEFAULT_OIDC_CONFIG;
};

/**
 * Right now this class is being called from independent functions. React oidc context has it's own internal instance
 */
export class OIDC {
  private static instance: UserManager;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(settings?: Partial<AuthProviderProps>) {
    if (!this.instance) {
      this.instance = new UserManager(getOIDCConfig(settings));
    }
    return this.instance;
  }

  static getKey() {
    const client = this.instance;
    return `oidc.user:${client.settings.authority}:${client.settings.client_id}`;
  }

  static requestCibaAccess({ email, organization }: CibaRequest) {
    return execute<CibaAccessResponse>(
      fetch(`${this.instance.settings.authority}/backchannel/request-access`, {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          'Authorization': getAuthorizationToken() as string,
          'Content-Type': 'application/json',
          organization,
        },
      }),
    );
  }

  static setAuthCookie(value: string) {
    window.document.cookie = `${encodeURIComponent(this.getKey())}=${encodeURIComponent(value)};path=/`;
    window.location.reload();
  }

  static fetchToken(authRequestId: string) {
    return fetch(`${this.instance.settings.authority}/token` as string, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'urn:openid:params:grant-type:ciba',
        client_id: this.instance.settings.client_id as string,
        scope: 'openid offline_access',
        auth_req_id: authRequestId,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
  }
}

export { User };
