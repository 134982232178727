import { Button, ButtonProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import { EntityWithId } from 'shared/types/EntityWithId';

import { ExtractData, QueryTypeLike } from '../structures';

interface Props<QueryType> extends Omit<ButtonProps, 'onSelect'> {
  data: ExtractData<QueryType>;
  onSelect: (value: EntityWithId<Partial<ExtractData<QueryType>>>) => void;
}

const Field = <QueryType extends QueryTypeLike>({ children, data, onSelect, ...props }: PropsWithChildren<Props<QueryType>>) => {
  return (
    <Button
      _hover={{
        bg: 'gray.50',
      }}
      _focus={{ bg: 'gray.50' }}
      justifyContent="flex-start"
      bg="white"
      p="2.5"
      fontWeight="normal"
      borderRadius="0"
      key={data._id}
      onClick={() => onSelect(data)}
      w="100%"
      {...props}
    >
      {children}
    </Button>
  );
};

export default Field;
