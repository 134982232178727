import { ThemeProvider } from '@salire-as/ui';
import { useConfig } from 'contexts/config';
import React from 'react';
import { AuthProvider } from 'react-oidc-context';

import { OIDC } from 'shared/utils/oidc';

import { AppWithTheme } from './theme';

export const Main = () => {
  const config = useConfig();
  const authConfig = {
    userManager: OIDC.getInstance({
      authority: config.oidc.client?.uris.authority,
      post_logout_redirect_uri: config.oidc.client?.uris.postLogoutRedirectUri,
      redirect_uri: config.oidc.client?.uris.redirectUri,
      metadataUrl: `${config.oidc.client?.uris.authority}/.well-known/openid-configuration`,
      client_id: config.oidc.client?.id,
    }),
    onSigninCallback: () => window.history.replaceState({}, document.title, window.location.pathname),
  };
  return (
    <AuthProvider userManager={authConfig.userManager} onSigninCallback={authConfig.onSigninCallback}>
      <ThemeProvider>
        <AppWithTheme />
      </ThemeProvider>
    </AuthProvider>
  );
};
