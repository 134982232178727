import { setContext } from '@apollo/client/link/context';

import { setHeaders } from 'client/setHeaders';

const authLink = setContext((_, { headers }) => {
  // Context for headers
  const context = {
    headers: {
      ...headers,
    },
  };

  setHeaders(context.headers);

  return context;
});

export default authLink;
