import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { get } from 'lodash';

import { VITE_LOGIN_PATH, VITE_ORGANIZATION_SELECTION_PATH } from 'shared/constants';
import getSlug from 'shared/utils/getSlug';

const ORGANIZATION_PATH_ERROR = [
  'an active organization is required to access the resource',
  'not member of organization',
  'an active subscription is required to access the app',
  'organization is locked',
];
const REDIRECT_ERROR_MESSAGES = [
  'Missing authorization header',
  'Unauthorized',
  'Jwt authentication failed unexpectedly',
  'Payment Required',
  'active organization is required',
];

const handleRedirect = (error: GraphQLError) => {
  const errorMessage = get(error, 'extensions.exception.response.trace.message', error.message)?.toLowerCase();
  let path = `${VITE_LOGIN_PATH}?redirect=true`;
  if (ORGANIZATION_PATH_ERROR.some((err) => errorMessage.includes(err))) {
    path = VITE_ORGANIZATION_SELECTION_PATH as string;
  }
  window.open(path as string, '_self');
};

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const shouldRedirect = REDIRECT_ERROR_MESSAGES.some((err) => error.message.includes(err)) || error.extensions?.response?.statusCode === 401;
      if (shouldRedirect && location.pathname !== '/login') {
        const slug = getSlug();
        if (slug) localStorage.removeItem(slug);
        handleRedirect(error);
      }
    }
  }
});

export default errorLink;
