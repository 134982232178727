import { Button, ButtonGroup, Divider, HStack, Link, Text } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

const HelpCenter = () => {
  const { t } = useTranslation();
  const { selectedTheme } = useTheme();
  const { show: showIntercom } = useIntercom();

  return (
    <>
      <Text fontSize="xs" color="gray.500">
        <Trans i18nKey="common.helpCenterText">
          <Link href="https://help.aiflow.no/nb/" target="_blank" color={`${selectedTheme.id}.500`}>
            {t('Profile.Icon.helpCenter')}
          </Link>
        </Trans>
      </Text>
      <HStack justifyContent="center">
        <Divider flex="0 1 25%" />
        <Text fontSize="xs" color="gray.400">
          {t('common.or')}
        </Text>
        <Divider flex="0 1 25%" />
      </HStack>
      <ButtonGroup p="1" mb="2">
        <Button colorScheme={selectedTheme.id} size="xs" onClick={showIntercom}>
          {t('common.chartWithSupport')}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default HelpCenter;
