import { Avatar, Box, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import React from 'react';

import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import { TENANT_PATH } from 'shared/constants';

interface Props {
  companyName: string;
  slug: string;
  onClose: () => void;
}

const Organization = ({ companyName, slug, onClose }: Props) => {
  const tenantSlug = TenantStore.useStoreState((store) => store.tenantSlug);

  const isSelected = tenantSlug === slug;

  return (
    <LinkBox>
      <Box
        _hover={{
          borderColor: 'green.600',
        }}
        borderColor={isSelected ? 'green.600' : 'gray.100'}
        p="2.5"
        borderWidth="2px"
        rounded="10px"
        w="100%"
        cursor="pointer"
      >
        <Box textAlign="center">
          <Avatar name={companyName} size="md" mb="2.5" />
          <LinkOverlay onClick={onClose} target="_blank" href={`${TENANT_PATH}${slug}`}>
            <Text>{companyName}</Text>
          </LinkOverlay>
        </Box>
      </Box>
    </LinkBox>
  );
};

export default Organization;
