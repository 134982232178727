import { NotificationFragment_MentionNotification_ } from 'fragments/__types/NOTIFICATION';

import { getCustomerCardPath, getOrderCardPath, getSaleCardPath } from 'shared/utils/links';

import { MENTION_NOTIFICATION_ENTITIES } from './contants';

export const getLinkToEntityCard = (notification: NotificationFragment_MentionNotification_) => {
  if (notification.mentionNotificationOrder) {
    return getOrderCardPath(notification.mentionNotificationOrder._id, '/activity');
  } else if (notification.mentionNotificationSale) {
    return getSaleCardPath(notification.mentionNotificationSale._id, '/activity');
  }
  return getCustomerCardPath(notification.mentionNotificationCustomer._id, '/activity');
};

export const getValueFromEntity = (notification: NotificationFragment_MentionNotification_) => {
  if (notification.mentionNotificationOrder) {
    return `#${notification.mentionNotificationOrder.orderRef}`;
  } else if (notification.mentionNotificationSale) {
    return `#${notification.mentionNotificationSale.ref}`;
  }
  return notification.mentionNotificationCustomer.name;
};

export const getTranslationEntityKey = (notification: NotificationFragment_MentionNotification_) => {
  if (notification.mentionNotificationOrder) {
    return MENTION_NOTIFICATION_ENTITIES.ORDER;
  } else if (notification.mentionNotificationSale) {
    return MENTION_NOTIFICATION_ENTITIES.SALE;
  }
  return MENTION_NOTIFICATION_ENTITIES.CUSTOMER;
};
