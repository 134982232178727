import { ModalBody } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'components/useModal';

import { HookProps } from '../structures';
import Form from './Form';

const Handler = ({ Modal, ...props }: ReturnType<typeof useModal> & HookProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={t(props?.person ? 'People.update' : 'People.create')} isCentered={false}>
      <ModalBody>
        <Form {...props} />
      </ModalBody>
    </Modal>
  );
};

export default Handler;
