import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';

import { CustomFieldComponentProps } from 'components/CustomField/structures';
import { DROPDOWN_VARIANT } from 'components/SearchDropdown/constants';
import { toggle } from 'components/SearchDropdown/utils';

import { getSelectComponent } from './utils';

const Id = ({ name, label, description, options, required, control, error, ...formProps }: CustomFieldComponentProps) => {
  const { area, multiple } = options ?? {};

  const ref = useRef(null);
  if (!area) return null;

  const SelectComponent = getSelectComponent(area);

  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps} ref={ref}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return multiple ? (
            <SelectComponent
              isFullWidth
              dropdownVariant={DROPDOWN_VARIANT.MULTI_SELECT}
              selected={value ?? []}
              onSelect={(data) => onChange(data)}
              title={description}
              triggerLabel="name"
              portalContainerRef={ref}
            />
          ) : (
            <SelectComponent
              isFullWidth
              dropdownVariant={DROPDOWN_VARIANT.SINGLE}
              selected={value ? [value] : []}
              onSelect={(data) => onChange({ target: { value: toggle(value, data) } })}
              title={description}
              triggerLabel="name"
              portalContainerRef={ref}
            />
          );
        }}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Id;
