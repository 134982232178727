import { Button, ButtonProps, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { isNil } from 'lodash';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import ChakraChevron from '../ChakraChevron';
import { ChakraProbability } from '../ChakraProbability';

interface Props extends Omit<ButtonProps, 'onChange'> {
  prefix?: string;
  value?: number;
  placeholder?: string;
  onChange: (value: number) => void;
}

const ChakraSelectNumberSlider: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { prefix = '%', value = 1, placeholder = 'Button placeholder', onChange, ...props },
  ref,
) => {
  const text = (isNil(value) ? placeholder : value) + ' ' + prefix;

  return (
    <Popover initialFocusRef={ref && 'current' in ref ? ref : undefined} placement="bottom-start">
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button rightIcon={<ChakraChevron isOpen={isOpen} />} {...props}>
              {text}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="300px">
              <PopoverBody mt="2.5">
                <ChakraProbability ref={ref} value={value} onChange={onChange} />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default forwardRef(ChakraSelectNumberSlider);
