import { get } from 'lodash';
import { AnySchema } from 'yup';

import { FieldType } from '__types/graphql';
import i18n, { yup } from 'languages';
import stringToNumber from 'utils/stringToNumber';

import { CUSTOM_FIELDS_COMPONENTS } from './contants';
import { CustomFieldComponent } from './structures';

export const getCustomFieldComponent = (type: FieldType): CustomFieldComponent => {
  return get(CUSTOM_FIELDS_COMPONENTS, type);
};

export const generateDynamicCustomFieldsSchema = (
  fields: { type: FieldType; required?: boolean; _id: GraphQL_ObjectId; options: { multiple?: boolean | null } | null }[],
) => {
  const schema: Record<string, AnySchema> = {};
  fields.forEach((field) => {
    switch (field.type) {
      case FieldType.TEXT:
      case FieldType.TEXTBOX:
        Object.assign(schema, { [field._id]: field.required ? yup.string().required().nullable() : yup.string().nullable() });
        break;
      case FieldType.CHECKBOX:
        Object.assign(schema, { [field._id]: field.required ? yup.boolean().required().nullable() : yup.boolean().nullable() });
        break;
      case FieldType.NUMBER:
      case FieldType.CURRENCY:
        Object.assign(schema, {
          [field._id]: field.required
            ? yup
                .number()
                .transform((_, originalValue) => {
                  const transformedValue = isNaN(originalValue) ? 0 : originalValue;
                  return transformedValue === null ? undefined : stringToNumber(transformedValue.toString());
                })
                .required()
            : yup
                .number()
                .transform((_, originalValue) => {
                  const transformedValue = isNaN(originalValue) ? 0 : originalValue;
                  return transformedValue === null ? undefined : stringToNumber(transformedValue.toString());
                })
                .nullable(),
        });
        break;
      case FieldType.RATING:
        Object.assign(schema, { [field._id]: field.required ? yup.number().min(1).max(5).required().nullable() : yup.number().min(0).max(5).nullable() });
        break;
      case FieldType.SELECT:
        if (field.options?.multiple) {
          Object.assign(schema, {
            [field._id]: field.required
              ? yup.array().of(yup.string()).required().min(1, i18n.t('common.required'))
              : yup.array().of(yup.string()).nullable(),
          });
          break;
        } else {
          Object.assign(schema, { [field._id]: field.required ? yup.string().required() : yup.string().nullable() });
          break;
        }
      case FieldType.ID:
        if (field.options?.multiple) {
          Object.assign(schema, {
            [field._id]: field.required
              ? yup
                  .array()
                  .of(
                    yup.object().shape({
                      _id: yup.string(),
                      name: yup.string(),
                    }),
                  )
                  .min(1, i18n.t('common.required'))
              : yup
                  .array()
                  .of(
                    yup.object().shape({
                      _id: yup.string(),
                      name: yup.string(),
                    }),
                  )
                  .nullable(),
          });
          break;
        } else {
          Object.assign(schema, {
            [field._id]: field.required
              ? yup
                  .object()
                  .shape({
                    _id: yup.string(),
                    name: yup.string(),
                  })
                  .required()
                  .nullable()
              : yup
                  .object()
                  .shape({
                    _id: yup.string(),
                    name: yup.string(),
                  })
                  .nullable(),
          });
          break;
        }
      case FieldType.DATE:
        Object.assign(schema, { [field._id]: field.required ? yup.date().required().nullable() : yup.date().nullable() });
        break;
      case FieldType.DATE_RANGE:
        Object.assign(schema, { [field._id]: field.required ? yup.array().of(yup.date()).required().nullable() : yup.array().of(yup.date()).nullable() });
        break;
      default:
        throw 'Unknown field type';
        break;
    }
  });
  return yup.object().shape({ meta: yup.object({ fields: yup.object(schema).nullable() }) });
};
