import { Fields } from 'shared/types/Fields';

export const filterMetaFieldsInput = (fields?: Fields) =>
  fields
    ? Object.entries(fields).reduce(
        (object, [key, value]) =>
          Object.assign(object, {
            [key]:
              value || typeof value === 'boolean'
                ? value
                : undefined || typeof value === 'string'
                ? value
                : undefined || typeof value === 'number'
                ? value
                : undefined || value === null
                ? null
                : undefined,
          }),
        {},
      )
    : {};
