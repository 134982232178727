import { createContext, useContext } from 'react';

export interface Client {
  id: string;
  domains: string[];
  name: string;
  uris: {
    authority: string;
    backChannelAuth: string;
    backChannelAuthRequest: string;
    backChannelAuthRequestAccept: string;
    postLogoutRedirectUri: string;
    redirectUri: string;
  };
}

export interface ContextConfig {
  oidc: {
    client?: Client;
  };
}

const IS_PRODUCTION = 'production' === import.meta.env.VITE_ENVIRONMENT;
export const AUTHORITY = `https://${IS_PRODUCTION ? 'api' : 'develop.api'}.${document.location.hostname.replace('develop.', '')}/oidc`;
const BACK_CHANNEL = `${AUTHORITY}/backchannel`;

const URIS = {
  authority: AUTHORITY,
  backChannelAuth: BACK_CHANNEL,
  backChannelAuthRequest: `${BACK_CHANNEL}/request-access`,
  backChannelAuthRequestAccept: `${BACK_CHANNEL}/accept`,
};

const COMMON = {
  ...URIS,
  redirectUri: `${document.location.origin}/callback`,
  postLogoutRedirectUri: document.location.origin,
};

export const AVAILABLE_CLIENTS = [
  {
    id: 'aiflow',
    name: 'AIFlow',
    domains: ['aiflow.no', 'develop.aiflow.no', 'aiflow.pl', 'develop.aiflow.pl'],
    uris: {
      ...COMMON,
    },
  },
  {
    id: 'simpli',
    name: 'SimpliCRM',
    domains: ['simplicrm.io', 'develop.simplicrm.io', 'develop.335232.xyz', '335232.xyz', 'localhost'],
    uris: {
      ...COMMON,
    },
  },
];

export const DEFAULT_CONFIG = {
  oidc: {
    client: null,
  },
};

export const ConfigContext = createContext({} as ContextConfig);

export const useConfig = () => useContext<ContextConfig>(ConfigContext);
