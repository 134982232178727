import { FormControl, FormErrorMessage, FormLabel, Select as SelectControl } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import { GroupBase, Select as MultipleSelectControl } from 'chakra-react-select';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomFieldComponentProps } from 'components/CustomField/structures';
import { chakraStyles } from 'shared/constants';

import { OptionGroup } from './structures';

const Select = ({ name, label, description, options, required, register, control, error, ...formProps }: CustomFieldComponentProps) => {
  const { values, multiple } = options || {};
  const { t } = useTranslation();
  const theme = useTheme();

  if (!values) return null;

  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {multiple ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <MultipleSelectControl<OptionGroup, true, GroupBase<OptionGroup>>
              isMulti
              chakraStyles={chakraStyles}
              colorScheme={theme.selectedTheme.id}
              ref={ref}
              onChange={(newValue: OptionGroup[]) => onChange(newValue.length === 0 ? [] : newValue.map((selected) => selected.value))}
              value={value?.map((selected: string) => ({ value: selected, label: selected }))}
              onBlur={onBlur}
              placeholder={description || t('common.selectPlaceholder')}
              options={values.map((option) => ({ value: option, label: option }))}
            />
          )}
        />
      ) : (
        <SelectControl borderRadius="md" size="sm" placeholder={description || t('common.selectPlaceholder')} {...register(name)}>
          {values.map((value) => (
            <option key={value}>{value}</option>
          ))}
        </SelectControl>
      )}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Select;
