import arrowLeft from 'images/forms/arrow-left.svg';
import arrowRight from 'images/forms/arrow-right.svg';

export const DATE_PICKER_STYLES = {
  '.chakra-portal &': {
    '.DayPicker-Month': {
      margin: '10px 10px 0',
    },
    '.DayPicker-NavButton': {
      'top': '10px',
      'right': '10px',
      'width': '12px',
      'height': '24px',
      '&--prev': {
        backgroundImage: `url(${arrowLeft})`,
      },
      '&--next': {
        backgroundImage: `url(${arrowRight})`,
      },
    },
    '.DayPicker-Caption': {
      padding: '0 7px',
    },
    '.DayPicker-Body, .DayPicker-Weekdays': {
      display: 'block',
    },
    '.DayPicker-Weekdays': {
      marginTop: 0,
    },
    '.DayPicker-Week, .DayPicker-WeekdaysRow': {
      display: 'flex',
    },
    '.DayPicker-Week': {
      marginBottom: '2px',
    },
    '.DayPicker-WeekNumber': {
      border: '0',
      display: 'flex',
      alignItems: 'center',
      minWidth: '27px',
      justifyContent: 'flex-end',
      cursor: 'auto',
    },
    '.DayPicker-Weekday, .DayPicker-Day': {
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'width': '40px',
      'margin': '0 1px',
      'fontSize': 'var(--chakra-fontSizes-sm)',
      'fontWeight': 'var(--chakra-fontWeights-medium)',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    '.DayPicker-Weekday': {
      'color': '#505969',
      'textTransform': 'capitalize',
      '&:first-child': {
        width: 'auto',
        minWidth: '27px',
        margin: 0,
      },
    },
    // eslint-disable-next-line max-len
    '.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover, .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
      {
        color: 'black',
        backgroundColor: 'unset',
      },
    '.DayPicker-Day': {
      'height': '40px',
      'color': 'black',
      'borderRadius': '2px',
      'backgroundColor': 'var(--chakra-colors-gray-200)',
      'cursor': 'pointer',
      'transition': 'all .125s ease-in',

      '&:hover': {
        color: 'black',
        backgroundColor: 'unset',
      },

      '&--gray300': {
        color: 'var(--chakra-colors-gray-700)',
        backgroundColor: 'var(--chakra-colors-gray-300)',
      },

      '&--gray500': {
        backgroundColor: 'var(--chakra-colors-gray-500)',
      },

      '&--gray900': {
        color: 'var(--chakra-colors-gray-100)',
        backgroundColor: 'var(--chakra-colors-gray-900)',
      },

      '&--outside': {
        color: 'var(--chakra-colors-gray-500)',
        backgroundColor: 'var(--chakra-colors-gray-100)',
      },

      '&--selected': {
        color: 'var(--chakra-colors-theme-500)',
        backgroundColor: 'var(--chakra-colors-theme-100)',
      },

      '&--selected.DayPicker-Day--outside': {
        backgroundColor: 'var(--chakra-colors-theme-50)',
      },

      '&--start, &--end, &--singleDay': {
        border: '2px solid #19a3c4',
      },
    },
  },
} as const;

export enum TIME_PICKER_VARIANT {
  SINGLE = 'single',
  DOUBLE = 'dobule',
  NONE = 'none',
}

export enum DATE_PICKER_VARIANT {
  SINGLE = 'single',
  DOUBLE = 'double',
}
