import { isUndefined } from 'lodash';
import React, { useState } from 'react';

import { Action } from './structures';

export default <T, U>(actions: Record<string, Action<T, U>>, { variables }: { variables: U }) => {
  const [action, set] = useState<keyof Record<string, Action<T, U>> | null>(null);
  const closeAction = () => {
    set(null);
  };

  const actionKeys = Object.keys(actions)
    .filter((actionKey) => {
      const selected = actions[actionKey];
      if (typeof selected?.disabled === 'function') {
        return !selected?.disabled();
      }
      return !selected?.disabled && (isUndefined(selected.hasPermission) || selected.hasPermission);
    })
    .map((actionKey) => ({ ...actions[actionKey], key: actionKey }));

  const handleActionClick = (key: keyof Record<string, Action<T, U>>) => {
    const selected = actions[key];
    const fields = Object.keys(selected);

    if (selected.function) {
      const runFunction = selected.function;
      runFunction(variables);
    }

    if (fields.includes('component')) {
      set(key);
    }
  };

  const ActionModals = (props: T) => {
    if (!action) return null;
    const { component: Component } = actions[action];

    if (!Component) return null;

    return <Component {...props} {...variables} closeAction={closeAction} />;
  };

  const hasAbility = actionKeys.some((key) => {
    if (typeof key?.disabled === 'function') {
      return !key?.disabled();
    }
    return !key?.disabled;
  });

  return {
    ActionModals,
    actionKeys,
    handleActionClick,
    closeAction,
    hasAbility,
  };
};
