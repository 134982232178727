import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';

import { Button } from '..';
import Select from './Select';

const Dropdown = styled.div`
  /*border: 1px solid red;*/
`;

export default ({
  userCanEdit,
  placement = 'bottom-start',
  size,
  onSelect,
  customStyle,
  selected,
  options,
  settings,
  placeholder = 'No placeholder',
  renderOptions,
  hover,
  wrapperStyle,
}) => {
  const [state, show] = useState(false);

  const enterButton = () => {
    if (hover) {
      show(true);
    }
  };

  const canModify = userCanEdit !== undefined || null ? userCanEdit : undefined;

  const onClick = () => {
    if (canModify === true) return null;
    show(true);
  };

  const leaveWrapper = () => {
    show(false);
  };

  const popperRef = React.useRef(null);

  useOnClickOutside(popperRef, () => {
    show(false);
  });

  return (
    <Box display="inline-block" sx={wrapperStyle}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <Button
              size={size}
              open={state}
              type={userCanEdit === true ? 'selectDisabled' : 'select'}
              ref={ref}
              onMouseOver={enterButton}
              onClick={onClick}
            >
              <Selected {...settings} placeholder={placeholder} data={selected} />
            </Button>
          )}
        </Reference>
        <Popper
          placement={placement}
          modifiers={{
            fixedWidth: {
              enabled: true,
              fn: (popper) => {
                const data = popper;

                if (popper.placement === 'bottom-start') {
                  const reference = popper.offsets.reference;
                  data.offsets.popper.left = reference.left;
                  data.offsets.popper.top = data.offsets.popper.top + 5;
                }
                if (popper.placement === 'bottom-end') {
                  const reference = popper.offsets.reference;
                  data.offsets.popper.left = reference.width + reference.left - popper.popper.width;
                  data.offsets.popper.top = data.offsets.popper.top + 5;
                }
                if (popper.placement === 'top-end') {
                  const reference = popper.offsets.reference;
                  data.offsets.popper.left = reference.width + reference.left - popper.popper.width;

                  data.offsets.popper.top = data.offsets.popper.top - 10;
                }
                if (popper.placement === 'top-start') {
                  const reference = popper.offsets.reference;
                  data.offsets.popper.left = reference.left;
                  data.offsets.popper.top = data.offsets.popper.top - 10;
                }
                return data;
              },
              order: 840,
            },
          }}
        >
          {({ ref, style, placement: placementValue, arrowProps }) =>
            state && (
              <Dropdown ref={ref} style={{ zIndex: 100, ...style, ...customStyle }} data-placement={placementValue} onMouseLeave={leaveWrapper}>
                <Select
                  selected={selected}
                  ref={popperRef}
                  options={options}
                  settings={settings}
                  renderOptions={renderOptions}
                  onSelect={onSelect}
                  close={() => show(false)}
                />
                <div ref={arrowProps.ref} style={arrowProps.style} />
              </Dropdown>
            )
          }
        </Popper>
      </Manager>
    </Box>
  );
};

const Selected = ({ placeholder, data, optionsKey }) => {
  if (!data) return placeholder;
  if (optionsKey) return <span>{data[optionsKey]} </span>;
  return data;
};
