import { wrapUseRoutes } from '@sentry/react';
import React, { useEffect } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import getPages from 'Pages/pages';

import { TenantStore } from '../../store';
import { NotificationsStore } from '../store';
import TenantPage from './TenantPage';

const useRoutesWrapper = wrapUseRoutes(useRoutes);

const TenantPages = () => {
  const { pages } = getPages();
  const basepath = TenantStore.useStoreState((store) => store.basepath);

  const { initSubscription } = NotificationsStore.useStoreActions((actions) => actions);

  useEffect(() => {
    const subscription = initSubscription();
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const routes: RouteObject[] = [
    {
      path: basepath,
      element: <TenantPage />,
      children: pages,
    },
  ];

  return useRoutesWrapper(routes);
};

export default TenantPages;
