import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';

import { CustomFieldComponentProps } from 'components/CustomField/structures';
import { DatePicker } from 'components/Form';
import { DATE_PICKER_VARIANT, TIME_PICKER_VARIANT } from 'components/Form/DatePicker/constants';

const DateRange = ({ name, label, required, control, error, ...formProps }: CustomFieldComponentProps) => {
  const ref = useRef(null);

  return (
    <FormControl isRequired={required} isInvalid={!!error} {...formProps} ref={ref}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            fontWeight="normal"
            size="sm"
            bg="white"
            isFullWidth
            dateVariant={DATE_PICKER_VARIANT.DOUBLE}
            timePickerVariant={TIME_PICKER_VARIANT.DOUBLE}
            value={{
              from: value?.[0] ? moment(value[0]) : undefined,
              to: value?.[1] ? moment(value[1]) : undefined,
              ...(value ? { dateRange: { from: moment(value[0]), to: moment(value[1]) } } : {}),
            }}
            onChange={(dates) => onChange(dates ? [moment.utc(dates[0]).toISOString(), moment.utc(dates[1]).toISOString()] : null)}
            portalContainerRef={ref}
          />
        )}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default DateRange;
