import { Box, Tooltip, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { NavBarItem, NavbarNotificationsIcon } from '@salire-as/ui';
import React, { RefObject, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import useResponsive from 'hooks/useResponsive';

import Inbox from './Inbox';
import useNotificationToast from './Inbox/Content/useNotificationToast';

interface Props {
  portalContainerRef?: RefObject<HTMLElement | null>;
}

const Notifications = ({ portalContainerRef }: Props) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { notify, Modal } = useNotificationToast();
  const { getNumberOfUndreadNotifications, setOnClose } = NotificationsStore.useStoreActions((actions) => actions);
  const numberOfUnreadNotifications = NotificationsStore.useStoreState((store) => store.numberOfUnreadNotifications);
  const newNotifications = NotificationsStore.useStoreState((store) => store.newNotifications);
  const isMenuExpanded = TenantStore.useStoreState((store) => store.isMenuExpanded);
  const { t } = useTranslation();
  const isTooltipDisabled = !useBreakpointValue({ base: true, md: false }) || !isMenuExpanded || !isOpen;
  const { isMobile } = useResponsive();

  useEffect(() => {
    getNumberOfUndreadNotifications();
    setOnClose(onClose);
  }, []);

  useEffect(() => {
    newNotifications?.forEach((not) => notify(not));
  }, [newNotifications]);

  return (
    <>
      <Tooltip hasArrow label={t('NotificationsInbox.title')} placement="right" offset={[0, 20]} isDisabled={isTooltipDisabled}>
        <Box onClick={onToggle} w="100%">
          {isMobile ? (
            <NavbarNotificationsIcon numberOfUnreadNotifications={numberOfUnreadNotifications} />
          ) : (
            <NavBarItem label={t('NotificationsInbox.title')} numberOfUnreadNotifications={numberOfUnreadNotifications} />
          )}
          <Inbox isOpen={isOpen} onClose={onClose} portalContainerRef={portalContainerRef} />
        </Box>
      </Tooltip>
      {Modal}
    </>
  );
};

export default memo(Notifications);
