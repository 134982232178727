import moment from 'moment-timezone';

export const PERIODS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  TOMORROW: 'tomorrow',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  THIS_QUARTER: 'thisQuarter',
  LAST_QUARTER: 'lastQuarter',
  PAST_SIX_MONTHS: 'pastSixMonths',
  THIS_YEAR: 'thisYear',
  LAST_YEAR: 'lastYear',
  NEXT_WEEK: 'nextWeek',
  NEXT_MONTH: 'nextMonth',
  NEXT_QUARTER: 'nextQuarter',
  NEXT_YEAR: 'nextYear',
};

export default {
  [PERIODS.TODAY]: {
    start: moment().startOf('day').toISOString(),
    end: moment().endOf('day').toISOString(),
  },
  [PERIODS.YESTERDAY]: {
    start: moment().subtract(1, 'day').startOf('day').toISOString(),
    end: moment().subtract(1, 'day').endOf('day').toISOString(),
  },
  [PERIODS.TOMORROW]: {
    start: moment().add(1, 'day').startOf('day').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
  },
  [PERIODS.THIS_WEEK]: {
    start: moment().startOf('week').toISOString(),
    end: moment().endOf('week').toISOString(),
  },
  [PERIODS.LAST_WEEK]: {
    start: moment().subtract(1, 'week').startOf('week').toISOString(),
    end: moment().subtract(1, 'week').endOf('week').toISOString(),
  },
  [PERIODS.THIS_MONTH]: {
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString(),
  },
  [PERIODS.LAST_MONTH]: {
    start: moment().subtract(1, 'month').startOf('month').toISOString(),
    end: moment().subtract(1, 'month').endOf('month').toISOString(),
  },
  [PERIODS.THIS_QUARTER]: {
    start: moment().startOf('quarter').toISOString(),
    end: moment().endOf('quarter').toISOString(),
  },
  [PERIODS.LAST_QUARTER]: {
    start: moment().subtract(1, 'quarter').startOf('quarter').toISOString(),
    end: moment().subtract(1, 'quarter').endOf('quarter').toISOString(),
  },
  [PERIODS.PAST_SIX_MONTHS]: {
    start: moment().subtract(6, 'month').toISOString(),
    end: moment().toISOString(),
  },
  [PERIODS.THIS_YEAR]: {
    start: moment().startOf('year').toISOString(),
    end: moment().endOf('year').toISOString(),
  },
  [PERIODS.LAST_YEAR]: {
    start: moment().subtract(1, 'year').startOf('year').toISOString(),
    end: moment().subtract(1, 'year').endOf('year').toISOString(),
  },
  [PERIODS.NEXT_WEEK]: {
    start: moment().add(1, 'w').startOf('isoweek').toISOString(),
    end: moment().add(1, 'w').endOf('isoweek').toISOString(),
  },
  [PERIODS.NEXT_MONTH]: {
    start: moment().add(1, 'M').startOf('month').toISOString(),
    end: moment().add(1, 'M').endOf('month').toISOString(),
  },
  [PERIODS.NEXT_QUARTER]: {
    start: moment().add(1, 'quarter').startOf('quarter').toISOString(),
    end: moment().add(1, 'quarter').endOf('quarter').toISOString(),
  },
  [PERIODS.NEXT_YEAR]: {
    start: moment().add(1, 'y').startOf('year').toISOString(),
    end: moment().add(1, 'y').endOf('year').toISOString(),
  },
};
