import { Avatar, Box, Divider, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { useAuth } from 'react-oidc-context';

import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import { VITE_ORGANIZATION_SELECTION_PATH } from 'shared/constants';

const FloatingUserActions = () => {
  const { selectedTheme } = useTheme();
  const { t } = useTranslation();
  const authUser = TenantStore.useStoreState((state) => state.user);
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return null;
  }

  return (
    <Box position="fixed" left="20px" bottom="20px">
      <Menu>
        <MenuButton role="group">
          <Avatar
            p="2.5"
            _groupHover={{ width: '8rem', minWidth: '8rem' }}
            transition="width .2s ease-in-out"
            icon={<FaUserCircle />}
            bg={`${selectedTheme.id}.500`}
            color="white"
          >
            <Text isTruncated ml="2" fontSize="xs" display="none" _groupHover={{ display: 'inline-block' }}>
              {authUser?.name}
            </Text>
          </Avatar>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => auth.signoutRedirect()}>{t('UserDropdown.signOut')}</MenuItem>
          <MenuItem onClick={() => window.open(`${document.location.origin}${VITE_ORGANIZATION_SELECTION_PATH}`, '_self')}>
            {t('UserDropdown.switchOrganization')}
          </MenuItem>
          <Divider />
          <Box color="gray.400" fontSize="xs" ml="3">
            {authUser?.email}
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default FloatingUserActions;
