import { Button, Flex, Tab, TabList } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsStore } from 'components/TenantRouter/RouterSwitcher/TenantApp/store';

const Header = () => {
  const { t } = useTranslation();

  const { markAllAsRead } = NotificationsStore.useStoreActions((actions) => actions);
  const isMarkAllDisabled = NotificationsStore.useStoreState((store) => store.isMarkAllDisabled);

  return (
    <Flex justify="space-between" alignItems="center" borderBottom="2px solid" borderColor="inherit">
      <TabList borderBottom="none">
        <Tab fontSize="sm" px="5" py="2.5" fontWeight="medium" color="gray.700">
          {t('NotificationsInbox.unread')}
        </Tab>
        <Tab fontSize="sm" px="5" py="2.5" fontWeight="medium" color="gray.700">
          {t('NotificationsInbox.history')}
        </Tab>
      </TabList>
      <Button variant="link" fontSize="sm" fontWeight="medium" color="theme.400" mr="5" isDisabled={isMarkAllDisabled} onClick={() => markAllAsRead()}>
        {t('NotificationsInbox.markAllAsRead')}
      </Button>
    </Flex>
  );
};

export default Header;
